import React from 'react'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffaf50'
    },
  },
  typography: { useNextVariants: true },
})

const styles = {
  root: {
    color: '#ffaf50',
    '&$checked': {
      color: '#ffaf50',
    },
  },
  checked: {},
  input: {
    color: "#ffaf50"
  },
  label: {
    color: "#ffaf50"
  },
  underline: {
    "&:before": {
      borderBottom: `1px solid #ffaf50`
    }
  }
}

const Input = ({ classes, input: { input }, label }) => {

  const handleChange = (event) => input.onChange({
    ...event,
    target: {
      ...event.target,
      value: !input.value
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Checkbox
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
            checked={input.value}
            onChange={handleChange}
            color="primary"
          />
        }
        label={<Typography style={{ color: '#ffaf50' }}>{label}</Typography>}
      />
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(Input)