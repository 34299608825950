/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Banking from '../NewBankingMb/banking'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import store from 'store'
import { useTranslation } from 'react-i18next'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import "./style.css"
import { Col, Row } from 'reactstrap'

function Histories({ classes, history }) {
  const {balanceGame} = store.get('user')
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [games, updateGame] = useState([])
  const [histories, updateHistories] = useState([])
  const [selectedType, updateSelectedType] = React.useState(null)
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Histories Warning',
    btnLabel: 'Got it'
  })
  useEffect(() => {
    http.get({ path: 'games' }).then(updateGame)
  }, [])
  const onSubmit = async ({ txType, type, fromDate, toDate }) => {
    console.log(type)
    try {
      const params = {
        fromDate: moment(fromDate).format('YYYY-MM-DD 00:00:00'),
        toDate: moment(toDate).format('YYYY-MM-DD 23:59:59'),
        game: type.split('-')[0],
        txType
      }
      // console.log(params)
      await withLoading(() =>
        http.get({ path: `report/transaction-history`, params: {
          ...params,
          game: params.game != '' ? params.game: undefined} }).then(rs => {
          let rabats = rs.rabats
          if(params.txType == 'winlose' && params.game != '') rabats = rabats.filter(x => x.game_code == params.game)
          updateHistories(rabats)
        })
      )
      updateSelectedType(type)
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    txType: Joi.string()
      .label('txType'),
    type: Joi.string()
      .label('Game'),
    fromDate: Joi.date()
      .label('From Date')
      .required(),
    toDate: Joi.date()
      .label('To Date')
      .required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    // validate: validate(schema),
    initialValues: {
    //   fromDate: moment().toDate(),
    //   toDate: moment().toDate(),
      type: 'all-'
    }
  })

  const [txType, type, fromDate, toDate] = formCreateInputs(['txType', 'type', 'fromDate', 'toDate'], form)
  return (
    <div style={{minWidth: 1000}}>
      <div className="hide_mb">
        <div className="content-history">
          <div className="history">
                    <form onSubmit={handleSubmit}>
                      <div className="report-search display_flex">
                        <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                          <label style={{'color':'black!important'}} for="">{t('FROM_DATE')}</label>

                          <DatePicker
                            style={{'color':'black'}}
                            onChange={(date) => {
                              fromDate.input.onChange({ target: { value: date } })
                              // console.log(fromDate.input)
                            }}
                            selected={fromDate.input.value}
                          />
                        </div>
                        <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                          <label style={{'color':'black!important'}} for="">{t('TO_DATE')}</label>
                          <DatePicker
                            style={{'color':'black'}}
                            onChange={(date) => toDate.input.onChange({ target: { value: date } })}
                            selected={toDate.input.value}
                          />
                        </div>
                        <div style={{ justifyContent: 'unset' }} className="form-report display_flex">
                          <label htmlFor="">{t('TYPE')}</label>
                          <div className="react-datepicker-wrapper">
                            <div className="react-datepicker__input-container">
                              <select
                                name=""
                                className="input_block"
                                style={{ padding: '0px 10px', color: 'black', background: 'white', height: '30px' }}
                                {...txType.input}
                              >
                                <option value='winlose'>Winlose</option>
                                <option value='deposit'>Deposit</option>
                                <option value='withdraw'>Withdraw</option>
                                <option value='bonus'>Bonus</option>
                                <option value='comm'>Comm</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div style={{ justifyContent: 'unset' }} className="form-report display_flex">
                          <label htmlFor="">{t('SELECT_GAME')}</label>
                          <div className="react-datepicker-wrapper">
                            <div className="react-datepicker__input-container">
                              <select
                                name=""
                                className="input_block"
                                style={{ padding: '0px 10px', color: 'black', background: 'white', height: '30px' }}
                                {...type.input}
                              >
                                <option value="all-"></option>
                                {games.map((g) => (<option value={g.game_code+'-'+g.label}
                                                                           key={g.game_code+g.label}>{g.label || g.name}</option>))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="report-submit">
                          <button style={{color: '#fff'}} disabled={submitting} type="submit" className="reload"><span></span>{t('REPORTS')}</button>
                        </div>
                      </div>
                    </form>
          </div>
        </div>
      </div>
      <div className="value-banking" style={{overflowX: 'scroll', background: '#fff'}}>
        <Banking bankings={histories} loading={loading}/>

      </div>
    </div>
  )
}

export default withStyles(styles)(Histories)
