/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'
import queryString from 'query-string'
import store from 'store'

const { useDialog, useLoading, Header } = component

function Sbo({ history }) {
  // console.log('sbo', history)
  const { location: { search } } = history
  const { GameType, GameId } = queryString.parse(search)
  const [Dialog, showDialog] = useDialog({
    title: 'Asian Gaming',
    type: 'error'
  })
  const [loading, withLoading] = useLoading()
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const login = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const { game: { gameUrl } } = await withLoading (() => http.get({ path: `games/14/login`, params: { GameType: 'AGIN', GameId: 0, ProviderId: 3 } }))

      if (gameUrl) return updateLoginUrl(gameUrl)
      return showDialog('AG is comming')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 0) {
      localStorage.loadgame = 1;
      window.location.reload(false);
    }
    login()
  }, [])
  return (
    <div className="col8">
      {/*<Header history={history} title='Sbo'/>*/}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : <div className="play-game full-screen">
          <iframe src={loginUrl} />
        </div>}
      <Dialog />
    </div>
  )
}

export default Sbo