import React from 'react'
import Slider from 'react-slick'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide
} from '@material-ui/core'
import styles from '../components/style'
import { PropagateLoader } from 'react-spinners'
import CloseIcon from '@material-ui/icons/Close'
import { map, isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import queryString from 'query-string'
import GameAccount from '../../../component/gameAccount'
import component from '../../../component'
import useLoading from '../../loading/hook'
import '../style.css'


const { useDialog } = component

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: `lobbymegarelease://lobbymegarelease?account=${account}&password=${password}`,
    2: `lobbykiss://lobbykiss?account=${account}&password=${password}`,
    8: `joker://www.joker123.net/mobile?data=${account}`,
    1: ''
  }

  return proxy[id]
}

const Collection = ({ data, dataTitle, dataMore, history, classes }) => {
  const {
    location: { search }
  } = history
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const [gameSelected, updateGameSelected] = React.useState({})
  const [games, setGames] = React.useState([])
  const { user_id: userId } = store.get('user') || { user_id: undefined }
  const { game, html55 } = queryString.parse(search)
  const gamesSetup = async () => {
    const gameList = await http.get({ path: 'games' })
    setGames(gameList)
    return gameList
  }

  const loginToGame = async (
    { src, label, route, id, redirect, target, html5, code },
    gameList = null
  ) => {
    if (target === '_blank') {
      window.open(route)
    }
    if (redirect) {
      history.push(route)
      return
    }
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'Please login first !' })
      return
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    updateGameSelected({ label, src, route, id })
    try {
      const providerCode = code === 'asiagaming-galaxy' ? '?ProviderId=AG' : ''
      const { balance, err, game_password: password } = await withLoading(() =>
        http
          .post({
            path: `users/${userId}/game/logout${providerCode}`,
            payload: {
              gameLoginId: id
            }
          })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              updateGameSelected({ label, src, err: error.message })
              throw { message: error.message }
            }
            return true
          })
          .then(() =>
            http.get({
              path: `users/${userId}/games/${id}/balance`,
              params: { ProviderId: 'AG' }
            })
          )
      )

      let deeplinkAcc = balance.account
      if (code === 'joker' && html5 === 0)
        deeplinkAcc = await http
          .get({
            json: false,
            path: `games/joker/login`,
            params: { GameCode: 0, mode: 'M' }
          })
          .then(rs => JSON.parse(rs).sign)
      const outDeeplink = generateDeeplink({
        id,
        account: deeplinkAcc,
        password
      })
      updateGameSelected({
        label,
        src,
        route,
        id,
        balance: balance.balance,
        account: balance.account,
        err,
        deeplink: outDeeplink,
        password,
        html5,
        code
      })
      console.log(html5, route)

      if (
        code !== 'xe88' &&
        (html5 === 1 || !outDeeplink || outDeeplink === '')
      )
        history.push(route)
    } catch (e) {
      console.log(e)
      // showDialogErrorWithMessage(e.message)
    }
  }
  const fetchGame = async () => {
    const gameList = await gamesSetup()

    if (game) {
      if (html55 == 1) {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code
        } = data.find(x => x.code === game && x.html5 === html55)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        )
      } else {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code
        } = data.find(x => x.code === game)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        )
      }
    }
  }
  React.useEffect(() => {
    fetchGame()
  }, [])

  const handleClose = () => {
    updateGameSelected({})
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          zIndex: '999',
          width: '26px',
          height: '26px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          background: "none",
          backgroundSize: 'contain'
        }}
        onClick={onClick}
      >
        <img src="./images/home/prev.png" alt="next" />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          zIndex: '999',
          width: '26px',
          height: '26px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          background: "none",
          backgroundSize: 'contain'
        }}
        onClick={onClick}
      >
        <img src='./images/home/prev.png' alt='prev' />
      </div>
    )
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <>
      <div className="colection-slide">
        <div className="colection-tilte">
          <p className="colection-slide-text-title">{dataTitle}</p>
          <p
            className="colection-text-more"
            onClick={() => history.push(dataMore)}
          >
            More
          </p>
        </div>
        <div className="colection-slide-show">
          <div className="colection-head-slide-show ">
            <Slider className="styled-slider" {...settings}>
              {map(
                ({
                  src,
                  classshow,
                  label,
                  route,
                  id,
                  redirect,
                  target,
                  deeplink,
                  idrActive,
                  html5,
                  code
                }) => (
                  <div
                    key={id}
                    cols={2.5}
                    onClick={() =>
                      loginToGame({
                        src,
                        classshow,
                        label,
                        route,
                        id,
                        redirect,
                        target,
                        deeplink,
                        html5,
                        code
                      })
                    }
                  >
                    <img className="colection-img-slide" src={src} alt={src} />
                  </div>
                ),
                data
              )}
            </Slider>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={!isEmpty(gameSelected)}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {gameSelected.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            background: '#23232A',
            padding: 25,
            marginTop: 70,
            height: 'calc(100vh - 70px)',
            overflow: 'auto'
          }}
        >
          <Card
            style={{
              background: 'none',
              border: '1px solid #ffaf50',
              borderRadius: '16px',
              borderRadius: '16px',
              maxWidth: '400px',
              margin: '0 auto'
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                image={gameSelected.src}
                title="Contemplative Reptile"
              />
              <CardContent style={{ textAlign: 'center' }}>
                {gameSelected.err ? (
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: 'bold', color: '#ffaf50' }}
                  >
                    {gameSelected.err}
                  </Typography>
                ) : loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 15
                    }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <GameAccount
                    account={gameSelected.account}
                    password={gameSelected.password}
                    deeplink={gameSelected.deeplink}
                    route={gameSelected.route}
                    html5={gameSelected.html5}
                    history={history}
                    balance={gameSelected.balance}
                  ></GameAccount>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Dialog>
      <ErrorDialog />
    </>
  )
}

export default withStyles(styles)(Collection)
