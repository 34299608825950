/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import component from 'component'
import store from 'store'
import http from 'service/http'

const { useDialog, useLoading, Header } = component


function Allbet({ history }) {
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const login = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const { entryEmbedded } = await http.get({ path: `games/15/login` })

      if (entryEmbedded) return updateLoginUrl(entryEmbedded)
      return showDialog('Sbo is comming')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 0) {
      localStorage.loadgame = 1;
      window.location.reload(false);
    }
    login()
  }, [])
  const [Dialog, showDialog] = useDialog({
    title: 'Evolution'
  })
  return (
    <div className="col8">
      {/*<Header history={history} title='Evolution'/>*/}
      <div className="play-game full-screen">
        <iframe src={loginUrl} />
      </div>
      <Dialog />
    </div>
  )
}

export default Allbet