/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { PropagateLoader } from 'react-spinners'
import TextInput from 'component/textInput'
import {
  Button,
  CssBaseline
} from '@material-ui/core'
import store from 'store'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'

function ChangePassword({ classes, history }) {
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Change Password Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  
  const onSubmit = async ({ password }) => {
    try {
      const { user_id: userId } = store.get('user')
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: { password } })
      )
      
      history.push('account')
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    password: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/).required(),
    confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [password, confirmPassword] = formCreateInputs(['password', 'confirmPassword'], form)
  return (
    <div className={classes.main}>
      <CssBaseline />
      <img style={{width: 120}} alt='iplay' src={`${process.env.PUBLIC_URL}/icon/97Pay-icon.png`} />
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextInput input={password} type="password" label='Enter Your Password' />
        <TextInput input={confirmPassword} type="password" label='Retype Your Password' />
        {loading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
          >
            <PropagateLoader
              sizeUnit="px"
              size={20}
              color="#f50057"
              loading={loading}
            />
          </div>
        ) : (
          <React.Fragment>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitting}
              className={classes.submit}
            >
              CHANGE PASSWORD
            </Button>
          </React.Fragment>
        )}
      </form>
      <ErrorDialog/>
    </div>
  )
}

export default withStyles(styles)(ChangePassword)
