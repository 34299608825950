import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from './NewHeader'
import Footer from './Footer'
import BottomNavigation from './BottomNavigation'
import LeftSideBar from './LeftSideBar'
import RightSideBar from './RightSideBar'

const Layout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <>
      <Header />
      <div className="main">
        <div className="w_1220" style={{ margin: '10 auto' }}>
          {/* <LeftSideBar /> */}
          {children}
          {/* <div className="right-tool">
            <div className="content">
              <div>
                <div className="icon"></div>
                <p>存款小助手</p>
              </div>
              <div>
                <div className="icon"></div>
                <p>存款小助手</p>
              </div>
              <div>
                <div className="icon"></div>
                <p>存款小助手</p>
              </div>
              <div>
                <div className="icon"></div>
                <p>存款小助手</p>
              </div>

            </div>
          </div> */}
          {/* <div data-v-08756235="" data-v-3f94bbba="" id="right-tool">
            <div data-v-08756235="" className="tool-area d-flex flex-column align-items-end">
              <div className="tool-box d-flex pointer deposit-assistant">
                <span className="tool-text text-center">{t('DEPOSIT_ASSISTANT')}</span>
                <div className="icon d-flex flex-column justify-content-center align-items-center">
                  <div className="img-loading finished" style={{ width: '24px', height: '24px' }}>
                  </div>
                  <div className="payment-icons"></div>
                </div>
              </div>
              <div className="tool-box d-flex pointer">
                <span className="tool-text text-center">{t('CALL')}</span>
                <div className="icon d-flex justify-content-center align-items-center">
                  <span className="ico ico-phone"></span>
                </div>
              </div>
              <div className="tool-box d-flex pointer">
                <span onClick={() => window.location.href = '/download'} className="tool-text text-center">{t('DOWNLOAD_APP')}</span>
                <div className="icon d-flex justify-content-center align-items-center">
                  <span className="ico ico-qrcode"></span>
                </div>
              </div>
              <div className="phone-number d-flex justify-content-end">
                <span>400-842</span>
                <span>-3711</span>
              </div>
            </div>
          </div> */}
          {/* <RightSideBar /> */}
        </div>
      </div>
      <BottomNavigation />
      <Footer />
    </>
  )
}

export default Layout
