/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import Header from 'component/header'
import CloseIcon from '@material-ui/icons/Close'
import { map, isEmpty } from 'ramda'
import Bottom from 'component/bottom'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import useLoading from '../loading/hook'
import styles from './style'
import SLOTS from './constant'
import config from 'config'
import queryString from 'query-string'
import GameAccount from '../../component/gameAccount'
import Head from '../../component/layout/Head'
import Testimonials from '../../component/layout/Testimonials'
import TotalPlay from '../../component/layout/TotalPlay'
import Sports from 'feature/SportsComp'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: '',
    7: '',
    2: '',
    1: ''
  }

  return proxy[id]
}

function home({ history, classes }) {
  const { location: { search } } = history
  const { game } = queryString.parse(search)
  const [loading, withLoading] = useLoading(false)
  const [gameSelected, updateGameSelected] = React.useState({})
  const [games, setGames] = React.useState([])
  const { user_id: userId } = store.get('user') || { user_id: undefined }
  const image = './images/home/banneresports.png'

  const gamesSetup = async () => {
    const gameList = await http.get({ path: 'games' })
    setGames(gameList)
    return gameList
  }

  const loginToGame = async ({ src, label, route, id, redirect, target, deeplink }, gameList = null) => {

    if (target === '_blank') {
      window.open(route)
    }
    if (redirect) {
      history.push(route)
      return
    }
    if (!gameList && (id === null || !games.find(({ id: gameId }) => id === gameId))) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    if (gameList && (id === null || !gameList.find(({ id: gameId }) => id === gameId))) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    updateGameSelected({ label, src, route, id })
    const { balance, account, err, game_password: password } = await withLoading(() =>
      http.post({
        path: `users/${userId}/game/logout`, payload: {
          gameLoginId: id
        }
      })
        .catch(error => {
          if (error.message !== 'Not enough money !') {
            // eslint-disable-next-line no-throw-literal
            throw { message: error.message }
          }
          return true
        }))
        // .then(() => deeplink || [10, 2, 14].includes(id) ? true : http.get({ path: `games/${id}/login` }))
        // .then(() => http.get({ path: `users/${userId}/games/${id}/balance` })))
      .catch((error) => {
        console.log(error)
        return { err: error.message }
      })
      if(err) updateGameSelected({ label, src, err })
    const outDeeplink = generateDeeplink({ id, account, password })

    updateGameSelected({ label, src, route, id, balance: balance.balance,  account: balance.account, err, deeplink: outDeeplink, password })
    if(!outDeeplink && outDeeplink !== '') history.push(route)
  }

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` })
    }
    async function fetchGame() {
      const gameList = await gamesSetup()
      // console.log(gameList)
      if(game) {
        const { src, label, route, id, redirect, target, deeplink } = SLOTS.find(x => x.code === game)
        loginToGame({ src, label, route, id, redirect, target, deeplink }, gameList)
      }
    }
    fetchGame()
  }, [])

  const handleClose = () => {
    updateGameSelected({})
  }
  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
      <Head image={image} alt="banneresports" />
      <div className="pages">
        <button className="pages-btn-items">Sports</button>
        <Sports history={history} />
        <img
          style={{ width: '100%', margin: '30px 0' }}
          src="./images/home/banneresportsplay.png"
          alt="banneresports"
        />
        <TotalPlay />
        <Testimonials />
      </div>
    </div>
  )

}
export default withStyles(styles)(home)
