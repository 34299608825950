import React from 'react'
import './boxright.css'

const BoxRight = ({ history }) => {
  return (
    <div className="boxright-wrapper">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
          paddingBottom: '20px'
        }}
      >
        <img
          style={{ width: '20px' }}
          src="./images/home/warning.svg"
          alt="warning"
        />
        <p className="boxright-title">Important Notice</p>
      </div>
      <div>
        <div className="boxright-styled-text">
          <p className="boxright-text">
            <img
              style={{ width: '10px', paddingRight: '5px' }}
              src="./images/home/circle.svg"
              alt="circle"
            />
            Always check for the latest active deposit bank detail before making
            a deposit
          </p>
        </div>
        <div className="boxright-styled-text">
          <p className="boxright-text">
            <img
              style={{ width: '10px', paddingRight: '5px' }}
              src="./images/home/circle.svg"
              alt="circle"
            />
            For using deposit option "Bank Transfer", Please make the transfer
            before sumit the transaction to avoid the transaction is delay
          </p>
        </div>
        <div className="boxright-styled-text">
          <p className="boxright-text">
            <img
              style={{ width: '10px', paddingRight: '5px' }}
              src="./images/home/circle.svg"
              alt="circle"
            />
            Depositor's ACCOUNT NAME must match with registerd full name. We do
            not encourage transaction made using 3rd party/company account.
          </p>
        </div>
        <div className="boxright-styled-text">
          <p className="boxright-text">
            <img
              style={{ width: '10px', paddingRight: '5px' }}
              src="./images/home/circle.svg"
              alt="circle"
            />
            Please DO NOT fill "BK8" # or any sensitive words related to
            gambling as reference/remark in your online trabsfer transaction.
          </p>
        </div>
        <div className="boxright-styled-text">
          <p className="boxright-text">
            <img
              style={{ width: '10px', paddingRight: '5px' }}
              src="./images/home/circle.svg"
              alt="circle"
            />
            Please take note that 1x turnover is required for all deposits made
            before any withdrawal can be processed.
          </p>
        </div>
        <div className="boxright-styled-text" s>
          <p className="boxright-text">
            <img
              style={{ width: '10px', paddingRight: '5px' }}
              src="./images/home/circle.svg"
              alt="circle"
            />
            Kindly check with our 24/7 LIVECHAT if your deposit amount has been
            deducted from bank account but not receive the credit or the
            transaction is showing pending/reject.
          </p>
        </div>
      </div>
    </div>
  )
}
export default BoxRight
