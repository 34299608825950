/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable consistent-return */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'
import store from 'store'

const { useDialog, useLoading } = component

function Allbet() {
  const [Dialog, showDialog] = useDialog({
    title: 'World Entertainment'
  })
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const [loading, withLoading] = useLoading()
  const login = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 26)) {
        const { url } = await withLoading(() => http.post({
          path: `users/${userId}/game/logout`, payload: {
            gameLoginId: '26'
          }
        })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message }
            }
            return true
          })
          .then(() => http.get({ path: `games/26/login` })))
        return updateLoginUrl(url)
      }
      return showDialog('World Entertainment is Maintenance ')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 0) {
      localStorage.loadgame = 1;
      window.location.reload(false);
    }
    login()
  }, [])
  return (
    <div className="col8">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : <div className="play-game full-screen">
          <iframe src={loginUrl} />
        </div>}
      <Dialog />
    </div>
  )
}

export default Allbet