import React, { useEffect, useState, useRef, Children } from 'react'
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import grey from '@material-ui/core/colors/grey'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import http from 'service/http'
import store from 'store'
import queryString from 'query-string'
import AppStore from 'component/appStore'
import { withRouter } from "react-router"

import useLoading from '../../feature/loading/hook'
import useDialog from '../../feature/dialog/hook'
import config from '../../config'
import socket from '../../service/socket'
import "./style.css"

const styles = {
  paperWidthSm: {
    width: '100%',
    maxHeight: 'unset !important',
    margin: '0 !important',
    height: '100%',
    maxWidth: 'none',
    display: 'flex',
    justifyContent: 'center',
    background: 'transparent',
    boxShadow: 'none',
    flexDirection: 'row'
  },
  root: {
    width: '100%',
    marginTop: '20px',
  },
  container: {
    background: 'rgba(35, 35, 42, .7)'
  },
  focused: {
    color: 'black',
  },
}

const theme = createMuiTheme({
  palette: {
    primary: grey,
  },
  typography: { useNextVariants: true },
});

const PopupCommon = ({ children, history, tabActive, classes, show, title, onClose = () => { } }) => {
  const { t } = useTranslation()
  const [_tabActive, updateTabActive] = useState(tabActive)
  const [loginBanner, updateLoginBanner] = useState('/images/login/welcome-ID.png')
  const [registerBanner, updateRegisterBanner] = useState('/images/register/register-ID.png')
  const [isShow, setShow] = useState(show)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t('error'),
    btnLabel: t('GOT_IT'),
    type: 'error'
  })
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = e => {
    if (myRef && myRef.current) {
      if (!myRef.current.contains(e.target)) {
        setClickedOutside(true);
        onClose()
      }
    }
  };
  const handleClickInside = () => { setClickedOutside(false) };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [])
  return (
    <>
      {/* {show ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="popup mfp-container mfp-s-ready mfp-inline-holder comp-dialog">
            <div ref={myRef}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto', display: 'flex'
              }}
              className="popup-common"
            // onClick={handleClickInside}
            >
              <h1>{title}</h1>
            </div>
          </div>
        </React.Fragment>, document.body) : null
      } */}
      {show ? <Dialog
        classes={{
          paperWidthSm: classes.paperWidthSm,
          container: classes.container
        }}
        className="dialog-common"
        open={show}
        onClose={onClose}
        style={{ zIndex: 99 }}
      >
        <ErrorDialog />
        {window.innerWidth < 1024 ? (
          <div id="login" style={{ background: '#1a1a1e' }}>
            <div id="entry-head">
            </div>
          </div>
        ) : (
          <div ref={myRef}
            style={{
              marginTop: 'auto',
              marginBottom: 'auto', display: 'flex'
            }}
            className="popup-common"
            onClick={handleClickInside}
          >
            <div className="content-common">
              <h3>{title}</h3>
              {children}
            </div>
          </div>
        )}
      </Dialog> : ""
      }
    </>
  )
}

export default withRouter(withStyles(styles)(PopupCommon))