export default {
  LANGS: 'Bahasa',
  HOME: 'HOME',
  SPORTS: 'Sportsbook',
  POKER: 'Poker',
  VIRTUAL_SPORTS: 'Virtual Sports',
  LIVE_CASINO: 'Kasino',
  SLOTS: 'slot',
  LOTTERY: 'lotere',
  PROMOTIONS: 'Promosi',
  CONTACT_US: 'Hubungi kami',
  LIVE_CHAT: 'Obrolan',
  WECHAT: 'WeChat',
  BANKING: 'Bank',
  ABOUT_US: 'Mengenai kami',
  RESPONSIBLE_GAMING: 'Game yang bertanggung jawab',
  TERMS_OF_USE: 'Syarat penggunaan',
  TRUSTED_PAYMENT: 'Pembayaran dipercayai',
  TRANSFER: 'Transfer',
  TOPUP: 'TOPUP',
  WITHDRAW: 'Tarik balik dana',
  ACCOUNT: 'Akun',
  HISTORIES: 'Histories',
  REPORTS: 'melaporkan',
  DOWNLOAD: 'download',
  LOGOUT: 'Keluar',
  EDIT_PROFILE: 'Edit profil',
  CHANGE_PASSWORD: 'Ubah kata sandi',

  // FORM AREA
  BANK_NAME: 'Sila masukkan nama bank (lengkap)',
  BANK_ACCOUNT_NAME: 'Sila masukkan nama akaun',
  BANK_ACCOUNT_NO: 'Sila masukkan nombor akaun bank anda',
  WITHDRAW_AMOUNT: 'Sila masukkan jumlah pengeluaran',
  WITHDRAW_NOW: 'Tarik jumlah sekarang',
  SELECT_BANK: 'Pilih bank',
  TOPUP_AMOUNT: 'Sila masukkan amaun tersebut',
  AGREEMENT: 'Saya bersetuju dengan terma dan syarat',
  ONLINE_TOP_UP_NOW: 'Deposit dalam talian sekarang',
  DEPOSIT_WITH_RECEIPT: 'Deposit dengan resit',
  DEPOSIT_AMOUNT: 'Sila masukkan jumlah deposit',
  DEPSOSIT_BONUS: 'Bonus tuntutan tuntutan',
  TRANSFER_IN: 'Transfer masuk',
  TRANSFER_OUT: 'Transfer keluar',
  TYPE_TRANS: 'Pilih jenis transaksi',
  SUBMIT: 'Hantar sekarang',

  LOADING: 'Memuatkan',
  FROM: 'Dari tarikh',
  TO: 'Sehingga kini',
  TRANS_SUC: 'Transfer berhasil',
  TRANS_ERR: 'Kesalahan penghantaran',
  TRANS_DONE: 'Transfer selesai dengan sukses',
  GOT_IT: 'Selesai',
  SELECT_GAME: 'Pilih permainan',
  CLAIM_LOYALTY_BONUS: 'Tuntut Bonus Kesetiaan',
  DEPO_SUC: 'Deposit berhasil',
  DEPO_ERR: 'Kesalahan Deposit',
  DEPO_DONE: 'Permintaan Anda telah berhasil dihantar',
  DEPOSIT: 'Deposit',
  FILE: 'Fail',
  WITH_SUC: 'Pengeluaran yang berhasil',
  WITH_ERR: 'Kesilapan pengekstrakan',
  WITH_DONE: 'Permintaan Anda telah berhasil dihantar',
  CLICK_DOWNLOAD: 'Klik untuk muat turun',
  ENTER_MOBILE: 'Masukkan nombor telefon',
  ENTER_PASS: 'Masukkan kata Password',
  SIGN_IN: 'Masukkan',
  REGISTER: 'Daftar',
  ENTER_DISPLAY_NAME: 'Masukkan nama paparan anda',
  ENTER_EMAIL: 'Masukkan email utama Anda',
  ENTER_AGENT: 'Masukkan email proxy Anda (pilihan)',
  RETYPE_PASS: 'Masukkan semula kata Passwork',
  BACK_TO_LOGIN: 'Kembali ke login',
  PULL_OUT: 'Pindahkan kredit ke dompet',
  RESTORE: 'Restore',
  
  AG_FISHING_KING: 'AG Memancing Raja',
  NOTICE_LABEL: 'pengumuman',
  NOTICE: 'Bank Pertanian China melaksanakan sistem perbankan',

  MEMBER_REGISTER: 'Pendaftaran pemain',
  YOUR_ACCOUNT_CONTACT_DETAIL: 'Maklumat akaun dan kenalan anda',
  USERNAME: 'Nama pengguna',
  USERNAME_CONDITION: 'Hanya 3 hingga 9 aksara, abjad angka (a-z, 0-9)',
  EMAIL: 'Alamat email',
  EMAIL_CONDITION: 'Pengambilan kata Password membutuhkan alamat email yang benar',
  PASSWORD: 'Kata Password',
  PASSWORD_CONDITION: 'Kata Password memerlukan sekurang-kurangnya 1 huruf kecil, 1 huruf besar, 1 nombor, dan 6 hingga 10 aksara abjad angka (A-Z, a-z, 0-9).',
  CONFIRM_PASSWORD: 'Sahkan kata Password',
  CURRENCE: 'Mata wang',
  PHONE: 'Nombor telefon',
  PHONE_CONDITION: 'Sila isikan nombor telefon yang betul (hanya format angka) untuk memudahkan anda menetapkan semula kata laluan anda',
  BANK: 'Nama bank',
  BANK_NUMBER: 'Akaun bank',
  BANK_NUMBER_CONDITION: 'Sila isikan nombor akaun bank yang betul untuk memudahkannya apabila anda perlu menarik balik',
  BANK_ACCOUNT_NAME_CONDITION: 'Senarai nama dan aksara hanya dalam buku bank',
  TERM_18: '* Saya berumur lebih dari 18 tahun dan telah membaca dan menerima terma dan syarat, dasar privasi dan peraturan perjudian yang dipaparkan di laman web ini.',
  LOGIN: 'Masuk',
  MEMBER_LOGIN: 'Login Ahli',
  FORGOT_PASSWORD: 'Lupa kata Password Anda?',
  MOST_POPULAR_GAMES: 'Permainan yang paling popular',
  MOST_POPULAR_GAMES_DESCRIPTION: 'Pengalaman mewah untuk rupa dan rasa VIP sebenar!',
  VIDEO_GAME_BONUS_POOL: 'Newwin Lucky Bonus',
  PLAY_NOW: 'Main sekarang',
  RECOMMEND_GAMES: 'Permainan yang disyorkan',
  WINNING_LIST_YESTERDAY: 'Senarai pemenang semalam',
  NUMBER_OF_BEST_PLACED: 'Bilangan pertaruhan',
  AMOUNT_PAID_OUT: 'Jumlah bayaran',
  MORE: 'Lebih banyak',
  CURRENT_BALANCE: 'Baki semasa',
  PAYMENT_METHOD: 'Kaedah pembayaran',
  FROM_BANK: 'Dari bank',
  AMOUNT: 'Sama seperti',
  RELOAD: 'Muat semula',
  FROM_DATE: 'Dari tarikh',
  TO_DATE: 'Setakat ini',
  START_THE_ROAD_TO_ADVANCEMENT: 'Bagaimana untuk bermula',
  ROAD_TO_ADVANCEMENT: 'Jalan ke kemajuan',
  START_UPDATE_EVERY_MONDAY: 'Kemas kini setiap hari Isnin',
  MY_OFFER: 'Tawaran saya',
  RECENTLY_PLAYED: 'Baru dimainkan',
  MY_COLLECTION: 'Koleksi saya',

  MY_ACCOUNT: 'Akaun saya',
  YOUR_WALLET: 'Dompet anda',
  MY_WALLET: 'Dompet saya',
  BONUS: 'Bonus',
  QUICK_REGISTER: 'Pembukaan akaun pantas',
  LATEST_OFFERS: 'Tawaran terkini',
  GAME: 'Game',
  GAMES: 'Games',
  LIST: 'Direktori',
  OTHER: 'Lain-lain',
  AFFILIATE_AGENT: 'Ejen Ahli',
  BET_THIS_MONTH: 'Pertaruhan bulan ini',
  WASHABLE_CODE_AMOUNT_OF: 'Jumlah mencuci',
  TO_WASH_CODE: 'Kod pembersihan',
  PLEASE: 'SILA',
  TO_VIEW: 'Sila semak',
  NEWS: 'Berita itu',
  FORUM: 'Forum',
  COPYRIGHT: '© 2018 97 IPAY All rights reserved.',
  REWARDS: 'Hadiah',
  BASE_PROFILE: 'Maklumat Asas',
  PROFILE: 'Maklumat',
  REFERAL_ID: 'ID perujuk',
  EMAIL_ADDRESS: 'Alamat email',
  MAIN_BALANCE: 'Baki Utama',
  JOIN_DATE: 'Tarikh Penyertaan',
  LAST_EDIT: 'Edit lepas',
  SUBMIT_LABEL: 'SUBMIT LABEL',
  RANKING: 'Kedudukan',
  USER: 'Pengguna',
  DEPOSIT_TOTAL: 'Jumlah simpanan',
  BET_AMOUNT: 'Bilangan pertaruhan',
  WINNING_AMOUNT: 'Bilangan kemenangan',
  STATISTICS_WITHIN: 'Statistik dalam 7 hari',
  HIGH_EXPLOSION: 'Populariti tinggi',
  POPULAR: 'Populer',
  TOTAL_BET: 'Jumlah pertaruhan',
  WIN_LOSE: 'Menang atau kalah',
  SEVEN_DAY_STATISTICS: 'Statistik 7 hari',

  DEPOSIT_ASSISTANT: 'Bantuan deposit',
  CALL: 'Panggilan',
  DOWNLOAD_APP: 'Muat turun App',

  PROMOTIONS_DESCRIPTION: 'PROMOSI',
  SLOT_DESCRIPTION: 'Game Slot',
  LIVE_CASINO_DESCRIPTION: 'Live Casino',
  LOTTERY_DESCRIPTION: 'Lottery',
  SPORTBOOK_DESCRIPTION: 'Sportbook',
  FISHING: 'Memancing',
  ONLINE_BANKING: 'Perbankan atas talian',
  CREDIT_NOT_ENOUGH: 'tidak cukup kredit, sila hubungi khidmat pelanggan.',
  INCORRECT_USERNAME_PASSWORD: 'Nama pengguna atau kata laluan salah',
  ERROR: 'Kesilapan'
}