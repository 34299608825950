/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import http from 'service/http'
// import socket from 'service/socket'
import numeral from 'numeral'
import moment from 'moment'
import store from 'store'
import config from 'config'
import Banking from './banking'
import { useTranslation } from 'react-i18next'

import useDialog from '../dialog/hook'
import useLoading from '../loading/hook'
import CompCommon from "feature/CompCommon"
import NewDeposit from "feature/NewDeposit"
import WithdrawNew from "feature/WithdrawNew"
import NewHistoriesMb from "feature/NewHistoriesMb"
import './style.css'
import { Button } from 'antd'

// socket.on('login', (data) => {
//   localStorage.clear();
//   alert('Your account just login from other place !')
//   window.location = 'login'
// })

function NewBankingMb({ history }) {
  const { t } = useTranslation()
  const { user_id: userId, user_account_no, user_money, balanceGame } = store.get('user') || { user_id: undefined }
  const user = store.get('user')
  window.username = user_account_no
  const [isShowDeposit, setShowDeposit] = useState(false);
  const [balance, setBalance] = React.useState(0)
  const [isShowWithdraw, setShowWithdraw] = useState(false);
  const [isShowHistory, setShowHistory] = useState(false);
  const [search, setSearch] = useState({
    fromDate: moment().format('YYYY-MM-DD 00:00:00'),
    toDate: moment().format('YYYY-MM-DD 23:59:59'),
    txType: 'winlose',
    game: ''
  })
  const [histories, setHistory] = useState([]);
  const [gameBalances, updateGameBalances] = useState([])
  const [loading, withLoading] = useLoading(false)
  const [bankings, setBankings] = useState([])
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Your Account'
  })
  const fetchBankings = () => {
    withLoading(() => http.get({ path: 'report/transaction-history', params: {
      ...search,
        game: search.game != '' ? search.game: undefined} }).then(rs => {
          let rabats = rs.rabats
          if(search.txType == 'winlose' && search.game != '') rabats = rabats.filter(x => x.game_code == search.game)
          setBankings(rabats)
    }))
  }
  React.useEffect(() => {
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 1) {
      localStorage.loadgame = 0
    }

    const userBalance = store.get('userBalance')
    setBalance(userBalance)
    // console.log(balanceGame)
    setTimeout(() => {
      const userBalance = store.get('userBalance')
      setBalance(userBalance)
    }, 5000)
    setTimeout(() => {
      const userBalance = store.get('userBalance')
      setBalance(userBalance)
    }, 10000)

    fetchBankings()

  }, [])
  const fetchAccount = () => {
    const { user_id: userId } = store.get('user')
    setTimeout(async () => {
      const [userResp] = await withLoading(() => Promise.all([
        http.get({ path: 'me' }),

      ]))

      store.set('user', userResp)
      updateGameBalances(userResp.balanceGame.filter(x => config.gamesAvailable.includes(x.balance.game_code)).map(t => {
        return t.balance
      }))
    }, 2000)

  }
  const onPullOut = async () => {
    const { user_id: userId } = store.get('user')
    const tasks = []
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== 'asiagaming') {
        const providerCode = game_code === 'ag_galaxy' ? '?ProviderId=AG' : ''
        tasks.push(http.post({
          path: `users/${userId}/game/${gameId}/withdraw${providerCode}`, payload: {
            amount: balance
          }
        }))
      }
    })
    await withLoading(() => Promise.all(tasks))
    showDialogWithMessage('Pull out done!')
    fetchAccount()
  }
  const onUpdateShow = (value, type) => {
    if (type === "deposit") {
      setShowDeposit(true)
    }
    if (type === "withdraw") {
      setShowWithdraw(true)
    }
    if (type === "history") {
      setShowHistory(true)
    }
  }
  const getListHistory = (value) => {
    setHistory(value)
  }

  const searchData = () => {
    fetchBankings()
  }
  return (
    <>
      <div className="content-detail-banking" style={{overflowX: 'hidden'}}>
      <Dialog />
      {user && user.user_id ? <>
        <div className="lst-wallet" style={{marginTop: 60, padding: 5}}>
            <div className="load-wallet" style={{padding: '0 5px'}}>
              <div className="wallet-top" style={{height: 30}}>
                <div className="content-text-wallet">
                  <span style={{float: 'left', fontSize: 10, lineHeight: '24px'}}>{config.currency || 'MYR'}</span>
                  <span style={{float: 'right', fontSize: 14}}><b>{`${numeral(user.user_money).format('0,00')}`}</b></span>
                </div>
              </div>
              <div className="wallet-bottom" style={{display: 'flex', padding: 0}}>
                <button className="refreshWallet" onClick={() => onPullOut()} style={{fontSize:'11px'}}>Refresh wallet</button>
              </div>
            </div>
            <div className="trans-his item-wallet" onClick={() => onUpdateShow(true, "deposit")}>
              <img  className='scale-img' src="images/icon/deposit2.png" alt="" />
              <p style={{margin: 0}}>Deposit</p>
            </div>
            <div className="thb item-wallet">
              <img className='scale-img' src="images/icon/withdraw2.png" alt="" onClick={() => onUpdateShow(true, "withdraw")} />
              <p style={{margin: 0}}>Withdraw</p>
            </div>
            <div className="user-inf item-wallet">
              <img className='scale-img' src="images/icon/historywhite.png" alt="" onClick={() => onUpdateShow(true, "history")} />
              <p style={{margin: 0}}>History</p>
            </div>
          </div>
          <div className="lst-wallet" style={{background: '#140700', padding: '5px 0'}}>
            <div className="trans-his item-wallet" style={{display: 'flex', fontSize: 11, width: '52%', flexBasis: 'unset'}}>
              <input style={{width: '45%', height: 20}} type="date" value={moment(search.fromDate).format('YYYY-MM-DD')} onChange={(e) => {
                if(e.target.value !== '' && e.target.value !==  moment(search.fromDate).format('YYYY-MM-DD')) {
                  // alert(e.target.value)
                  setSearch({...search, fromDate: moment(e.target.value).format('YYYY-MM-DD 00:00:00')})
                }
              }}/>
              To
              <input style={{width: '45%', height: 20}} type="date" value={moment(search.toDate).format('YYYY-MM-DD')} onChange={(e) => {
                if(e.target.value !== '' && e.target.value !==  moment(search.toDate).format('YYYY-MM-DD')) {
                  // alert(e.target.value)
                  setSearch({...search, toDate: moment(e.target.value).format('YYYY-MM-DD 23:59:59')})
                }
              }}/>
            </div>
            <div className="trans-his item-wallet" style={{display: 'flex', fontSize: 11, width: '16%', flexBasis: 'unset'}}>
              <select name='' id='' style={{height: 20, width: '100%'}} value={search.winlose} onChange={(e) => setSearch({...search, txType: e.target.value})}>
                <option value='winlose'>Winlose</option>
                <option value='deposit'>Deposit</option>
                <option value='withdraw'>Withdraw</option>
                <option value='bonus'>Bonus</option>
                <option value='comm'>Comm</option>
              </select>
            </div>
            <div className="trans-his item-wallet" style={{display: 'flex', fontSize: 11, width: '16%', flexBasis: 'unset'}}>
              <select name='' id='' style={{height: 20, width: '100%'}} value={search.game} onChange={(e) => setSearch({...search, game: e.target.value})}>
                <option value='all'>All game</option>
                {balanceGame.map(({balance}) => (<option value={balance.game_code} key={balance.game_code}>{balance.gameName}</option>))}
              </select>
            </div>
            <div className="trans-his item-wallet" style={{display: 'flex', fontSize: 11, width: '16%', flexBasis: 'unset'}}>
              <button className="btn-login_mb" style={{height: 20, width: 60,
                background: '#28a745',
                borderRadius: 1}}
                      onClick={searchData}
              >{t('SEARCH')}</button>
            </div>
          </div>
        </>:
        <button className="btn-login_mb" onClick={() => history.push("/login")}>
              <img src="images/icon/lock.png" alt="" /><span>LOGIN</span>
        </button>}

        <div className="value-banking" style={{overflowX: 'scroll', background: '#fff'}}>
          <Banking bankings={bankings} loading={loading}/>

        </div>

      </div>
      <CompCommon
        title={"Deposit"}
        show={isShowDeposit}
        close={() => { setShowDeposit(!isShowDeposit) }}
      >
        <NewDeposit />
      </CompCommon>
      <CompCommon
        title={"Withdraw"}
        show={isShowWithdraw}
        close={() => { setShowWithdraw(!isShowWithdraw) }}
      >
        <WithdrawNew />
      </CompCommon>
      <CompCommon
        title={"History"}
        show={isShowHistory}
        icon={"images/icon/Untitled-2.png" }
        getListHistory={getListHistory}
        close={() => {
          setShowHistory(!isShowHistory)
        }}
      >
        <NewHistoriesMb histories={histories} />
      </CompCommon>
    </>
  );
}

export default NewBankingMb
