import React, { useRef, useState } from 'react'
import {
  Checkbox,
  CssBaseline,
  FormControlLabel
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import TextInput from 'component/textInput'
import SelectInput from 'component/selectInput'
import Joi from '@hapi/joi'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { useTranslation } from 'react-i18next'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import './style.css'

const correctGameProps = ({ name, code, id, bank_account_name: bankAccountName, bank_account_no: bankAccountNo }) => ({
  title: name, value: {
    name, id, bankAccountName, bankAccountNo, code
  }
})

function Deposit({ classes, history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Deposit Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const formPayment = useRef(null)
  const [banks, updateBanks] = React.useState([])
  const [backendUrl, setBackendUrl] = useState()
  const [currency, setCurrency] = useState()
  const [memberId, setMemberId] = useState()
  const [memberIp, setMemberIp] = useState()
  const [partnerCode, setPartnerCode] = useState()
  const [partnerOrderid, setPartnerOrderid] = useState()
  const [redirectUrl, setRedirectUrl] = useState()
  const [serviceVersion, setServiceVersion] = useState()
  const [bank, setBank] = useState()
  const [sign, setSign] = useState()
  const [transTime, setTransTime] = useState()

  const onSubmit = async ({ bankCode, amount }) => {
    // console.log(bankCode)
    // return
    try {
      const { user_id: userId } = store.get('user')
      const ip = await fetch(`https://api.ipify.org?format=json`).then(response => response.json())
      const payload = await withLoading(() =>
        http.get({
          path: `api/payment/${userId}/sign`, params: {
            bankCode: bankCode.code, amount,
            ip: ip.ip
          }
        })
      )

      setBackendUrl(payload.backend_url)
      setCurrency(payload.currency)
      setMemberId(payload.member_id)
      setMemberIp(ip.ip)
      setPartnerCode(payload.partner_code)
      setPartnerOrderid(payload.partner_orderid)
      setRedirectUrl(payload.redirect_url)
      setServiceVersion(payload.service_version)
      setSign(payload.sign)
      setTransTime(payload.trans_time)
      setBank(payload.bank_code)
      console.log(formPayment.current)

      // return
      formPayment.current.submit()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const fetchData = async () => {
    try {
      const gamesResp = await withLoading(() => http.get({ path: 'banks' }))
      updateBanks(gamesResp.map(correctGameProps))
    } catch (error) {
      throw error
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const schema = Joi.object().keys({
    bankCode: Joi.object()
      .required(),
    amount: Joi.number()
      .required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [bankCode, amount] = formCreateInputs(['bankCode', 'amount'], form)

  return (
    <div className={`col8 ${classes.main}`}>
      <div className={`col-md-8 col-sm-12 ${classes.container}`}>
        <CssBaseline />
        <form onSubmit={handleSubmit} className={classes.form}>
          <SelectInput input={bankCode} options={banks} label={t('SELECT_BANK')} />
          <TextInput input={amount} label={t('TOPUP_AMOUNT')} />
          <FormControlLabel
            className='form-label-control'
            control={<Checkbox style={{ color: '#ffaf50' }} value="remember" color="primary" />}
            label={t('AGREEMENT')}
          />
          {loading ? (
            <div
              style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
            >
              <PropagateLoader
                sizeUnit="px"
                size={20}
                color="#f50057"
                loading={loading}
              />
            </div>
          ) : (
              <React.Fragment>
                <div className="text-center formGroup_btn">
                  <button disabled={submitting} type="submit" className="col-md-8 col-12 reload"><span></span>{t('ONLINE_TOP_UP_NOW')}</button>
                  <button onClick={() => history.push('/deposit')} className="col-md-8 col-12 reload"><span></span>{t('DEPOSIT_WITH_RECEIPT')}</button>
                </div>
              </React.Fragment>
            )}
        </form>
      </div>

      <form style={{ display: 'none' }} method="post" action="http://api.besthappylife.biz/MerchantTransfer" ref={formPayment}>
        <input type="hidden" name="Merchant" value={partnerCode} />
        <input type="hidden" name="Currency" value={currency} />
        <input type="hidden" name="Customer" value={memberId} />
        <input type="hidden" name="Reference" value={partnerOrderid} />
        <input type="hidden" name="Key" value={sign} />
        <input type="hidden" name="Amount" {...amount.input} value={amount.input.value} />
        <input type="hidden" name="Note" value="" />
        <input type="hidden" name="Datetime" value={transTime} />
        <input type="hidden" name="FrontURI" value={redirectUrl}/>
        <input type="hidden" name="BackURI" value={backendUrl} />
        <input type="hidden" name="Language" value="en-us" />
        <input type="hidden" name="Bank" value={bank}/>
        <input type="hidden" name="ClientIP" value={memberIp} />
      </form>

      <ErrorDialog />
    </div>
  )
}

export default withStyles(styles)(Deposit)
