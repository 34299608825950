/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import Header from 'component/header'
import CloseIcon from '@material-ui/icons/Close'
import { map, isEmpty } from 'ramda'
import Bottom from 'component/bottom'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import useLoading from '../loading/hook'
import styles from './style'
import SLOTS from './constant'
import config from 'config'
import queryString from 'query-string'
import GameAccount from '../../component/gameAccount'
import TotalPlay from '../../component/layout/TotalPlay'
import Head from '../../component/layout/Head'
import Testimonials from '../../component/layout/Testimonials'
import LiveCasinoComp from 'feature/LiveCasinoComp'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: '',
    2: '',
    1: ''
  }

  return proxy[id]
}

function home({ history, classes }) {
  const {
    location: { search }
  } = history
  const [loading, withLoading] = useLoading(false)
  const [gameSelected, updateGameSelected] = React.useState({})
  const { game } = queryString.parse(search)
  const [games, setGames] = React.useState([])
  const { user_id: userId } = store.get('user') || { user_id: undefined }

  const gamesSetup = async () => {
    const gameList = await http.get({ path: 'games' })
    setGames(gameList)
    return gameList
  }

  const loginToGame = async (
    { src, label, route, id, redirect, target, deeplink },
    gameList = null
  ) => {
    if (target === '_blank') {
      console.log('route', route)
      window.open(route)
    }
    if (redirect) {
      history.push(route)
      return
    }
    console.log(
      id,
      games.find(({ id: gameId }) => id === gameId)
    )
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    updateGameSelected({ label, src, route, id })
    const {
      balance,
      account,
      err,
      game_password: password
    } = await withLoading(() =>
      http
        .post({
          path: `users/${userId}/game/logout`,
          payload: {
            gameLoginId: id
          }
        })
        .catch(error => {
          if (error.message !== 'Not enough money !') {
            // eslint-disable-next-line no-throw-literal
            throw { message: error.message }
          }
          return true
        })
        // .then(() => deeplink || [10,4, 14].includes(id) ? true : http.get({ path: `games/${id}/login` }))
        .then(() => http.get({ path: `users/${userId}/games/${id}/balance` }))
    ).catch(error => {
      return { err: error.message }
    })

    const outDeeplink = generateDeeplink({ id, account, password })

    updateGameSelected({
      label,
      src,
      route,
      id,
      balance: balance.balance,
      account: balance.account,
      err,
      deeplink: outDeeplink,
      password
    })
    if (!outDeeplink && outDeeplink !== '') history.push(route)
  }

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` })
    }
    async function fetchGame() {
      const gameList = await gamesSetup()
      // console.log(gameList)
      if (game) {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink
        } = SLOTS.find(x => x.code === game)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink },
          gameList
        )
      }
    }
    fetchGame()
  }, [])

  const handleClose = () => {
    updateGameSelected({})
  }
  const { t } = useTranslation()
  const image = './images/home/bannercasino.png'
  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
      <Head image={image} alt="bannercasino" />
      <div className="pages">
        <button className="pages-btn-items">Casino</button>
        <LiveCasinoComp history={history} />
        <img
          style={{ width: '100%', margin: '30px 0' }}
          src="./images/home/bannercasinoplay.png"
          alt="bannercasino"
        />
        <TotalPlay />
        <Testimonials />
      </div>
    </div>
    // <div className="content">
    //   <div className="ml-2 mr-2">
    //     {/* <div className="title_recoment tile_deposit">{t('LIVE_CASINO')}</div> */}
    //     <div className="row">
    //       {/* {SLOTS.filter(x => config.gamesAvailable.includes(x.code)).map(({ src, label,code, route, id, redirect, target, deeplink, idrActive }) => ( */}
    //       {SLOTS.map(({ src, label, code,route, id, redirect, target, deeplink, idrActive }) => (
    //         // && idrActive == 0

    //           <div
    //             key={label}
    //             className='col-4 col-sm-3 mb-4'
    //             cols={2.5}
    //             onClick={() => loginToGame({ src, label, route, id, redirect, target, deeplink })}
    //           >
    //             <img
    //               style={{
    //                 width: '100%',
    //                 borderRadius: 10,
    //                 boxShadow: '0px 0px 8px #C09981',
    //                 cursor: 'pointer'
    //               }}
    //               src={src}
    //               alt='97ipay'

    //             />
    //           </div>
    //        ))

    //       }
    //     </div>
    //   </div>
    //   <Dialog fullScreen open={!isEmpty(gameSelected)} onClose={handleClose} TransitionComponent={Transition}>
    //     <AppBar className={classes.appBar}>
    //       <Toolbar>
    //         <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
    //           <CloseIcon />
    //         </IconButton>
    //         <Typography variant="h6" className={classes.title}>
    //           {gameSelected.label}
    //         </Typography>
    //       </Toolbar>
    //     </AppBar>
    //     <div style={{ background: '#23232A', padding: 25, marginTop: 70, height: 'calc(100vh - 70px)', overflow: 'auto' }}>
    //       <Card style={{ background: 'none', border: '1px solid #ffaf50', maxWidth: '400px', margin: '0 auto' }}>
    //         <CardActionArea>
    //           <CardMedia
    //             component="img"
    //             alt="Contemplative Reptile"
    //             className={classes.media}
    //             image={gameSelected.src}
    //             title="Contemplative Reptile"
    //           />
    //           <CardContent style={{ textAlign: 'center' }}>
    //             {
    //               gameSelected.err
    //                 ? <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
    //                   {gameSelected.err}
    //                 </Typography>
    //                 : loading
    //                   ? <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }}>
    //                     <PropagateLoader
    //                       sizeUnit="px"
    //                       size={20}
    //                       color="#f50057"
    //                       loading={loading}
    //                     />
    //                   </div>
    //                   : <GameAccount account={gameSelected.account}
    //                                  password={gameSelected.password}
    //                                  deeplink={gameSelected.deeplink}
    //                                  route={gameSelected.route}
    //                                  balance={gameSelected.balance}></GameAccount>
    //                 // <React.Fragment>
    //                 //     <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
    //                 //       Account: {gameSelected.account}
    //                 //     </Typography>
    //                 //     {gameSelected.password ? <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
    //                 //       Password: {gameSelected.password}
    //                 //     </Typography>
    //                 //       : null}
    //                 //     <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
    //                 //       Balance: {gameSelected.balance}
    //                 //     </Typography>
    //                 //     {gameSelected.deeplink !== undefined
    //                 //       ? <a className='link-btn-default' href={gameSelected.deeplink}>Play now</a>
    //                 //       : <Button onClick={() => history.push(gameSelected.route)} fullWidth variant="contained" color="primary" className={classes.submit}>
    //                 //         <strong>Play now</strong>
    //                 //       </Button>
    //                 //     }
    //                 //   </React.Fragment>
    //             }
    //           </CardContent>
    //         </CardActionArea>
    //       </Card>
    //     </div>
    //   </Dialog>
    // </div>
  )
}
export default withStyles(styles)(home)
