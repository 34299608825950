/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'
import store from 'store'

const { useDialog, useLoading } = component

function Allbet() {
  const [Dialog, showDialog] = useDialog({
    title: 'Maxbet'
  })

  const [loading, withLoading] = useLoading()
  const login = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 6)) {
        const { mobile: [, gameLoginUrl] } = await withLoading(() => http.post({
          path: `users/${userId}/game/logout`, payload: {
            gameLoginId: '6'
          }
        })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message }
            }
            return true
          })
          .then(() => http.get({ path: `games/6/login` })))
        window.location = gameLoginUrl
      }
      return showDialog('Maxbet is Maintenance ')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    login()
  }, [])
  return (
    <div className="col8">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : null}
      <Dialog />
    </div>
  )
}

export default Allbet