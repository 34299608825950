/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import http from 'service/http'
import { useTranslation } from 'react-i18next'
import './style.css'
import useLoading from '../loading/hook'
import store from 'store'
// import socket from 'service/socket'
import useDialog from '../dialog/hook'
import Resizer from 'react-image-file-resizer';
import { Checkbox, Button, Form, Modal, Select, Radio, Space, Upload, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import InputField from '../../component/textInput/InputField'
import { showNotification } from '../../utils/function'
import { ETypeNotification } from '../../constants/gameData'
import FormComponent from '../../component/form/FormComponent'
import moment from 'moment'
import MenuItem from '../../component/layout/MenuItem'
import BoxLeft from '../../component/layout/BoxLeft'
import BoxRight from '../../component/layout/BoxRight'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import StyledButton from '../../component/button/syledButton'
import { PropagateLoader } from 'react-spinners'
const { Option } = Select;
const correctGameProps = ({
  name, id,
  bank_account_name: bankAccountName,
  bank_account_no: bankAccountNo
}) => ({
  title: name, value: {
    name, id, bankAccountName, bankAccountNo
  }
})
const arrbanks = [{
  image: 'images/icon/bankiconkrung.png',
  bank_account_name: "Leagend club",
  bank_account_no: "1234567890",
  code: "",
  id: 45,
  name: "Krungsri Bank",
  type: "Deposit"
}, {
  image: 'images/icon/ThaibankRHB.png',
  bank_account_name: "Leagend club",
  bank_account_no: "1234567890",
  code: "",
  id: 45,
  name: "Rhb",
  type: "Deposit"
}, {
  image: 'images/icon/ThaibankCIMB.png',
  bank_account_name: "Leagend club",
  bank_account_no: "1234567890",
  code: "",
  id: 45,
  name: "Cimb",
  type: "Deposit"
}, {
  image: 'images/icon/bank_icon.png',
  bank_account_name: "Leagend club",
  bank_account_no: "1234567890",
  code: "",
  id: 45,
  name: "KrungThai Bank",
  type: "Deposit"
}, {
  image: 'images/icon/bank_icon1.png',
  bank_account_name: "Leagend club",
  bank_account_no: "1234567890",
  code: "",
  id: 46,
  name: "Kasikorn Bank",
  type: "Deposit"
}, {
  image: 'images/icon/bank_icon2.png',
  bank_account_name: "Leagend club",
  bank_account_no: "1234567890",
  code: "",
  id: 47,
  name: "SCB Bank",
  type: "Deposit"
}]

function NewDepositNewTest({ history }) {
  const { t } = useTranslation()
  const [banks, updateBanks] = React.useState([])
  const [userState, setUserState] = React.useState({})
  const [checkedBank, setCheckedBank] = useState(1);
  const [checkedBonus, setCheckedBonus] = useState(0);
  const [dataForm, setDataForm] = useState({})
  const user = store.get('user')
  const [bonus, setBonus] = useState([])
  const [kissvipBanks, setKissvipbanks] = useState([])
  const [bonusId, setBonusId] = useState(null)
  const [bankCode, setBankCode] = useState("")
  const [typeSubmit, setTypeSubmit] = useState(false)
  const [selectedBank, setSelectedBank] = useState('');
  const [showBank, setShowBank] = useState(true);
  const [loading, withLoading] = useLoading(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeBank, setTypeBank] = useState("");
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Deposit Successful'
  })
  const [changeAmount, setChangeAmount] = useState("0")
  const [checkbank, setCheckbank] = useState('')
  const [depositOption, setDepositOption] = useState(1)
  const handleChangeActive = (item, bank) => {
    setCheckbank(bank)
    handleCheck(item)
  }
  const handleChangeAmount = (amount) => {
    setChangeAmount(amount)
    form.setFieldValue("amount", amount)
  }
  const min = 0
  const max = 3000000

  const handleInputChange = e => {
    if (!e) {
      setChangeAmount('')
      return
    }
    if (!Number.isNaN(+e)) {
      const val = String(Math.max(min, Math.min(max, Number(e))))
      setChangeAmount(val)
    }
  }
  const bankOnline =  [
    {
      value: "BKKB",
      title: "Bangkok Bank"
    },
    {
      value: "BAAC",
      title: "Bank for Agriculture and Agricultural Cooperatives	"
    },
    {
      value: "KSAB",
      title: "Bank of Ayudhya (Krungsri)"
    },
    {
      value: "CIMT",
      title: "CIMB Thai Bank"
    },
    {
      value: "GSBA",
      title: "Government Savings Bank	"
    },
    {
      value: "KSKB",
      title: "Kasikorn Bank	"
    },
    {
      value: "Kiatnakin Bank",
      title: "Kiatnakin Bank"
    },
    {
      value: "KTBA",
      title: "Krung Thai Bank	"
    },
    {
      value: "SCBB",
      title: "Siam Commercial Bank"
    },
    {
      value: "SCBT",
      title: "Standard Chartered Bank (Thai)"
    }
  ]

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Deposit Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchData = async () => {
    try {
      const [gamesResp] = await withLoading(() => Promise.all([
        http.get({ path: 'banks' }),
      ]))
      const newBanks = gamesResp.filter(x => x.type === 'Deposit').map(g => {
        const found = arrbanks.find(x => x.name == g.name)
        if (found) g.image = found.image
        return g
      })
      updateBanks(newBanks)
    } catch (error) {
      throw error
    }
  }
  const fetchAccount = async () => {
    const [userResp] = await withLoading(() => Promise.all([
      http.get({ path: 'me' }),
    ]))
    store.set('user', userResp)
    setUserState(userResp)

  }
  const fileChangedHandler = () => {
    const { files: [file] } = document.querySelector('input[type=file]')
    let fileInput = false
    if (file) {
      fileInput = true
    }
    return new Promise(resolve => {
      if (fileInput) {
        Resizer.imageFileResizer(
          file,
          1900,
          900,
          'JPEG',
          50,
          0,
          uri => {
            resolve(uri)
          },
          'base64'
        );
      }
    })
  }
  const onSubmit = async payload => {
    try {
      const { user_id: userId, user_account_no: username } = store.get('user')
      // const proofOfDeposit = await fileToBase64()
      console.log("payload", payload);
      const proofOfDeposit = await fileChangedHandler()
      const submited = {
        amount: payload.amount,
        currency: 'MYR',
        to_bank_account_name: payload.bank_account_name,
        to_bank_account_number: payload.bank_account_no,
        to_bank: payload.name,
        to_bank_id: payload.id,
        from_bank: user.bankName,
        username,
        type: payload.type ? payload.type : "Online Transfer",
        receipt_image: proofOfDeposit
      }
      if(bonusId) submited.bonusId = bonusId
      // console.log(bonusId);
      await withLoading(() =>
        http.post({ path: `users/${userId}/deposit`, payload: submited })
      )
      // socket.emit('messages', {
      //   msgType: 'deposit.request',
      //   data: {
      //     message: `Request deposit from '${username}'`,
      //     title: 'Deposit Request'
      //   }
      // })
      showNotification(ETypeNotification.SUCCESS, 'Your request submitted successfully')
      // navigate('/account')
      // window.location.reload();
    } catch (error) {
      console.log('error.message', error);
      showNotification(ETypeNotification.ERROR, error.message)
    }
  }
  React.useEffect(() => {
    fetchData();
    fetchAccount()
    form.setFieldsValue({
      name: dataForm.name,
      bank_account_name: dataForm.bank_account_name,
      bank_account_no: dataForm.bank_account_no
    });
  }, [form, dataForm])

  const handleCheck = (item) => {
    setCheckedBank(item.id)
    setDataForm(item)
  };
  const handleCheckBonus = (id) => {
    // console.log(id)
    setBonusId(id)
    setCheckedBonus(checkedBonus == 1 ? 0 : 1)
  };

  const handlerSelected = (item) => {
    // console.log(item)
    setBankCode(item)
    const f = kissvipBanks.find(x => x.id === item)
    setSelectedBank(f.name)
    setCheckbank(f.name)
  }
  
  const handleSubmit = (values) => {
    if (['kissvippay', 'kissvippayqr', 'kissvippayqrtrue'].includes(typeBank)) {
      onSubmitHandlerGetIdItemKissvip({ ...values, bankCode: bankCode, selectedBank: selectedBank })
      // onSubmitVaderpay({...values})
    }
    else if (typeSubmit) {
      onSubmitHandlerGetIdItem({ ...values, bankCode: bankCode, selectedBank: selectedBank })
      // onSubmitVaderpay({...values})
    }
    else {
      onSubmit({ ...values, id: dataForm.id, selectedBank: selectedBank, type: typeBank })
    }

  }
  const onSubmitHandlerGetIdItemKissvip = async payload => {
    // console.log(payload)
    try {
      let submited = {
        bankCode: payload.bankCode,
        bankName: payload.selectedBank,
        amount: payload.amount,
      }
      if (bonusId) submited.bonusId = bonusId
      let route = `payment-kissvip`
      if(typeBank === 'kissvippayqr') {
        submited = {
          amount: Number(payload.amount),
          postback_url: window.location.href
        }
        route = 'payment-kissvip/qr-deposit'
      } else if(typeBank === 'kissvippayqrtrue') {
        submited = {
          amount: Number(payload.amount),
          postback_url: window.location.href
        }
        route = 'payment-kissvip/qr-true-deposit'
      }
      const response = await withLoading(() =>
        http.post({ path: route, payload: submited })
     )
// console.log(response)
      if(!response.errors){
        showDialogWithMessage('Please Process ...')
        window.location.href = response.redirect_path ? response.redirect_path : response.redirect_to
     } else {
        const keys = Object.keys(response.errors)
        showDialogErrorWithMessage(response.errors[keys[0]])
      }
     // window.location.reload()
    } catch (e) {
      console.log('e', e);
    }
  }
  const onSubmitHandlerGetIdItem = async payload => {
    try {
      const submited = {
        bankCode: payload.bankCode,
        amount: payload.amount,
      }
      console.log(submited)
      if(bonusId) submited.bonusId = bonusId
      const response = await withLoading(() =>
      http.post({ path: `payment-vaderpay`, payload: submited })
     )

      if(response){
        const linkRedirect = response.redirect_to;
        const link = linkRedirect.split("redirectlink=");
        window.open(link && link[1], '_blank');
     }
     window.location.reload()
    } catch (e) {
      console.log('e', e);
    }
  }
  const handlerSubmitChangeUser = (values) => {
    onSubmitFormUpdateUser({ ...values })
    fetchAccount()
    setIsModalVisible(false);
  }
  const onSubmitFormUpdateUser = async payload => {
    try {
      const { user_id: userId } = store.get('user')
      const submited = {
        bankName: payload.bankName,
        bankAccount: payload.bankAccount,
        bankAccountNumber: payload.bankAccountNumber
      }
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: submited })
      )
      showNotification(ETypeNotification.SUCCESS, 'Update Your Bank Detail Success')

    } catch (error) {
      showNotification(ETypeNotification.ERROR, error.message)
    }
  }
  const [form] = Form.useForm();

  const newItemBank = {};
  newItemBank.id = banks.length + 1;
  newItemBank.name = userState.bankName;
  newItemBank.bank_account_no = userState.bankAccountNumber;
  const mergeBank = [{ ...newItemBank }]

  const handlerShowBankSelected = (item) => {
    setDepositOption(item)
    if (item == 4) {
      setShowBank(false)
      setTypeSubmit(false)
      setTypeBank(item == 4 ? "kissvippayqr": "")
    }
    else if (item == 5) {
      setShowBank(false)
      setTypeSubmit(false)
      setTypeBank(item == 5 ? "kissvippayqrtrue": "")
    }
    else if (item == 3) {
      setShowBank(false)
      setTypeSubmit(false)
      setTypeBank(item == 3 ? "kissvippay": "")
    }
    else if (item == 2) {
      setShowBank(false)
      setTypeSubmit(true)
      setTypeBank(item == 2 ? "payment gateway": "")
    }
    else{
      setShowBank(true)
      setTypeSubmit(false)
      setTypeBank(item == 1 ? "banking": "")
    }

  }
  const handlerSelectedBankTHB  = (item) => {
    // console.log('item', item);
    setBankCode(item)
  }
  const fetchBonus = async () => {
      const [bonus, b] = await withLoading(() =>
        Promise.all([
          http.get({ path: `admin/promotion-new` }),
          http.get({ path: `payment-kissvip/banks` })
      ]))
      const activeBonus = bonus.filter(x => x.active == 1)
      setBonus(activeBonus)
      setKissvipbanks(b.accounts.filter(x => x.status === "A"))
  }
  React.useEffect(() => {
    fetchBonus()
  }, [])

  return (
    <div className="containerActive">
      <MenuItem />
      <div className="styled-title-router">Deposit</div>
      <div className="styledContainer">
        <BoxLeft />
        <Form
          className="deposit-new-container"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ padding: '20px' }}
        >
          <div className="deposit-new-content">
            <p className="deposit-new-title">
              Deposit Options <span className="type-red">*</span>
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                flexWrap: 'wrap'
              }}
            >
              <div className="deposit-new-container-box">
                <div
                  className={
                    depositOption === 1
                      ? 'deposit-new-box-active'
                      : 'deposit-new-box'
                  }
                  onClick={() => handlerShowBankSelected(1)}
                >
                  {depositOption === 1 ? (
                    <img
                      style={{ width: '30px' }}
                      src="./images/home/bankwhite.svg"
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: '30px' }}
                      src="./images/home/bank.svg"
                      alt=""
                    />
                  )}
                </div>
                <p className="deposit-new-text">Bank Transfer</p>
              </div>
              <div className="deposit-new-container-box">
                <div
                  className={
                    depositOption === 2
                      ? 'deposit-new-box-active'
                      : 'deposit-new-box'
                  }
                  onClick={() => handlerShowBankSelected(2)}
                >
                  <img src="./images/home/quickpay.png" alt="" />
                </div>
                <p className="deposit-new-text">Quick Pay</p>
              </div>
              <div className="deposit-new-container-box">
                <div
                  className={
                    depositOption === 3
                      ? 'deposit-new-box-active'
                      : 'deposit-new-box'
                  }
                  onClick={() => handlerShowBankSelected(3)}
                >
                  <img
                    style={{ width: '81%' }}
                    src="./images/home/logovipwhite.png"
                    alt=""
                  />
                </div>
                <p className="deposit-new-text">VIP Pay</p>
              </div>
              <div className="deposit-new-container-box">
                <div
                  className={
                    depositOption === 4
                      ? 'deposit-new-box-active'
                      : 'deposit-new-box'
                  }
                  onClick={() => handlerShowBankSelected(4)}
                >
                  <img
                    style={{ width: '81%' }}
                    src="./images/home/logovipwhite.png"
                    alt=""
                  />
                </div>
                <p className="deposit-new-text">VIP Pay QR</p>
              </div>
              <div className="deposit-new-container-box">
                <div
                  className={
                    depositOption === 5
                      ? 'deposit-new-box-active'
                      : 'deposit-new-box'
                  }
                  onClick={() => handlerShowBankSelected(5)}
                >
                  <img
                    style={{ width: '81%' }}
                    src="./images/home/logovipwhite.png"
                    alt=""
                  />
                </div>
                <p className="deposit-new-text">VIP Pay QR True</p>
              </div>
            </div>
          </div>

          <div className="deposit-new-content">
            <PropagateLoader
              sizeUnit="px"
              size={20}
              color="#f50057"
              loading={loading}
            />
            {showBank && (
              <div className="styled-group-banks">
                <div className="styled-bank">
                  <div
                    className={
                      checkbank === 'kbank'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                    onClick={() => handleChangeActive(banks[0], 'kbank')}
                  >
                    {checkbank === 'kbank' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/kbankactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/kbankunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Kbank</p>
                  </div>
                  <div
                    className={
                      checkbank === 'krungthai'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                    onClick={() => handleChangeActive(!banks[3], 'krungthai')}
                  >
                    {checkbank === 'krungthai' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/krungactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/krungunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Krungthai</p>
                  </div>
                  <div
                    className={
                      checkbank === 'scb'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                    onClick={() => handleChangeActive(banks[2], 'scb')}
                  >
                    {checkbank === 'scb' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/acbactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/acbunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">SCB</p>
                  </div>
                  <div
                    className={
                      checkbank === 'krungsri'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                    onClick={() => handleChangeActive(banks[1], 'krungsri')}
                  >
                    {checkbank === 'krungsri' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/krungsriactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/krungsriunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Krungsri</p>
                  </div>
                  <div
                    className={
                      checkbank === 'bangkok'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                    onClick={() => handleChangeActive(!banks[3], 'bangkok')}
                  >
                    {checkbank === 'bangkok' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/bankokbank.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/bankokbank.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Bangkok Bank</p>
                  </div>
                  <div
                    className={
                      checkbank === 'ttb'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                    onClick={() => handleChangeActive(!banks[3], 'ttb')}
                  >
                    {checkbank === 'ttb' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/ttbactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/ttbunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">TTB</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="deposit-new-content">
            {showBank && (
              <>
                <div className="show_mb">
                  <Form.Item name="name" label="Bank Name">
                    <InputField
                      disabled
                      size="default"
                      placeholder="Bank Name"
                    />
                  </Form.Item>
                </div>
                <div className="hide_mb">
                  <Form.Item name="name" label="Bank Name">
                    <InputField disabled size="large" placeholder="Bank Name" />
                  </Form.Item>
                </div>
                <div className="show_mb">
                  <Form.Item name="bank_account_name" label="Account Name">
                    <InputField
                      disabled
                      size="default"
                      placeholder="Account Name"
                    />
                  </Form.Item>
                </div>
                <div className="hide_mb">
                  <Form.Item name="bank_account_name" label="Account Name">
                    <InputField
                      disabled
                      size="large"
                      placeholder="Account Name"
                    />
                  </Form.Item>
                </div>
                <div className="hide_mb">
                  <Form.Item name="bank_account_no" label="Account No.">
                    <InputField
                      disabled
                      size="large"
                      placeholder="Account No."
                    />
                  </Form.Item>
                </div>
                <div className="show_mb">
                  <Form.Item name="bank_account_no" label="Account No.">
                    <InputField
                      disabled
                      size="default"
                      placeholder="Account No."
                    />
                  </Form.Item>
                </div>
              </>
            )}
          </div>
          <div className="deposit-new-content">
            {typeBank === 'payment gateway' && (
              <div className="input-deposit">
                <label>
                  Bank <span className="tick">*</span>
                </label>
                {/* <div className="styled-group-banks">
                  <div className="styled-bank">
                    {bankOnline.map((bank, ix) => (
                      <div
                        key={ix}
                        className={
                          checkbank === bank.value
                            ? 'styled-item-bank-active'
                            : 'styled-item-bank'
                        }
                        onClick={() => setCheckbank(bank.value)}
                      >
                        <img
                          className="style-bank"
                          src={bank?.logo_url}
                          alt="bank"
                        />
                        <p className="withdraw-text">{bank.value}</p>
                      </div>
                    ))}
                  </div>
                </div> */}
                <div className=" input-select-channel d-flex">
                  <Select
                    size="large"
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={handlerSelectedBankTHB}
                    placeholder="Select bank..."
                    style={{ width: '100%' }}
                  >
                    <Option value="">{t('SELECT_BANK')}</Option>
                    {bankOnline &&
                      bankOnline.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.title}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            )}
            {typeBank === 'kissvippay' && (
              <div className="input-deposit">
                <label>
                  Bank <span className="tick">*</span>
                </label>
                <div className="styled-group-banks">
                  <div className="styled-bank">
                    {kissvipBanks.map((bank, ix) => (
                      <div
                        key={ix}
                        className={
                          checkbank === bank.name
                            ? 'styled-item-bank-active'
                            : 'styled-item-bank'
                        }
                        // onClick={() => setCheckbank(bank.name)}
                        onClick={() => handlerSelected(bank.id)}
                      >
                        <img
                          className="style-bank"
                          src={bank.logo_url}
                          alt="bank"
                        />
                        <p className="withdraw-text">{bank.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className=" input-select-channel d-flex">
                  {/* <Select
                    size="large"
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={handlerSelected}
                    placeholder="Select bank..."
                    style={{ width: '100%' }}
                  >
                    {kissvipBanks.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select> */}
                </div>
              </div>
            )}
            {typeBank === 'kissvippayqr' && (
              <div className="input-deposit">
              </div>
            )}
            {typeBank === 'kissvippayqrtrue' && (
              <div className="input-deposit">
              </div>
            )}
          </div>
          {showBank && (
            <div className="input-deposit">
              <label>{t('BANK_INFO')}:</label>
              <div className="input-select-channel d-flex ml-4">
                <label>{user.bankName}</label>
              </div>
              <div className="input-select-channel d-flex ml-4">
                <label>{user.bankAccount}</label>
              </div>
              <div className="input-select-channel d-flex ml-4">
                <label>{user.bankAccountNumber}</label>
              </div>
            </div>
          )}
          <div className="deposit-new-content">
            <p className="deposit-new-title">
              Amount <span className="type-red">*</span>
            </p>
            <div className="deposit-new-styled-amount">
              <Form.Item name="amount" style={{ marginBottom: '0' }}>
                <input
                  min={min}
                  max={max}
                  type="text"
                  className="deposit-new-input-amount"
                  onChange={e => handleInputChange(e.target.value)}
                  size="default"
                />
              </Form.Item>
              {/* <img
                id="warningdeposit"
                className="styled-warning"
                src="./images/home/warningAmount.svg"
                alt=""
              />
              <Tooltip
                className="styled-warning"
                anchorId="warningdeposit"
                place="right"
                content="Min/Max Limit: 30.00/30,000.00"
              /> */}
              <div className="wraning-amount">
                <span className="type-red">* </span>Min/Max Limit:
                50.00/100,000.00
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                margin: '10px 0'
              }}
            >
              <div
                className={
                  changeAmount === '50'
                    ? 'deposit-new-amount-option-active'
                    : 'deposit-new-amount-option'
                }
                onClick={() => handleChangeAmount('50')}
              >
                50
              </div>
              <div
                className={
                  changeAmount === '100'
                    ? 'deposit-new-amount-option-active'
                    : 'deposit-new-amount-option'
                }
                onClick={() => handleChangeAmount('100')}
              >
                100
              </div>
              <div
                className={
                  changeAmount === '300'
                    ? 'deposit-new-amount-option-active'
                    : 'deposit-new-amount-option'
                }
                onClick={() => handleChangeAmount('300')}
              >
                300
              </div>
              <div
                className={
                  changeAmount === '500'
                    ? 'deposit-new-amount-option-active'
                    : 'deposit-new-amount-option'
                }
                onClick={() => handleChangeAmount('500')}
              >
                500
              </div>
              <div
                className={
                  changeAmount === '1000'
                    ? 'deposit-new-amount-option-active'
                    : 'deposit-new-amount-option'
                }
                onClick={() => handleChangeAmount('1000')}
              >
                1000
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center'
              }}
            >
              {showBank && (
                <>
                  <input
                    style={{ display: 'none' }}
                    id="uploadBnb"
                    type="file"
                    className="input_block input_white"
                  />
                  <Button
                    onClick={() => {
                      document.getElementById('uploadBnb').click()
                    }}
                    style={{ background: 'red', color: '#fff' }}
                    icon={<UploadOutlined />}
                  >
                    Up Load Receipt
                  </Button>
                </>
              )}
              <StyledButton
                width="250px"
                height="35px"
                background="#FF8A00"
                id="custom-primary-button-deposit"
                loading={loading}
                htmlType="submit"
              >
                Submit
              </StyledButton>
            </div>
          </div>
          <Modal
            footer={null}
            title="Update bank"
            visible={isModalVisible}
            onCancel={handleCancel}
          >
            <FormComponent handlerSubmit={handlerSubmitChangeUser}>
              <Form.Item
                name="bankName"
                label="Bank Name"
                style={{ marginBottom: '10px' }}
              >
                <InputField
                  type="text"
                  size="default"
                  placeholder="Bank Name..."
                />
              </Form.Item>
              <Form.Item name="bankAccount" label="Bank Account">
                <InputField
                  type="text"
                  size="default"
                  placeholder="Bank Account.."
                />
              </Form.Item>
              <Form.Item name="bankAccountNumber" label="Account No">
                <InputField
                  type="text"
                  size="default"
                  placeholder="Account No.."
                />
              </Form.Item>
              <div className="text-center formGroup_btn">
                <Button type="primary" loading={loading} htmlType="submit">
                  Update
                </Button>
              </div>
            </FormComponent>
          </Modal>
          {/* <div className="deposit-new-content">
            <div className="styled-container-table">
              <table className="styled-table">
                <tr>
                  <th>Transaction Date</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
                <tr>
                  <td>Alfreds Futterkiste</td>
                  <td>Maria Anders</td>
                  <td>Germany</td>
                  <td>Germany</td>
                </tr>
              </table>
            </div>
          </div> */}
        </Form>
        <div className="showBoxright">
          <BoxRight />
        </div>
      </div>
      <div className="showBoxrightmb">
        <BoxRight />
      </div>
      <Dialog />
      <ErrorDialog />
    </div>
  )
}

export default NewDepositNewTest
