/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import config from 'config'
import Joi from '@hapi/joi'
// import socket from 'service/socket'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { Checkbox, Button, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import CurrentBalance from 'component/CurrentBalance'
import './style.css'
import InputField from '../../component/textInput/InputField'
import { ETypeNotification } from '../../constants/gameData'
import { navigate } from '@reach/router'
import { showNotification } from '../../utils/function'
const { Option } = Select
const correctGameProps = ({ name }) => ({ title: name, value: name })
const bankOnline = [
  {
    value: 'BKKB',
    title: 'Bangkok Bank'
  },
  {
    value: 'BAAC',
    title: 'Bank for Agriculture and Agricultural Cooperatives'
  },
  {
    value: 'KSAB',
    title: 'Bank of Ayudhya (Krungsri)'
  },
  {
    value: 'CIMT',
    title: 'CIMB Thai Bank'
  },
  {
    value: 'GSBA',
    title: 'Government Savings Bank'
  },
  {
    value: 'KSKB',
    title: 'Kasikorn Bank'
  },
  {
    value: 'Kiatnakin Bank',
    title: 'Kiatnakin Bank'
  },
  {
    value: 'KTBA',
    title: 'Krung Thai Bank'
  },
  {
    value: 'SCBB',
    title: 'Siam Commercial Bank'
  },
  {
    value: 'SCBT',
    title: 'Standard Chartered Bank (Thai)'
  }
]
function WithdrawNew({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [banks, updateBanks] = React.useState([])
  const [bankOption, setbankOption] = React.useState({})
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('WITH_SUC')
  })

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t('WITH_ERR'),
    btnLabel: t('GOT_IT'),
    type: 'error'
  })
  const user = store.get('user')
  console.log(user)
  const onSubmit = async payload => {
    try {
      const { user_id: userId, user_account_no: username } = store.get('user')
      const bankCode = bankOnline.find(x => x.title == payload.bank_name)
      console.log(bankCode, payload)
      if (!bankCode)
        showNotification(ETypeNotification.ERROR, 'Bank name Invalid')
      else {
        const submited = {
          amount: payload.amount,
          bank_name: payload.bank_name,
          bankCode: payload.bankCode,
          bank_account_name: payload.bank_account_name,
          bank_account_number: payload.bank_account_no,
          currency: config.currency,
          payment_method: 'transaction',
          username
        }
        await withLoading(() =>
          http.post({ path: `users/${userId}/withdraw`, payload: submited })
        )
        // socket.emit('messages', {
        //   msgType: 'withdraw.request',
        //   data: {
        //     message: `Request withdraw from '${username}'`,
        //     title: 'Withdraw Request'
        //   }
        // })
        showNotification(ETypeNotification.SUCCESS, 'Success !')
        // navigate('/account')
        // window.location.reload();
        // showDialogWithMessage(t('WITH_DONE'), () => history.push('/account'))
      }
    } catch (error) {
      showNotification(ETypeNotification.ERROR, error.message)
      // alert(error.message)
      // showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    bankName: Joi.string()
      .label('Bank Name')
      .required(),
    bankAccountName: Joi.string()
      .label('Bank Account Name')
      .required(),
    bankAccountNo: Joi.string()
      .label('Bank Account Number')
      .required(),
    amount: Joi.number()
      // .min(10)
      .required()
  })

  const fetchData = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const [gamesResp] = await withLoading(() =>
        Promise.all([http.get({ path: 'banks' })])
      )
      // updateBanks(gamesResp);
      updateBanks(
        gamesResp.filter(x => x.type === 'Withdraw').map(correctGameProps)
      )
    } catch (error) {
      throw error
    }
  }

  React.useEffect(() => {
    fetchData()
    form.setFieldsValue({
      bank_name: user.bankName,
      bankCode: user.bankCode,
      bank_account_name: user.bankAccount,
      bank_account_no: user.bankAccountNumber
    })
  }, [form])

  const [form] = Form.useForm()
  const handleSubmit = values => {
    // console.log('values', values);
    onSubmit({
      ...values,
      bank_name: user.bankName,
      bankCode: user.bankCode,
      bank_account_name: user.bankAccount,
      bank_account_no: user.bankAccountNumber
    })
  }
  const newItemBank = {}
  newItemBank.id = banks.length + 1
  newItemBank.name = user.bankName
  newItemBank.bank_account_no = user.bankAccountNumber
  const mergeBank = [...banks, newItemBank]
  const handlerChangeSelected = id => {
    const option = bankOnline.find(item => item.value == id)
    setbankOption({
      ...bankOption,
      bank_name: option.title,
      bankCode: option.value
    })
  }

  return (
    <div className="content-withdraw">
      <div className="withdraw-left"></div>
      <div className="withdraw-right">
        <div className="withdraw-right-main" style={{ marginTop: 80 }}>
          <Form form={form} className="form-deposit" onFinish={handleSubmit}>
            <div className="input-withdraw">
              <CurrentBalance />
              <Form.Item
                name="bank_name"
                className="label-with-draw"
                label={t('BANK')}
              >
                <InputField disabled size="default" />
              </Form.Item>

              <Form.Item
                name="bank_account_no"
                className="label-with-draw"
                label={t('BANK_ACCOUNT_NO')}
              >
                <InputField disabled size="default" />
              </Form.Item>
              <Form.Item
                name="bank_account_name"
                className="label-with-draw"
                label={t('BANK_ACCOUNT_NAME')}
              >
                <InputField disabled size="default" />
              </Form.Item>
              <Form.Item
                name="amount"
                className="label-with-draw"
                label={t('WITHDRAW_AMOUNT')}
              >
                <InputField
                  type="number"
                  size="default"
                  placeholder="WithDraw Amount..."
                />
              </Form.Item>
            </div>

            {loading ? (
              <div
                className="btn-submit-withdraw"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 15
                }}
              >
                <PropagateLoader
                  sizeUnit="px"
                  size={20}
                  color="#f50057"
                  loading={loading}
                />
              </div>
            ) : (
              <div className="text-center formGroup_btn">
                <Button
                  style={{ color: '#fff' }}
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  {t('WITHDRAW_NOW')}
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default WithdrawNew
