import React from 'react'
import '../style.css'

const data = [
  {
    image: './images/home/howto1.png',
    text: 'Click Join Now. Fill in your login infor',
    class: 'theme-box1'
  },
  {
    image: './images/home/howto2.png',
    text: 'Make you first deposit using money o crypto transfer',
    class: 'theme-box2'
  },
  {
    image: './images/home/howto3.png',
    text: "Don't forget to claim your bonuses",
    class: 'theme-box3'
  },
  {
    image: './images/home/howto4.png',
    text: 'Start winning while playing on your favourile games',
    class: 'theme-box3'
  }
]
const Howto = () => {
  return (
    <div className="howto">
      <div style={{display: 'flex', flexDirection: 'column', margin: "15px 0"}}>
        <div className="howto-text">How to Register</div>
        <div className="howto-title">
          Welcome to
          <img
            style={{ width: '65px', paddingBottom: '5px' }}
            src="./images/home/logovipp.png"
            alt="logo"
          />
        </div>
      </div>
      <div style={{ overflow: 'auto', width: "100%" }}>
        <div className='howto-register'>
          <div className="howto-list-register">
            {data.map((i, index) => (
              <div className={i.class} key={index}>
                <img className='howto-style-imgcard' src={i.image} alt="a" />
                <p className="howto-card-text">{i.text}</p>
              </div>
            ))}
          </div>
          <img className='howto-styled-img'
            src="./images/home/lineregister.png"
            alt="lineregister"
          />
        </div>
      </div>
    </div>
  )
}

export default Howto
