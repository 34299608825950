import { notification } from 'antd';
import { ETypeNotification } from '../constants/gameData';
export const showNotification = (type, description) => {
    const options = {
      message: '',
      description,
      className: 'Notification',
    };
  
    switch (type) {
      case ETypeNotification.SUCCESS:
        notification.success(options);
        break;
      case ETypeNotification.WARNING:
        notification.warning(options);
        break;
      case ETypeNotification.ERROR:
        notification.error(options);
        break;
      case ETypeNotification.INFO:
        notification.info(options);
        break;
      default:
        notification.open(options);
    }
  };