export default [
    {
    label: 'NagaID',
    code: 'NagaID',
    src: './images/home/lottovip.png',
    classshow: 'pages-icon-coming',
    route: '/nagaid',
    idrActive: 0,
    id: 32
  },
  {
    label: 'KissVip Lotto',
    code: '97lotto',
    src: './images/home/lottokissvip.png',
    classshow: 'pages-icon-hot',
    route: '/97lotto',
    idrActive: 0,
    id: 12
  },
]
