/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { useForm } from 'react-final-form-hooks'
import http from 'service/http'
import CurrentBalance from 'component/CurrentBalance'
import { useTranslation } from 'react-i18next'
import DepositTab from './depositTab'

const correctGameProps = ({ name, id, bank_account_name: bankAccountName, bank_account_no: bankAccountNo }) => ({
  title: name, value: {
    name, id, bankAccountName, bankAccountNo
  }
})

function Deposit({ history }) {
  const { t } = useTranslation()
  const [banks, updateBanks] = React.useState([])

  const fetchData = async () => {
    try {
      const [gamesResp] = await Promise.all([
        http.get({ path: 'banks' }),
        // http.get({ path: 'me' })
      ])
      updateBanks(gamesResp.filter(x => x.type === 'Deposit').map(correctGameProps))
    } catch (error) {
      throw error
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content">
      <div className="title_recoment tile_deposit">{t('DEPOSIT')}</div>
      <div className="depositW display_flex">
        <div className="deposit-left" style={{marginTop: '20px'}}>
          <h3 className="rs h3_deposit">{t('CURRENT_BALANCE')}</h3>
          <CurrentBalance />
        </div>
        <div className="deposit-right">
          <DepositTab
            history={history}
            banks={banks}
          />
        </div>
      </div>
    </div>
  );
}

export default Deposit
