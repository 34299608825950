/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from 'react'
import {
  CssBaseline,
  GridList,
  GridListTile,
  ListSubheader,
  GridListTileBar
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import { withStyles } from '@material-ui/core/styles'
import http from 'service/http'
import component from 'component'
import store from 'store'
import styles from './style'


const { useDialog, Header, useLoading } = component

function Login({ classes, history }) {
  const formJoker = useRef(null)
  const [loading, withLoading] = useLoading(false)
  const [games, updateGames] = useState([])
  const [jokerUrl, updateJokerUrl] = useState(null)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Joker',
    btnLabel: 'Got it',
    type: 'error'
  })
  const checkWith = () => {
    let width = 2
    const e = document.getElementById('root')
    if(e.clientWidth > 576) width = 3
    if(e.clientWidth > 720) width = 4
    if(e.clientWidth > 960) width = 5
    if(e.clientWidth > 1140) width = 6
    return width
  }
  const initialPT = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 8)) {
        const { ListGames } = await withLoading(() => http.post({
          path: `users/${userId}/game/logout`, payload: {
            gameLoginId: '8'
          }
        })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message }
            }
            return true
          })
          .then(() => http.get({ path: `games/8/gamelist` })).then(rs => {
            return {
              ListGames: rs.ListGames.filter(x => x.GameType.includes('Fishing'))
            }
          }))
        return updateGames(ListGames)
      }
      return showDialogErrorWithMessage('Joker is Maintenance ')
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const loginJoker = async GameCode => {
    const url = await http.get({ json: false, path: `games/joker/login`, params: { GameCode } })
    updateJokerUrl(url)
  }

  useEffect(() => {
    if (jokerUrl) {
      formJoker.current.submit()
      updateJokerUrl()
    }
  }, [jokerUrl])

  useEffect(() => {
    initialPT()
  }, [])

  return (
    <div className="col8" style={{height: "calc(100vh - 130px)", overflow: 'auto'}}>
      {/* <Header history={history} title='Joker Login' /> */}
      <div className="title_recoment tile_deposit">Joker</div>
      <CssBaseline />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : (
        <GridList cellHeight={180} cols={checkWith()} className={classes.gridList}>
          {/*<GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>*/}
          {/*<ListSubheader component="div" />*/}
          {/*</GridListTile>*/}
          {games.map(({ GameCode, Image2, GameName }) => (
            <GridListTile style={{ height: 'auto' }} onClick={() => loginJoker(GameCode)} key={GameCode}>
              <img style={{width: '100%'}} src={Image2} alt={GameName} />
              {/*<GridListTileBar*/}
              {/*title={GameName}*/}
              {/*/>*/}
            </GridListTile>
          ))}
        </GridList>
      )}
      <ErrorDialog />
      <form ref={formJoker} action={jokerUrl} target='_blank' method='POST' style={{ display: 'none' }} />
    </div>
  )
}

export default withStyles(styles)(Login)
