import React from 'react'
import { useTranslation } from 'react-i18next'
import './Styles.css'

const listLinks = {
  games: [
    {
      title: 'Sports',
      href: '/sports'
    },
    {
      title: 'E - Sports',
      href: '#'
    },
    {
      title: 'Casino',
      href: '/casino'
    },
    {
      title: 'Slots',
      href: '/slot'
    },
    {
      title: 'Fishing',
      href: '/fishing'
    },
    {
      title: 'Lottery',
      href: '/lottery'
    }
  ],
  info: [
    {
      title: 'About Us',
      href: '#'
    },
    {
      title: 'VIP',
      href: '#'
    },
    {
      title: 'Leaderboard',
      href: '#'
    },
    {
      title: 'Affiliate Program',
      href: '#'
    },
    {
      title: 'Contract Us',
      href: '#'
    },
    {
      title: 'Responsible Gaming',
      href: '#'
    },
    {
      title: 'Info Center',
      href: '#'
    },
    {
      title: 'Terms of Conditions',
      href: '#'
    }
  ]
}

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="footer-group">
        <hr style={{ margin: '10px 0' }} />
        <div className="footer-top ">
          <div className="footer-top-left">
            <div>
              <p className="footer-title">About BK8 Sports</p>
              <p className="footer-title">
                Gaming License
                <img
                  style={{
                    width: '15px',
                    height: '15px',
                    margin: '0 10px 3px'
                  }}
                  src="./images/footer/badgecheck.png"
                  alt="check"
                />
              </p>
              <img
                style={{ width: '150px' }}
                src="./images/footer/gamingcuracao.png"
                alt="logogaming"
              />
              <p className="footer-top-text">
                Regulated & Licensed by the Government of Curacao and operates
                under the Mater License of Gaming Services Provider. N.V.
                #365/JAZ
              </p>
            </div>
            <div className="footer-style-link">
              <div>
                <p className="footer-title">Game</p>
                {listLinks.games.map((i, index) => (
                  <div style={{ margin: '10px 0' }} key={index}>
                    <a className="footer-href" href={i.href}>
                      {i.title}
                    </a>
                  </div>
                ))}
              </div>
              <div>
                <p className="footer-title">Info</p>
                {listLinks.info.map((ii, index) => (
                  <div style={{ margin: '10px 0' }} key={index}>
                    <a className="footer-href" href={ii.href}>
                      {ii.title}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="footer-top-right">
            <div className="styled-account-header">
              <img
                style={{ width: '30px', height: '30px', marginBottom: '10px' }}
                src="./images/home/EN.png"
                alt="en"
              />
            </div>
            <div>
              <p className="">Certificotion</p>
              <div className="footer-center-top-icon">
                <img
                  style={{ width: '30px', height: '30px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                />
                {/* <img
                  style={{ width: '30px', height: '30px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                />
                <img
                  style={{ width: '30px', height: '30px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <hr style={{ margin: '10px 0' }} />
        <div className="footer-center">
          <div className="footer-href">
            <div>
              <p className="">Payment methods</p>
              <div className="footer-center-top-icon">
                <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/bigcoin.svg"
                  alt="icon"
                />
                <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/usdt.svg"
                  alt="icon"
                />
                <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/ethereum.svg"
                  alt="icon"
                />
                {/* <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                /> */}
                {/* <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                />
                <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                />
                <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                /> */}
                <img
                  style={{ width: '39px', height: '35px' }}
                  src="./images/footer/visa.svg"
                  alt="icon"
                />
                <img
                  style={{ width: '53px', height: '35px' }}
                  src="./images/footer/mastercard.svg"
                  alt="icon"
                />
                {/* <img
                  style={{ width: '35px', height: '35px' }}
                  src="./images/footer/tiktok.svg"
                  alt="icon"
                /> */}
              </div>
            </div>
            <div className="footer-center-right">
              <div className="footer-style-group">
                <div>
                  <p className="footer-title">Follow Us</p>
                  <div className="footer-center-top-icon">
                    <img
                      style={{ width: '35px', height: '35px' }}
                      src="./images/footer/youtube.svg"
                      alt="icon"
                    />
                    <img
                      style={{ width: '35px', height: '35px' }}
                      src="./images/footer/instagram.svg"
                      alt="icon"
                    />
                    <img
                      style={{ width: '35px', height: '35px' }}
                      src="./images/footer/facebook.svg"
                      alt="icon"
                    />
                    <img
                      style={{ width: '35px', height: '35px' }}
                      src="./images/footer/twitter.svg"
                      alt="icon"
                    />
                  </div>
                </div>
                <div>
                  <p className="footer-title">Security</p>
                  <div className="footer-security">
                    <img
                      style={{ width: '30px', height: '30px' }}
                      src="./images/footer/tiktok.svg"
                      alt="icon"
                    />
                    {/* <img
                      style={{ width: '30px', height: '30px' }}
                      src="./images/footer/tiktok.svg"
                      alt="icon"
                    /> */}
                  </div>
                </div>
              </div>
              <div>
                <p className="footer-title">Responsible Gaming</p>
                <div className="footer-center-top-icon">
                  <img
                    style={{ width: '35px', height: '35px' }}
                    src="./images/footer/18plus.svg"
                    alt="18+"
                  />
                  {/* <img
                    style={{ width: '35px', height: '35px' }}
                    src="./images/footer/tiktok.svg"
                    alt="icon"
                  /> */}
                  {/* <img
                    style={{ width: '35px', height: '35px' }}
                    src="./images/footer/tiktok.svg"
                    alt="icon"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="footer-center-title-bottom">
              Copyright @2023 BK8. All rights reserved
            </p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="style-footer-bottom">
          <p className="style-footer-bottom-p">
            BK8 | Trusted Online Casino in Asia | Available in Thailand,
            VietNam, Indonesia, Malaysia, Cambodia, Philippine, India, Korea
          </p>
          <p className="style-footer-bottom-p" style={{ margin: '0' }}>
            BK8 is a trusted online casino in Aisa, authorized and licensed to
            operate in multiple countries including Thailand, VietNam,
            Indonesia, Malaysia, Cambodia, Philippine, India and Korea. We are
            the biggest & most trusted online casino in the region, offering an
            easy-to-use interface, top-notch customer service, and
            round-the-dock availability of a wide range of games, including slot
            machines, table games, video poker game, and more.{' '}
            <a
              style={{
                textDecoration: 'underline',
                color: '#000',
                fontSize: '13px'
              }}
            >
              Read More
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
