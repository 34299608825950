const styles = theme => ({
  main: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px'
  },
  main1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  gridList: {
    width: '100%'
  },
  error: {
    color: theme.palette.secondary.main
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    fontWeight: 'bold'
  }
})

export default styles