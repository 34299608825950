import React from 'react'
import './Styles.css'

// const Testimonial = styled.div`
//   width: 100%;
//   height: auto;
//   border-radius: 12px;
//   display: flex;
//   flex-direction: column;
//   background: #f3f5f6;
//   margin: 25px 0;
//   .slideshow {
//     display: none;
//   }
//   @media (max-width: 760px) {
//     .showpc {
//       display: none;
//     }
//     .slideshow {
//       margin: 0 auto;
//       overflow: hidden;
//       max-width: 301px;
//       @media (max-width: 768px) {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//       }
//     }

//     .slideshowSlider {
//       white-space: nowrap;
//       display: flex;
//       gap: 10px;
//       transition: ease 2000ms;
//     }

//     .slideshowDots {
//       text-align: center;
//     }

//     .slideshowDot {
//       display: inline-block;
//       height: 10px;
//       width: 10px;
//       border-radius: 50%;

//       cursor: pointer;
//       margin: 15px 5px 0px;

//       background-color: #c4c4c4;
//     }
//   }
//   @media (max-width: 575px) {
//     background: none;
//   }
// `
// const Title = styled.p`
//   font-size: 24px;
//   line-height: 32px;
//   font-weight: 600;
//   color: #000;
//   padding: 30px;
//   margin: 0;
//   @media (max-width: 575px) {
//     padding: 20px 20px 0px;
//   }
// `
// const TestimoniaList = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   padding: 0 30px 30px;
//   @media (max-width: 1224px) {
//     justify-content: space-evenly;
//     padding: 0 10px 30px;
//   }
// `
// const TestimonialItem = styled.div`
//   max-width: 400px;
//   width: 400px;
//   height: 250px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   padding: 0 5px;
//   @media (max-width: 1224px) {
//     width: 300px;
//   }
//   @media (max-width: 1002px) {
//     width: 100%;
//   }
//   @media (max-width: 760px) {
//     border: 1px solid #ccc;
//     border-radius: 24px;
//     display: inline-block;
//     width: 299px;
//     height: 330px;
//     padding: 30px 20px;
//     background-color: #fff;
//   }
// `
// const TitleItem = styled.p`
//   font-size: 32px;
//   line-height: 36px;
//   font-weight: 600;
//   padding: 0;
//   margin: 0;
//   color: #000;
//   @media (max-width: 1003px) {
//     font-size: 24px;
//     line-height: 32px;
//   }
// `
// const TestimonialDetail = styled.p`
//   font-size: 14px;
//   line-height: 18px;
//   font-weight: 400;
//   color: #B2BDC3;
//   margin: 0;
// `
// const StyledStar = styled.img`
//   width: 15px;
//   height: 15px;
// `
// const TestimonialStar = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 5px;
// `
// const TestimonialLine = styled.div`
//   border: 1px solid #B2BDC3;
//   margin: 5px;
// `
const data = [
  {
    title1: 'Safe and reputable platforms that you can trust!',
    title2: '',
    imageTitle: '',
    detail1:
      "I hesitate to make a deposit or share my information on other platforms because I don't feel safe. However,",
    detail2:
      'gave me the confidence as they have a verified domain and security measures in place. I always feel safe playing on',
    imgdetail: './images/home/logovipp.png',
    star: 5
  },
  {
    title1: 'Happy to switch to',
    title2: 'Awesome!',
    imageTitle: './images/home/logovipp.png',
    detail1: 'I am really happy to switch to',
    detail2:
      "High payouts and faantastic bonuses. You'd have to try it to believe it!",
    imgdetail: './images/home/logovipp.png',
    star: 5
  },
  {
    title1: 'Feel like a real VIP with them!',
    title2: '',
    imageTitle: '',
    detail1:
      'They are going above and beyond to provide exceptional services for their VIPs, width exclsive rewards and perks from time to time.',
    detail2: 'gained a loyal member like me now!',
    imgdetail: './images/home/logovipp.png',
    star: 5
  }
]
const data2 = [
  {
    title1: 'Safe and reputable',
    title2: 'platforms that you',
    title3: 'can trust!',
    imageTitle: '',
    detail1: 'I hesitate to make a deposit',
    detail2: 'or share my information on other',
    detail3: "platforms because I don't ",
    detail4: 'feel safe. However, ',
    detail5: 'gave me the confidence as they have a',
    detail5: ' verified domain and security measures in',
    detail6: 'place. I always feel safe playing on',
    imgdetail1: './images/home/logovipp.png',
    imgdetail2: '',
    imgdetail3: '',
    star: 5
  },
  {
    title1: 'Happy to switch',
    title2: 'to',
    title3: 'Awesome!',
    imageTitle: './images/home/logovipp.png',
    detail1: 'I am really happy to switch to',
    detail2: 'High payouts and faantastic bonuses.',
    detail3: "You'd have to try it to believe it!",
    detail4: '',
    detail5: '',
    detail6: '',
    imgdetail1: '',
    imgdetail2: './images/home/logovipp.png',
    imgdetail3: '',
    star: 5
  },
  {
    title1: 'Feel like a',
    title2: 'real VIP with them!',
    title3: '',
    imageTitle: '',
    detail1: 'They are going above and beyond',
    detail2: 'to provide exceptional services',
    detail3: 'for their VIPs, width exclsive rewards ',
    detail4: 'and perks from time to time.',
    detail5: 'gained a loyal member like me now!',
    detail6: '',
    imgdetail1: '',
    imgdetail2: '',
    imgdetail3: './images/home/logovipp.png',
    star: 5
  }
]

const Testimonials = () => {
  const delay = 5000
  const [index, setIndex] = React.useState(0)
  const timeoutRef = React.useRef(null)

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  React.useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex(prevIndex =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    )

    return () => {
      resetTimeout()
    }
  }, [index])
  return (
    <div className="testimonial-wrapper">
      <p className="testimonial-title">Testimonials</p>
      <div className="testimonial-list">
        <div className="testimonial-line" />
        {data.map((i, index) => (
          <>
            <div className="testimonial-item" key={index}>
              <p className="testimonial-title-item">
                {i.title1}
                {i.imageTitle && (
                  <img
                    style={{ width: '52px', margin: '0 5px' }}
                    src={i.imageTitle}
                    alt="logovipp"
                  />
                )}
                {i.title2}
              </p>
              <p className="testimonial-detail-item">
                {i.detail1}
                <img
                  style={{ width: '30px', margin: '0 5px' }}
                  src={i.imgdetail}
                  alt="logovipp"
                />
                {i.detail2}
              </p>
              <div className="testimonial-star">
                <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
              </div>
            </div>
            <div className="testimonial-line" />
          </>
        ))}
      </div>
      <div className="slideshow" style={{ width: '100%', height: '400px' }}>
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {data2.map((i, index) => (
            <div className="testimonial-item">
              <div className="slide" key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '20px'
                  }}
                >
                  <p className="testimonial-title-item">{i.title1}</p>
                  <p className="testimonial-title-item">
                    {i.imageTitle && (
                      <img
                        style={{ width: '52px', margin: '0 5px' }}
                        src={i.imageTitle}
                        alt="logovipp"
                      />
                    )}
                    {i.title2}
                  </p>
                  <p className="testimonial-title-item">{i.title3}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '20px'
                  }}
                >
                  <p className="testimonial-detail-item">
                    {i.detail1}
                    {i.imgdetail2 && (
                      <img
                        style={{ width: '30px', margin: '0 5px' }}
                        src={i.imgdetail2}
                        alt="logovipp"
                      />
                    )}{' '}
                  </p>
                  <p className="testimonial-detail-item">{i.detail2}</p>
                  <p className="testimonial-detail-item">{i.detail3}</p>
                  <p className="testimonial-detail-item">
                    {i.detail4}
                    {i.imgdetail1 && (
                      <img
                        style={{ width: '30px', margin: '0 5px' }}
                        src={i.imgdetail1}
                        alt="logovipp"
                      />
                    )}
                  </p>
                  <p className="testimonial-detail-item">
                    {i.imgdetail3 && (
                      <img
                        style={{ width: '30px', margin: '0 5px' }}
                        src={i.imgdetail3}
                        alt="logovipp"
                      />
                    )}
                    {i.detail5}
                  </p>
                  <p className="testimonial-detail-item">{i.detail6}</p>
                </div>
                <div className="testimonial-star">
                  <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                  <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                  <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                  <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                  <img className='testimonial-item-star' src="./images/home/star.png" alt="star" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="slideshowDots">
          {data2.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? ' active' : ''}`}
              onClick={() => {
                setIndex(idx)
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Testimonials
