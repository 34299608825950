import React from 'react'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { useTranslation } from 'react-i18next'
import LoginDialog from '../../component/layout/Login/index'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'

function Login({ history, callback }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Login Error',
    btnLabel: 'Got it',
    type: 'error'
  })

  const onSubmit = async payload => {
    try {
      const { id_token: token, user } = await withLoading(() =>
        http.post({ path: 'authenticate', payload })
      )
      http.setJwtToken(token)
      store.set('token', token)
      store.set('user', user)
      history.push('home')
      if (callback) {
        callback()
      }
    } catch (error) {
      showDialogErrorWithMessage(t('INCORRECT_USERNAME_PASSWORD'))
    }
  }

  const schema = Joi.object().keys({
    username: Joi.number()
      .required(),
    password: Joi.string()
      .regex(/^[a-zA-Z0-9]{3,30}$/)
      .required(),
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [username, password] = formCreateInputs(['username', 'password'], form)
  return (
      <LoginDialog
        tabActive="login"
        onClose={ history.goBack }
      />
  );
}

export default Login
