import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import grey from '@material-ui/core/colors/grey'
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import http from 'service/http'
import store from 'store'
import queryString from 'query-string'
import AppStore from 'component/appStore'
import { withRouter } from 'react-router'
import StyledButton from '../../button/syledButton'

import useLoading from '../../../feature/loading/hook'
import useDialog from '../../../feature/dialog/hook'
// import socket from '../../../service/socket'
import banks from '../../../constants/banks'
import config from '../../../config'
import '../Styles.css'
import { PropagateLoader } from 'react-spinners'

const data = [
  {
    image: './images/home/howto2.png',
    text: 'Make you first deposit using money or crypto transfer',
    class: 'theme-box',
    background: '#edfcf4'
  },
  {
    image: './images/home/howto3.png',
    text: "Don't forget to claim your bonuses",
    class: 'theme-box',
    background: '#fbf4e6'
  },
  {
    image: './images/home/howto4.png',
    text: 'Start winning while playing on your favourile games',
    class: 'theme-box',
    background: '#fbf4e6'
  }
]

const styles = {
  paperWidthSm: {
    width: '100%',
    // height: '550px',
    maxHeight: 'unset !important',
    margin: '0 !important',
    height: '100%',
    maxWidth: 'none',
    display: 'flex',
    justifyContent: 'center',
    background: 'transparent',
    boxShadow: 'none',
    flexDirection: 'row'
  },
  root: {
    width: '100%',
    marginTop: '20px'
  },
  container: {
    background: 'rgba(35, 35, 42, .7)'
  },
  focused: {
    color: 'black'
  }
}

const theme = createMuiTheme({
  palette: {
    primary: grey
  },
  typography: { useNextVariants: true }
})

const LoginDialog = ({
  history,
  classes,
  tabActive = 'login',
  onClose = () => {}
}) => {
  store.set('token', null)
  store.set('user', null)
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [loadingbtn, setLoading] = useState(false)
  const [_tabActive, updateTabActive] = useState(tabActive)
  const [loginBanner, updateLoginBanner] = useState(
    '/images/login/welcome-ID.png'
  )
  const [registerBanner, updateRegisterBanner] = useState(
    '/images/register/register-ID.png'
  )
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t('error'),
    btnLabel: t('GOT_IT'),
    type: 'error'
  })

  const [loginInfo, updateLoginInfo] = useState({
    username: '',
    password: ''
  })

  const [registerInfo, updateRegisterInfo] = useState({
    username: '',
    password: '',
    reTypePassword: ''
  })

  const onLogin = async () => {
    if (!loginInfo.username || !loginInfo.password) {
      showDialogErrorWithMessage('Please complete all information')
    } else {
      try {
        setLoading(true)
        const { id_token: token, user } = await withLoading(() =>
          http.post({ path: 'authenticate', payload: loginInfo })
        )

        http.setJwtToken(token)
        store.set('token', token)
        const me = await http.get({ path: 'me' })
        store.set('user', me)
        // socket.emit('login', {
        //   username: user.user_account_no
        // })
        history.push('home')
        setLoading(false)
      } catch (error) {
        setLoading(false)
        showDialogErrorWithMessage(t('INCORRECT_USERNAME_PASSWORD'))
      }
    }
  }

  const onRegister = async () => {
    if (
      !registerInfo.username ||
      !registerInfo.password ||
      !registerInfo.reTypePassword
    ) {
      showDialogErrorWithMessage('Please complete all information')
    } else if (registerInfo.reTypePassword !== registerInfo.password) {
      showDialogErrorWithMessage('Passwords do not match')
    } else if (registerInfo.bankName === '') {
      showDialogErrorWithMessage('Please select bank')
    } else {
      try {
        setLoading(true)
        const {
          location: { search }
        } = history
        const { parent, user_level: userLevel } = queryString.parse(search)
        const payload = {
          fullname: registerInfo.fullname,
          bankName: registerInfo.bankName,
          bankAccount: registerInfo.bankAccount,
          bankAccountNumber: registerInfo.bankAccountNumber,
          password: registerInfo.password,
          username: registerInfo.username,
          mobile_phone: registerInfo.username,
          user_account_no: registerInfo.username,
          parent_id: parent || config.agent,
          user_level: userLevel || 'PLAYER'
        }
        const { id_token: token, user } = await withLoading(() =>
          http.post({ path: 'users', payload })
        )

        http.setJwtToken(token)
        store.set('token', token)
        store.set('user', user)
        history.push('home')
        setLoading(false)
        // if (payload.user_level === 'PLAYER') {
        //   return history.push('home')
        // }
        // window.location = config.adminUrl
      } catch (error) {
        setLoading(false)
        showDialogErrorWithMessage(error.message)
      }
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (_tabActive === 'login') {
      onLogin()
    } else {
      onRegister()
    }
  }
  useEffect(() => {
    const lang = store.get('lang') || config.lang
    if (lang == 'idn') {
      updateLoginBanner('/images/login/welcome-ID.png')
      updateRegisterBanner('/images/register/register-ID.png')
    }
    if (lang == 'en') {
      updateLoginBanner('/images/login/welcome-EN.png')
      updateRegisterBanner('/images/register/register-EN.png')
    }
    if (lang == 'cn') {
      updateLoginBanner('/images/login/welcome-ZH.png')
      updateRegisterBanner('/images/register/register-ZH.png')
    }
    if (lang == 'ma') {
      updateLoginBanner('/images/login/welcome-BM.png')
      updateRegisterBanner('/images/register/register-BM.png')
    }
    if (lang == 'th') {
      updateLoginBanner('/images/login/welcome-TH.png')
      updateRegisterBanner('/images/register/register-TH.png')
    }
    // updateLoginBanner
  }, [])

  return (
    <>
      <div className="login-new-wrapper">
        <div className="login-new-content">
          {_tabActive === 'login' ? (
            <div className="login-new-content-left">
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: ' 30px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    <p className="login-new-title">Sign in</p>
                    <div>
                      <p className="login-new-styled-lable ">
                        Phone Number <span className="type-red">*</span>
                      </p>
                      <input
                        type="text"
                        className="login-new-styled-input"
                        // className="login-input"
                        // placeholder={t('ENTER_MOBILE')}
                        placeholder="Enter username"
                        value={loginInfo.username}
                        onChange={event => {
                          updateLoginInfo({
                            ...loginInfo,
                            username: event.target.value
                          })
                        }}
                      />
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Password <span className="type-red">*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        type="password"
                        // className="login-input"
                        // placeholder={t('ENTER_PASS')}
                        value={loginInfo.password}
                        onChange={event => {
                          updateLoginInfo({
                            ...loginInfo,
                            password: event.target.value
                          })
                        }}
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                  {loadingbtn ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 15
                      }}
                    >
                      <PropagateLoader
                        sizeUnit="px"
                        size={20}
                        color="#f50057"
                        loading={loadingbtn}
                      />
                    </div>
                  ) : (
                    <>
                      <StyledButton type="submit" background="#FF7E00">
                        {_tabActive === 'login' ? t('SIGN_IN') : t('SIGN_UP')}
                      </StyledButton>
                    </>
                  )}
                  <div className="login-new-not-register">
                    <p>Not register?</p>
                    <span
                      className="login-new-not-register-span"
                      onClick={() => history.push('/register')}
                    >
                      Create account
                    </span>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="login-new-content-left">
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    <p className="login-new-title">Creact Account</p>
                    <div>
                      <p className="login-new-styled-lable">
                        Full Name <span>*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        type="text"
                        placeholder="Enter display name"
                        // placeholder={t('ENTER_DISPLAY_NAME')}
                        value={registerInfo.fullname}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            fullname: event.target.value
                          })
                        }}
                      />
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Number phone <span>*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        type="text"
                        placeholder="Enter number phone"
                        // placeholder={t('ENTER_MOBILE')}
                        value={registerInfo.username}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            username: event.target.value
                          })
                        }}
                      />
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Password <span>*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        type="password"
                        placeholder="Enter password"
                        // placeholder={t('ENTER_PASS')}
                        value={registerInfo.password}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            password: event.target.value
                          })
                        }}
                      />
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Confirm Password <span>*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        type="password"
                        placeholder="Confirm Password"
                        // placeholder={t('RETYPE_PASS')}
                        value={registerInfo.reTypePassword}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            reTypePassword: event.target.value
                          })
                        }}
                      />
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Bank Name <span>*</span>
                      </p>
                      <select
                        className="login-new-style-select"
                        type="text"
                        placeholder="Enter Bank name"
                        // placeholder={t('BANK_NAME')}
                        value={registerInfo.bankName}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            bankName: event.target.value
                          })
                        }}
                      >
                        <option value="">Bank name</option>
                        {banks.map(b => {
                          return <option value={b.title}>{b.title}</option>
                        })}
                      </select>
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Bank account name <span>*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        type="text"
                        placeholder="Enter Bank Sccount Name"
                        // placeholder={t('BANK_ACCOUNT_NAME')}
                        value={registerInfo.bankAccount}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            bankAccount: event.target.value
                          })
                        }}
                      />
                    </div>
                    <div>
                      <p className="login-new-styled-lable">
                        Bank account no<span>*</span>
                      </p>
                      <input
                        className="login-new-styled-input"
                        placeholder="Enter Bank Account No"
                        type="text"
                        // placeholder={t('BANK_ACCOUNT_NO')}
                        value={registerInfo.bankAccountNumber}
                        onChange={event => {
                          updateRegisterInfo({
                            ...registerInfo,
                            bankAccountNumber: event.target.value
                          })
                        }}
                      />
                    </div>
                  </div>
                  {loadingbtn ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 15
                      }}
                    >
                      <PropagateLoader
                        sizeUnit="px"
                        size={20}
                        color="#f50057"
                        loading={loadingbtn}
                      />
                    </div>
                  ) : (
                    <>
                      <StyledButton type="submit" background="#FF7E00">
                        {_tabActive === 'login' ? t('SIGN_IN') : t('SIGN_UP')}
                      </StyledButton>
                    </>
                  )}
                  <div
                    className="login-new-not-register"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <p>
                      By clicking the Register buttom, I here by acknowledge
                      that I am above 18 years old and have read and accepted
                      your terms & conditions.
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px'
                      }}
                    >
                      <p>Already have an account?</p>
                      <span
                        className="login-new-not-register-span"
                        onClick={() => history.push('/login')}
                      >
                        Sign in
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          <div className="login-new-content-right">
            <img
              style={{ width: '100%' }}
              src="./images/home/minibanners1.png"
              alt="banner"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px 0'
              }}
            >
              <div>
                <div className="login-new-titlewc">
                  Welcome to
                  <img
                    style={{ width: '70px', marginBottom: '6px' }}
                    src="./images/home/logovipp.png"
                    alt="logo"
                  />
                </div>
                <div className="login-new-titlewc">
                  <p className="login-new-titlewc-p">
                    the Biggest and Most Trusted Platform
                  </p>
                </div>
              </div>
              <div style={{ overflow: 'auto', width: '100%' }}>
                <div className="login-new-styled-bonus">
                  {data.map((i, index) => (
                    <div
                      className={i.class}
                      style={{ background: `${i.background}` }}
                      key={index}
                    >
                      <img
                        className="login-new-styled-bonus-img"
                        src={i.image}
                        alt="a"
                      />
                      <p className="login-new-styled-bonus-p">{i.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorDialog />
    </>
  )
}

export default withRouter(withStyles(styles)(LoginDialog))
