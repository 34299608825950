export default {
  LANGS: 'Languages',
  HOME: 'Home',
  SPORTS: 'Sportsbook',
  POKER: 'Poker',
  VIRTUAL_SPORTS: 'Virtual Sports',
  LIVE_CASINO: 'Live Casino',
  SLOTS: 'Slot',
  LOTTERY: 'Lottery',
  PROMOTIONS: 'Promotions',
  CONTACT_US: 'Contact Us',
  LIVE_CHAT: 'Live Chat',
  WECHAT: 'Wechat',
  BANKING: 'Banking',
  ABOUT_US: 'ABOUT US',
  RESPONSIBLE_GAMING: 'RESPONSIBLE GAMING',
  TERMS_OF_USE: 'TERMS OF USE',
  TRUSTED_PAYMENT: 'Trusted Payment',
  TRANSFER: 'Transfer',
  TOPUP: 'Topup',
  WITHDRAW: 'Withdraw',
  ACCOUNT: 'Account',
  HISTORY: 'History',
  HISTORIES: 'Histories',
  REPORTS: 'Reports',
  DOWNLOAD: 'Download',
  LOGOUT: 'Logout',
  EDIT_PROFILE: 'Edit Profile',
  CHANGE_PASSWORD: 'Change Password',

  // FORM AREA
  BANK_INFO: 'Bank Info',
  BANK_NAME: 'Please Enter Bank Name (full)',
  BANK_ACCOUNT_NAME: 'Bank Account Name',
  BANK_ACCOUNT_NO: 'Bank Account No',
  WITHDRAW_AMOUNT: 'Withdraw Amount',
  WITHDRAW_NOW: 'WITHDRAW NOW',
  SELECT_BANK: 'Select bank',
  TOPUP_AMOUNT: 'Please enter amount',
  AGREEMENT: 'I agree to terms and conditions',
  ONLINE_TOP_UP_NOW: 'ONLINE TOP UP NOW',
  DEPOSIT_WITH_RECEIPT: 'DEPOSIT WITH RECEIPT',
  DEPOSIT_AMOUNT: 'Please Enter Deposit Amount',
  DEPSOSIT_BONUS: 'Claim deposit bonus',
  TRANSFER_IN: 'Transfer In',
  TRANSFER_OUT: 'Transfer Out',
  TYPE_TRANS: 'Select Type Transaction',
  SUBMIT: 'SUBMIT NOW',

  LOADING: 'loading',
  FROM: 'From Date',
  TO: 'To Date',
  TRANS_SUC: 'Transfer Successful',
  TRANS_ERR: 'Transfer Error',
  TRANS_DONE: 'Transfer is successfully finished',
  GOT_IT: 'Got it',
  SELECT_GAME: 'Select Game',
  CLAIM_LOYALTY_BONUS: 'Claim loyalty bonus',
  DEPO_SUC: 'Deposit Successful',
  DEPO_ERR: 'Deposit Error',
  DEPO_DONE: 'Your request submitted successfully',
  DEPOSIT: 'Deposit',
  FILE: 'file',
  WITH_SUC: 'Withdraw Successful',
  WITH_ERR: 'Withdraw Error',
  WITH_DONE: 'Your request submitted successfully',
  CLICK_DOWNLOAD: 'Click to Download',
  ENTER_MOBILE: 'Enter Mobile No',
  ENTER_PASS: 'Enter Password',
  SIGN_IN: 'Sign in',
  SIGN_UP: 'Sign up',
  REGISTER: 'Register',
  ENTER_DISPLAY_NAME: 'Enter Your Display Name',
  ENTER_EMAIL: 'Enter Your Primary Email',
  ENTER_AGENT: 'Enter affiliate code',
  RETYPE_PASS: 'Retype Your Password',
  BACK_TO_LOGIN: 'BACK TO LOGIN',
  PULL_OUT: 'Reset Wallet',
  RESTORE: 'Restore',
  REFRESH: 'Refresh',

  AG_FISHING_KING: 'AG Fishing King',
  NOTICE_LABEL: 'Announcement',
  NOTICE: 'Welcome To Play97',

  MEMBER_REGISTER: 'MEMBER REGISTRATION',
  YOUR_ACCOUNT_CONTACT_DETAIL: 'YOUR ACCOUNT & CONTACT DETAIL',
  FULL_NAME: 'Full name',
  USERNAME: 'User name',
  USERNAME_CONDITION: 'Between 3 to 9 character only, alphanumeric (a-z, 0-9)',
  EMAIL: 'Email Address',
  EMAIL_CONDITION: 'Correct email address is required for Password retrieval',
  PASSWORD: 'Password',
  PASSWORD_CONDITION: 'Password is required at least 1 lowercase letter, 1 uppercase letter, 1 number and between 6 to 10 alphanumeric (A-Z, a-z, 0-9).',
  CONFIRM_PASSWORD: 'Confirm Password',
  CURRENCE: 'Currence',
  PHONE: 'Phone Number',
  PHONE_CONDITION: 'Please fill in the correct phone number ( numeric only format) , in order to make you easier when need to reset password',
  BANK: 'Bank Name',
  BANK_NUMBER: 'Bank Account No',
  BANK_NUMBER_CONDITION: 'Please fill in the correct bank account number, in order to be easier when need to do withdrawal',
  BANK_ACCOUNT_NAME_CONDITION: 'Name listed in bank book and character only',
  TERM_18: '*I am over 18 years of age and have read and accepted Terms & Conditions, Privacy Policy & Betting Rules as published on this site.',
  LOGIN: 'Login',
  JOINUS: 'Join us',
  MEMBER_LOGIN: 'MEMBER LOG IN',
  FORGOT_PASSWORD: 'Forgot password?',
  MOST_POPULAR_GAMES: 'Most popular games',
  MOST_POPULAR_GAMES_DESCRIPTION: 'High-end experience of true VIP!',
  VIDEO_GAME_BONUS_POOL: 'Newwin Lucky bonus',
  PLAY_NOW: 'Play Now',
  RECOMMEND_GAMES: 'Recommend games',
  WINNING_LIST_YESTERDAY: 'Winning list yesterday',
  NUMBER_OF_BEST_PLACED: 'Number of bets placed',
  AMOUNT_PAID_OUT: 'Amount paid out',
  MORE: 'More',
  CURRENT_BALANCE: 'Current Balance',
  PAYMENT_METHOD: 'Payment method',
  FROM_BANK: 'From Bank',
  AMOUNT: 'Amount',
  RELOAD: 'RELOAD',
  FROM_DATE: 'From Date',
  TO_DATE: 'To Date',
  START_THE_ROAD_TO_ADVANCEMENT: 'Start the road to advanced',
  ROAD_TO_ADVANCEMENT: 'Road to advancement',
  START_UPDATE_EVERY_MONDAY: 'Start update every Monday',
  MY_OFFER: 'My offer',
  RECENTLY_PLAYED: 'Recently played',
  MY_COLLECTION: 'My collection',

  MY_ACCOUNT: 'MY ACCOUNT',
  YOUR_WALLET: 'Your Wallet',
  MY_WALLET: 'My Wallet',
  BONUS: 'Bonus',
  QUICK_REGISTER: 'Quick account opening',
  LATEST_OFFERS: 'Latest Offers',
  GAME: 'Game',
  GAMES: 'Games',
  LIST: 'List',
  OTHER: 'Other',
  AFFILIATE_AGENT: 'Affiliate Agent',
  BET_THIS_MONTH: 'Bet this month',
  WASHABLE_CODE_AMOUNT_OF: 'Washable code amount of',
  TO_WASH_CODE: 'To wash code',
  PLEASE: 'Please',
  TO_VIEW: 'to view',
  NEWS: 'News',
  FORUM: 'Forum',
  COPYRIGHT: '© 2018 97 IPAY All rights reserved.',
  REWARDS: 'Rewards',
  BASE_PROFILE: 'BASE PROFILES',
  PROFILE: 'Profile',
  REFERAL_ID: 'Referal ID',
  EMAIL_ADDRESS: 'Email Address',
  MAIN_BALANCE: 'Main Wallet',
  JOIN_DATE: 'Join Date',
  LAST_EDIT: 'Last Edit',
  SUBMIT_LABEL: 'Submit',
  RANKING: 'Ranking',
  USER: 'User',
  DEPOSIT_TOTAL: 'Deposit Total',
  BET_AMOUNT: 'Bet Amount',
  WINNING_AMOUNT: 'Winning Amount',
  STATISTICS_WITHIN: 'Statistics within 7 days of',
  HIGH_EXPLOSION: 'high explosion',
  POPULAR: 'Popular',
  TOTAL_BET: 'Total Bet',
  WIN_LOSE: 'Win lose',
  SEVEN_DAY_STATISTICS: '7-day statistics',

  DEPOSIT_ASSISTANT: 'Deposit assistant',
  CALL: 'Call',
  DOWNLOAD_APP: 'Download App',

  PROMOTIONS_DESCRIPTION: 'PROMOTIONS',
  SLOT_DESCRIPTION: 'SLOT GAMES',
  LIVE_CASINO_DESCRIPTION: 'LIVE CASINO',
  LOTTERY_DESCRIPTION: 'LOTTERY',
  SPORTBOOK_DESCRIPTION: 'SPORTBOOK',
  FISHING: 'Fishing',
  ONLINE_BANKING: 'Online Banking',
  CREDIT_NOT_ENOUGH: 'Not enough credit, please contact customer service.',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect username or password',
  ERROR: 'error',
  TRANSACTION_HISTORY: 'Transaction history',
  BEFORE: 'Before',
  AFTER: 'After',
  RECEIVER: 'Receiver',
  TRANSFER_AMOUNT: 'Transfer amount',
  TRANSFER_NOW: 'Transfer now',
  TRANSFER_ERR: 'Transfer Error',
  TRANSFER_SUC: 'Transfer Successful',
  PIN: 'Pin',
}