/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import http from 'service/http'
// import socket from 'service/socket'
import numeral from 'numeral'
import moment from 'moment'
import store from 'store'
import config from 'config'
import Bottom from 'component/bottom'
import Header from 'component/header'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd';
import CompCommon from "feature/CompCommon"
import ChangePasswordNewMb from "feature/ChangePasswordNewMb"
import NewBankDetails from "feature/NewBankDetails"
import NewMyAcc from "feature/NewMyAcc"
import './style.css'

// socket.on('login', (data) => {
//   localStorage.clear();
//   alert('Your account just login from other place !')
//   window.location = 'login'
// })

function NewAccount({ history }) {
  const { t } = useTranslation()

  const [jackpot, setJackpot] = React.useState(0)

  const { user_id: userId, user_account_no, user_money, balanceGame } = store.get('user') || { user_id: undefined }
  const user = store.get('user')
  window.username = user_account_no
  const [balance, setBalance] = React.useState(0)
  const [isShowComp, setShowCompCommon] = useState(false)
  const [isShowChangePass, setChowChangePass] = useState(false)
  const [isShowBankDetail, setShowBankDetail] = useState(false)
  const [isShowAcc, setShowAcc] = useState(false)
  
  const onUpdateShow = (value, type) => {
    if (type === "pass") {
      setChowChangePass(value)
    }
    else if (type === "bank-detail") {
      setShowBankDetail(value)
    }
    else if (type === "my-acc") {
      setShowAcc(value)
    }
  }
  React.useEffect(() => {
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 1) {
      localStorage.loadgame = 0
    }

    // fetch('https://api3.newwin4d.com/api_getresult.php?date=' + moment().format('YYYY-MM-DD') + '&key=1688', {
    //   mode: "cors",
    // }).then(response => response.json()).then(async rs => {
    //   if (!rs.result) {
    //     const nrs = await fetch('https://api3.newwin4d.com/api_getresult.php?date=' + moment().subtract(1, 'd').format('YYYY-MM-DD') + '&key=1688', {
    //       mode: "cors",
    //     }).then(response => response.json())
    //     setJackpot(nrs.result.jackpot_pool)
    //   } else setJackpot(rs.result.jackpot_pool)
    // }).catch(async err => {
    //   console.log(err)
    // })
    // if (userId) {
    //   http.post({ path: `users/${userId}/sync-game` })
    //
    //
    // }
    const userBalance = store.get('userBalance')
    setBalance(userBalance)
    setTimeout(() => {
      const userBalance = store.get('userBalance')
      setBalance(userBalance)
    }, 5000)
    setTimeout(() => {
      const userBalance = store.get('userBalance')
      setBalance(userBalance)
    }, 10000)
  }, [])
  return (
    <div className="content-account" style={{ marginTop: 50 }}>
      {user && user.user_account_no && (
        <div
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginBottom: 10,
            color: 'hsl(42deg 100% 43%)'
          }}
        ></div>
      )}
      <div className="name-logo-account item-account">
        <img style={{ width: 50 }} src="images/icon/goldcs.png" alt="" />
        <label style={{ width: '50%' }}>
          {user && user.user_id ? user.user_account_no : ''}
        </label>
        <img style={{ width: 50 }} src="images/icon/goldcorrect.png" alt="" />
      </div>

      {user && user.user_id ? (
        <div className="value-wallet item-account">
          <Row>
            <Col span={12}>
              <label style={{ fontSize: 18 }}>Main Wallet</label>
            </Col>
            <Col span={12} style={{ display: 'flex', paddingRight: 20 }}>
              <span
                style={{
                  float: 'left',
                  lineHeight: '30px',
                  fontWeight: 'normal'
                }}
              >
                {config.currency || 'MYR'}
              </span>
              <label style={{ float: 'right', fontSize: 18 }}>{`${numeral(
                user.user_money
              ).format('0,00')}`}</label>
            </Col>
          </Row>
        </div>
      ) : (
        <button className="btn-login_mb" onClick={() => history.push('/login')}>
          <img src="images/icon/lock.png" alt="" />
          <span>LOGIN</span>
        </button>
      )}
      <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "flex-end"}} className="lst-inf-acc item-account">
        <div onClick={() => onUpdateShow(true, 'my-acc')}>
          <img
            style={{ width: '50px' }}
            src="images/home/userprofile.svg"
            alt=""
          />
          <p>My Info</p>
        </div>
        <div onClick={() => onUpdateShow(true, 'pass')}>
          <img style={{ width: '50px' }} src="images/home/lock.svg" alt="" />
          <p>Password</p>
        </div>
        <div onClick={() => onUpdateShow(true, 'bank-detail')}>
          <img style={{ width: '50px' }} src="images/home/bank.svg" alt="" />
          <p>Bank Details</p>
        </div>
      </div>

      <CompCommon
        title={'Change Password'}
        show={isShowChangePass}
        close={() => {
          setChowChangePass(false)
        }}
      >
        <ChangePasswordNewMb />
      </CompCommon>
      <CompCommon
        title={'Bank details'}
        show={isShowBankDetail}
        close={() => {
          setShowBankDetail(false)
        }}
      >
        <NewBankDetails />
      </CompCommon>
      <CompCommon
        title={'My Accounts'}
        show={isShowAcc}
        close={() => {
          setShowAcc(false)
        }}
      >
        <NewMyAcc />
      </CompCommon>
    </div>
  )
}

export default NewAccount
