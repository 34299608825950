export default {
  LANGS: 'Bahasa',
  HOME: 'HOME',
  SPORTS: 'Sportbook',
  POKER: 'Poker',
  VIRTUAL_SPORTS: 'Virtual Sports',
  LIVE_CASINO: 'Kasino',
  SLOTS: 'slot',
  LOTTERY: 'lotere',
  PROMOTIONS: 'Promosi',
  CONTACT_US: 'Hubungi kami',
  LIVE_CHAT: 'Obrolan',
  WECHAT: 'WeChat',
  BANKING: 'Bank',
  ABOUT_US: 'Tentang Kami',
  RESPONSIBLE_GAMING: 'Game yang bertanggung jawab',
  TERMS_OF_USE: 'Ketentuan penggunaan',
  TRUSTED_PAYMENT: 'Pembayaran tepercaya',
  TRANSFER: 'Transfer',
  TOPUP: 'Isi ulang',
  WITHDRAW: 'Tarik dana',
  ACCOUNT: 'Akun',
  HISTORIES: 'Histories',
  REPORTS: 'Reports',
  DOWNLOAD: 'Unduh',
  LOGOUT: 'Keluar',
  EDIT_PROFILE: 'Edit profil',
  CHANGE_PASSWORD: 'Ubah kata sandi',

  // FORM AREA
  BANK_NAME: 'Silakan masukkan nama bank (lengkap)',
  BANK_ACCOUNT_NAME: 'Silakan Masukkan Nama Akun',
  BANK_ACCOUNT_NO: 'Silakan masukkan nomor rekening bank Anda',
  WITHDRAW_AMOUNT: 'Silakan masukkan jumlah penarikan',
  WITHDRAW_NOW: 'Tarik jumlah sekarang',
  SELECT_BANK: 'Pilih bank',
  TOPUP_AMOUNT: 'Silakan masukkan jumlahnya',
  AGREEMENT: 'Saya setuju dengan syarat dan ketentuan',
  ONLINE_TOP_UP_NOW: 'Setor online sekarang',
  DEPOSIT_WITH_RECEIPT: 'Setor dengan tanda terima',
  DEPOSIT_AMOUNT: 'Silakan masukkan jumlah setoran',
  DEPSOSIT_BONUS: 'Bonus setoran klaim',
  TRANSFER_IN: 'Transfer masuk',
  TRANSFER_OUT: 'Transfer keluar',
  TYPE_TRANS: 'Pilih jenis transaksi',
  SUBMIT: 'Kirim Sekarang',

  LOADING: 'Memuat',
  FROM: 'Dari tanggal',
  TO: 'Sampai saat ini',
  TRANS_SUC: 'Transfer berhasil',
  TRANS_ERR: 'Kesalahan transmisi',
  TRANS_DONE: 'Transfer selesai dengan sukses',
  GOT_IT: 'Selesai',
  SELECT_GAME: 'Pilih satu gim',
  CLAIM_LOYALTY_BONUS: 'Klaim Bonus Loyalitas',
  DEPO_SUC: 'Setoran berhasil',
  DEPO_ERR: 'Kesalahan setoran',
  DEPO_DONE: 'Permintaan Anda telah berhasil dikirim',
  DEPOSIT: 'Setoran',
  FILE: 'File',
  WITH_SUC: 'Penarikan jumlah berhasil',
  WITH_ERR: 'Kesalahan ekstraksi',
  WITH_DONE: 'Permintaan Anda telah berhasil dikirim',
  CLICK_DOWNLOAD: 'Klik untuk mengunduh',
  ENTER_MOBILE: 'Masukkan nomor telepon',
  ENTER_PASS: 'Masukkan kata sandi',
  SIGN_IN: 'Masuk',
  REGISTER: 'Daftar',
  ENTER_DISPLAY_NAME: 'Masukkan nama tampilan Anda',
  ENTER_EMAIL: 'Masukkan email utama Anda',
  ENTER_AGENT: 'Masukkan email proxy Anda (opsional)',
  RETYPE_PASS: 'Masukkan kembali kata sandi',
  BACK_TO_LOGIN: 'Kembali untuk login',
  PULL_OUT: 'Transfer kredit ke dompet',
  RESTORE: 'Restore',
  
  AG_FISHING_KING: 'AG Fishing King',
  NOTICE_LABEL: 'Pengumuman',
  NOTICE: 'Bank Pertanian Tiongkok menerapkan sistem perbankan',

  MEMBER_REGISTER: 'Registrasi player',
  YOUR_ACCOUNT_CONTACT_DETAIL: 'Akun dan informasi kontak Anda',
  USERNAME: 'Nama pengguna',
  USERNAME_CONDITION: 'Hanya 3 hingga 9 karakter, alfanumerik (a-z, 0-9)',
  EMAIL: 'Alamat email',
  EMAIL_CONDITION: 'Pengambilan kata sandi membutuhkan alamat email yang benar',
  PASSWORD: 'Kata sandi',
  PASSWORD_CONDITION: 'Kata sandi memerlukan setidaknya 1 huruf kecil, 1 huruf besar, 1 angka, dan 6 hingga 10 karakter alfanumerik (A-Z, a-z, 0-9).',
  CONFIRM_PASSWORD: 'Konfirmasikan kata sandi',
  CURRENCE: 'Mata uang',
  PHONE: 'Nomor telepon',
  PHONE_CONDITION: 'Silakan isi nomor telepon yang benar (hanya format numerik) untuk mempermudah mengatur ulang kata sandi Anda',
  BANK: 'Nama bank',
  BANK_NUMBER: 'Rekening bank',
  BANK_NUMBER_CONDITION: 'Silakan isi nomor rekening bank yang benar untuk mempermudah ketika Anda perlu menarik',
  BANK_ACCOUNT_NAME_CONDITION: 'Daftar nama dan karakter hanya di buku bank',
  TERM_18: '*Saya berusia di atas 18 tahun dan telah membaca dan menerima syarat dan ketentuan, kebijakan privasi, dan aturan taruhan yang dipasang di situs web ini.',
  LOGIN: 'Masuk',
  MEMBER_LOGIN: 'Login Anggota',
  FORGOT_PASSWORD: 'Lupa kata sandi Anda?',
  MOST_POPULAR_GAMES: 'Game paling populer',
  MOST_POPULAR_GAMES_DESCRIPTION: 'VIP sejati!',
  VIDEO_GAME_BONUS_POOL: 'Newwin Lucky Bonus',
  PLAY_NOW: 'Main sekarang',
  RECOMMEND_GAMES: 'Game yang direkomendasikan',
  WINNING_LIST_YESTERDAY: 'Daftar pemenang kemarin',
  NUMBER_OF_BEST_PLACED: 'Jumlah taruhan',
  AMOUNT_PAID_OUT: 'Jumlah pembayaran',
  MORE: 'Lebih banyak',
  CURRENT_BALANCE: 'Saldo saat ini',
  PAYMENT_METHOD: 'Metode pembayaran',
  FROM_BANK: 'Dari bank',
  AMOUNT: 'Sama dengan',
  RELOAD: 'Muat ulang',
  FROM_DATE: 'Dari tanggal',
  TO_DATE: 'Sejauh ini',
  START_THE_ROAD_TO_ADVANCEMENT: 'Cara memulai',
  ROAD_TO_ADVANCEMENT: 'Jalan menuju kemajuan',
  START_UPDATE_EVERY_MONDAY: 'Perbarui setiap hari Senin',
  MY_OFFER: 'Penawaran saya',
  RECENTLY_PLAYED: 'Baru Diputar',
  MY_COLLECTION: 'Koleksi saya',

  MY_ACCOUNT: 'Akun saya',
  YOUR_WALLET: 'Dompet Anda',
  MY_WALLET: 'Dompet saya',
  BONUS: 'Bonus',
  QUICK_REGISTER: 'Pembukaan akun cepat',
  LATEST_OFFERS: 'Penawaran terbaru',
  GAME: 'Game',
  GAMES: 'Games',
  LIST: 'Direktori',
  OTHER: 'Lainnya',
  AFFILIATE_AGENT: 'Agen Anggota',
  BET_THIS_MONTH: 'Taruhan bulan ini',
  WASHABLE_CODE_AMOUNT_OF: 'Jumlah mencuci',
  TO_WASH_CODE: 'Kode pembersihan',
  PLEASE: 'Tolong',
  TO_VIEW: 'Silakan periksa',
  NEWS: 'Berita',
  FORUM: 'Forum',
  COPYRIGHT: '© 2018 97 IPAY All rights reserved.',
  REWARDS: 'Hadiah',
  BASE_PROFILE: 'Informasi dasar',
  PROFILE: 'Informasi',
  REFERAL_ID: 'ID pengarah',
  EMAIL_ADDRESS: 'Alamat email',
  MAIN_BALANCE: 'Keseimbangan utama',
  JOIN_DATE: 'Tanggal partisipasi',
  LAST_EDIT: 'Sunting terakhir',
  SUBMIT_LABEL: 'Kirim',
  RANKING: 'Peringkat',
  USER: 'Pengguna',
  DEPOSIT_TOTAL: 'Total setoran',
  BET_AMOUNT: 'Jumlah taruhan',
  WINNING_AMOUNT: 'Jumlah kemenangan',
  STATISTICS_WITHIN: 'Statistik dalam 7 hari',
  HIGH_EXPLOSION: 'Popularitas tinggi',
  POPULAR: 'Populer',
  TOTAL_BET: 'Taruhan total',
  WIN_LOSE: 'Menang atau kalah',
  SEVEN_DAY_STATISTICS: 'Statistik 7 hari',

  DEPOSIT_ASSISTANT: 'Bantuan setoran',
  CALL: 'Panggilan',
  DOWNLOAD_APP: 'Unduh Aplikasi',

  PROMOTIONS_DESCRIPTION: 'PROMOSI',
  SLOT_DESCRIPTION: 'Slot Game',
  LIVE_CASINO_DESCRIPTION: 'Live Casino',
  LOTTERY_DESCRIPTION: 'Lotre',
  SPORTBOOK_DESCRIPTION: 'Bola',
  FISHING: 'Penangkapan ikan',
  ONLINE_BANKING: 'Perbankan online',
  CREDIT_NOT_ENOUGH: 'tidak cukup kredit, silakan hubungi layanan pelanggan.',
  INCORRECT_USERNAME_PASSWORD: 'Username atau kata sandi salah',
  ERROR: 'Kesalahan'
}