import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import store from 'store'
import config from './config'
import en from './component/langs/en'
import th from './component/langs/th'
import ma from './component/langs/ma'
import idn from './component/langs/idn'
import cn from './component/langs/cn'
import mya from './component/langs/mynn'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  cn: {
    translation: cn
  },
  idn: {
    translation: idn
  },
  th: {
    translation: th
  },
  ma: {
    translation: ma
  },
  mya: {
    translation: mya
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: store.get('lang') || config.lang,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

  export default i18n