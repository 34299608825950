/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React from 'react'
import {
  CssBaseline,
  Grid,
  Paper
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import component from 'component'
import styles from './style'

const GAMES = [
  {
    id: '602801',
    name: 'Royal Baccarat'
  }, {
    id: '602802',
    name: 'Royal Roulette'
  }, {
    id: '602803',
    name: 'Royal Sic Bo'
  }, {
    id: '602804',
    name: 'Royal Blackjack'
  }, {
    id: '602805',
    name: 'Royal 5 Box Blackjack'
  }, {
    id: '602811',
    name: 'Dragon Bonus'
  }, {
    id: '604501',
    name: 'Money Roll'
  }
]
const { Header } = component

function Login({ classes, history }) {

  return (
    <div className={`col8 ${classes.main}`}>
      {/* <Header history={history} title='SBO Games'/> */}
      <CssBaseline />
      <Grid container spacing={3} style={{ marginTop: 53 }}>
        {GAMES.map(({ id }) => (
          <Grid item xs={12}>
            <Paper onClick={() => history.push(`/sbo?GameType=Games&GameId=${id}`)} className={classes.paper}>
              <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/img/sbo/${id}.jpg`} alt={id} />
            </Paper>
          </Grid>))}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Login)
