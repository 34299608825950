/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { PropagateLoader } from 'react-spinners'
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next'
import TextInput from 'component/textInput'
import store from 'store'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import './style.css'
import InputField from '../../component/textInput/InputField'
import { showNotification } from '../../utils/function'
import { ETypeNotification } from '../../constants/gameData'

function ChangePasswordNew({ classes, history }) {
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Change Password Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const { t } = useTranslation()
  const onSubmit = async ({ password }) => {
    try {
      const { user_id: userId } = store.get('user')
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: { password } })
      )
      
      showNotification(ETypeNotification.SUCCESS,'Your password change successfull !')
      form.resetFields();
    } catch (error) {
      showNotification(ETypeNotification.ERROR,error.message)
    }
  }

  const schema = Joi.object().keys({
    password: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/).required(),
    confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
  })

  // const { form, handleSubmit, submitting } = useForm({
  //   onSubmit,
  //   validate: validate(schema)
  // })
  const handleSubmit = (values) => {
    onSubmit({ ...values })
  }
  // const [password, confirmPassword] = formCreateInputs(['password', 'confirmPassword'], form)
  const [form] = Form.useForm();
  return (
    <div className={`content-change-pass ${classes.main}`}>
     <Form form={form} layout="vertical" className="form-deposit changepass" onFinish={handleSubmit}>
              <Form.Item rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
                name="password" className='custom-label-account' label=" Current Password" style={{ marginBottom: "10px" }}>
                <InputField type="password" size="default" placeholder="password" />
              </Form.Item>
              <Form.Item rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
                name="password" className='custom-label-account' label="New Password" style={{ marginBottom: "10px" }}>
                <InputField type="password" size="default" placeholder="password" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
                name="confirmPassword" className='custom-label-account' label="Confirm password">
                <InputField type="password" size="default" placeholder="Confirm password.." />
              </Form.Item>

              <div className="text-center formGroup_btn">
                <Button style={{width: '100%'}} type="primary"  loading={loading} htmlType='submit'>{t('SAVE')}</Button>
              </div>

            </Form>
      <ErrorDialog />
    </div>
  )
}

export default withStyles(styles)(ChangePasswordNew)
