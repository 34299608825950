export default {
  LANGS: 'ภาษา',
  HOME: 'หน้าหลัก',
  SPORTS: 'หนังสือกีฬา',
  POKER: 'โป๊กเกอร์',
  VIRTUAL_SPORTS: 'กีฬาเสมือนจริง',
  LIVE_CASINO: 'คาสิโนสด',
  SLOTS: 'สล็อต',
  LOTTERY: 'หวย',
  PROMOTIONS: 'โปรโมชั่น',
  CONTACT_US: 'ติดต่อเรา',
  LIVE_CHAT: 'สนทนาสด',
  WECHAT: 'วีแชท',
  BANKING: 'การธนาคาร',
  ABOUT_US: 'เกี่ยวกับเรา',
  RESPONSIBLE_GAMING: 'การเล่มเกมที่มีความรับผิดชอบ',
  TERMS_OF_USE: 'ข้อกำหนดการใช้งาน',
  TRUSTED_PAYMENT: 'การชำระเงินที่เชื่อถือได้',
  TRANSFER: 'โอน',
  TOPUP: 'เติมเงิน',
  WITHDRAW: 'ถอนเงิน',
  ACCOUNT: 'บัญชี',
  HISTORY: 'ประวัติ',
  HISTORIES: 'ประวัติ',
  REPORTS: 'รายงาน',
  DOWNLOAD: 'ดาวน์โหลด',
  LOGOUT: 'ออกจากระบบ',
  EDIT_PROFILE: 'แก้ไขโปรไฟล์',
  CHANGE_PASSWORD: 'เปลี่ยนรหัสผ่าน',

  // FORM AREA
  BANK_NAME: 'กรุณาใส่ชื่อธนาคาร (ชื่อเต็ม)',
  BANK_ACCOUNT_NAME: 'ชื่อเจ้าของบัญชีธนคาร',
  BANK_ACCOUNT_NO: 'เลขที่บัญชีธนาคาร',
  WITHDRAW_AMOUNT: 'จำนวนถอน',
  WITHDRAW_NOW: 'ถอนเดี๋ยวนี้',
  SELECT_BANK: 'เลือกธนาคาร',
  TOPUP_AMOUNT: 'กรุณาใส่จำนวน',
  AGREEMENT: 'ฉันยอมรับข้อตกลงและเงื่อนไข',
  ONLINE_TOP_UP_NOW: 'เติมเงินออนไลน์เด๋วนี้',
  DEPOSIT_WITH_RECEIPT: 'ฝากโดยใช้ใบเสร็จ',
  DEPOSIT_AMOUNT: 'กรุณาใส่จำนวนฝาก',
  DEPSOSIT_BONUS: 'รับโบนัสเงินฝาก',
  TRANSFER_IN: 'โอนเข้า',
  TRANSFER_OUT: 'โอนออก',
  TYPE_TRANS: 'เลือกประเภทธุรกรรม',
  SUBMIT: 'ส่งเดี๋ยวนี้',

  LOADING: 'กำลังโหลด',
  FROM: 'จากวันที่',
  TO: 'ถึงวันที่',
  TRANS_SUC: 'โอนสำเร็จ',
  TRANS_ERR: 'โอนผิดพลาด',
  TRANS_DONE: 'การโอนเสร็จเรียบร้อยแล้ว',
  GOT_IT: 'Got it',
  SELECT_GAME: 'เลือกเกม',
  CLAIM_LOYALTY_BONUS: 'รับโบนัสความซื่อสัตย์',
  DEPO_SUC: 'ฝากสำเร็จ',
  DEPO_ERR: 'ฝากผิดพลาด',
  DEPO_DONE: ' ส่งคำขอของคุณสำเร็จแล้ว',
  DEPOSIT: 'ฝาก',
  FILE: 'ไฟล์',
  WITH_SUC: 'ถอนสำเร็จ',
  WITH_ERR: 'ถอนผิดพลาด',
  WITH_DONE: 'ส่งคำขอของคุณสำเร็จแล้ว',
  CLICK_DOWNLOAD: 'คลิกเพื่อดาวน์โหลด',
  ENTER_MOBILE: 'กรุณาใส่เบอร์โทรศัพท์มือถือ',
  ENTER_PASS: 'กรุณาใส่รหัสผ่าน',
  SIGN_IN: 'เข้าสู่ระบบ',
  SIGN_UP: 'ลงชื่อ',
  REGISTER: 'ลงทะเบียน',
  ENTER_DISPLAY_NAME: 'กรุณาใส่ชื่อของคุณที่ต้องการให้แสดง',
  ENTER_EMAIL: 'กรุณาใส่อีเมลล์หลักของคุณ',
  ENTER_AGENT: 'กรุณาใส่รหัสบริษัท',
  RETYPE_PASS: 'ใส่รหัสอีกครั้ง',
  BACK_TO_LOGIN: 'กลับไปหน้าเข้าสู่ระบบ',
  PULL_OUT: 'รีเซ็ตกระเป๋าเงิน',
  RESTORE: 'คืนค่า',
  REFLASH: 'รีเฟรช',

  AG_FISHING_KING: 'AG Fishing King',
  NOTICE_LABEL: 'ประกาศ',
  NOTICE: 'ยินดีต้อนรับสู่ Play97',

  MEMBER_REGISTER: 'สมาชิกลงทะเบียน',
  YOUR_ACCOUNT_CONTACT_DETAIL: 'บัญชีของคุณและรายละเอียดการติดต่อ',
  FULL_NAME: 'ชื่อเต็ม',
  USERNAME: 'ชื่อผู้ใช้',
  USERNAME_CONDITION: 'ระหว่าง 3-9 ตัวอักษรเท่านั้น, ตัวหนังสือตัวเลข (a-z, 0-9)',
  EMAIL: 'อีเมลล์',
  EMAIL_CONDITION: 'จำเป็นต้องใส่อีเมลล์ที่ถูกต้องเพื่อความสะดวกในการกู้คืนรหัสผ่าน',
  PASSWORD: 'รหัสผ่าน',
  PASSWORD_CONDITION: 'รหัสผ่านจะต้องมีอักษรตัวเล็กอย่างน้อย 1 ตัว และตัวใหญ่ 1 ตัว ตัวเลข 1 ตัว และต้องมีจำนวนระหว่าง 6 ถึง 10 ตัวอักษรและตัวเลขรวมกัน (A-Z, a-z, 0-9).',
  CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
  CURRENCE: 'สกุลเงิน',
  PHONE: 'เบอร์โทรศัพท์',
  PHONE_CONDITION: 'กรุณาใส่เบอร์โทรศัพท์ที่ถูกต้อง (รูปแบบตัวเลขเท่านั้น) เพื่อความสะดวกในการรีเซ็ตรหัสผ่าน',
  BANK: 'ชื่อธนาคาร',
  BANK_NUMBER: 'เลขบัญชีธนาคาร',
  BANK_NUMBER_CONDITION: 'กรุณาใส่เลขบัญชีธนาคารที่ถูกต้องเพื่อความรวดเร็วและง่ายในการถอน',
  BANK_ACCOUNT_NAME_CONDITION: 'ชื่อที่แสดงในบัญชีธนาคารและเป็นตัวอักษรเท่านั้น',
  TERM_18: '*ฉันอายุมากกว่า 18 ปี และได้อ่านและยอมรับข้อกำหนดและเงื่อนไข นโยบายความเป็นส่วนตัว และ กฏการเดิมพันตามที่เผยแพร่บนเว็บไซต์นี้แล้ว',
  LOGIN: 'เข้าสู่ระบบ',
  JOINUS: 'เข้าร่วมกับเรา',
  MEMBER_LOGIN: 'สมาชิกเข้าสู่ระบบ',
  FORGOT_PASSWORD: 'ลืมรหัสผ่าน?',
  MOST_POPULAR_GAMES: 'เกมส์ที่ได้รับความนิยมที่สุด',
  MOST_POPULAR_GAMES_DESCRIPTION: 'ประสบการณ์ระดับไฮเอนด์ของวีไอพีที่แท้จริง!',
  VIDEO_GAME_BONUS_POOL: 'โบนัสนำโชคใหม่',
  PLAY_NOW: 'เล่นเดี๋ยวนี้',
  RECOMMEND_GAMES: 'เกมส์แนะนำ',
  WINNING_LIST_YESTERDAY: 'รายการชนะเมื่อวาน',
  NUMBER_OF_BEST_PLACED: 'จำนวนที่วางเดิมพัน',
  AMOUNT_PAID_OUT: 'จำนวนจ่ายออก',
  MORE: 'เพิ่มเติม',
  CURRENT_BALANCE: 'ยอดคงเหลือปัจจุบัน',
  PAYMENT_METHOD: 'วิธีการชำระเงิน',
  FROM_BANK: 'จากธนาคาร',
  AMOUNT: 'จำนวน',
  RELOAD: 'โหลดใหม่',
  FROM_DATE: 'จากวันที่',
  TO_DATE: 'ถึงวันที่',
  START_THE_ROAD_TO_ADVANCEMENT: 'เริ่มต้นเส้นทางสู่มืออาชีพ',
  ROAD_TO_ADVANCEMENT: 'เส้นทางสู่ความก้าวหน้า',
  START_UPDATE_EVERY_MONDAY: 'เริ่มอัพเดตทุกวันจันทร์',
  MY_OFFER: 'ข้อเสนอของฉัน',
  RECENTLY_PLAYED: 'เล่นล่าสุด',
  MY_COLLECTION: 'การสะสมของฉัน',

  MY_ACCOUNT: 'บัญชีของฉัน',
  YOUR_WALLET: 'กระเป๋าเงินของคุณ',
  MY_WALLET: 'กระเป๋าเงินของฉัน',
  BONUS: 'โบนัส',
  QUICK_REGISTER: 'เปิดบัญชีด่วน',
  LATEST_OFFERS: 'ข้อเสนอล่าสุด',
  GAME: 'เกม',
  GAMES: 'เกมส์',
  LIST: 'รายการ',
  OTHER: 'อื่นๆ',
  AFFILIATE_AGENT: 'เอเย่นต์บริษัท',
  BET_THIS_MONTH: 'การเดิมพันเดือนนี้',
  WASHABLE_CODE_AMOUNT_OF: 'จำนวนรหัสที่ล้างได้ของ',
  TO_WASH_CODE: 'เพื่อล้างรหัส',
  PLEASE: 'กรุณา',
  TO_VIEW: 'เพื่อดู',
  NEWS: 'ข่าว',
  FORUM: 'ฟอรัม',
  COPYRIGHT: 'สงวนลิขสิทธิ์โดย © 2018 97 IPAY.',
  REWARDS: 'รางวัล',
  BASE_PROFILE: 'โปรไฟล์พื้นฐาน',
  PROFILE: 'โปรไฟล์',
  REFERAL_ID: 'รหัสอ้างอิง',
  EMAIL_ADDRESS: 'อีเมลล์',
  MAIN_BALANCE: 'กระเป๋าเงินหลัก',
  JOIN_DATE: 'วันที่เข้าร่วม',
  LAST_EDIT: 'แก้ไขครั้งล่าสุด',
  SUBMIT_LABEL: 'ส่ง',
  RANKING: 'การจัดอันดับ',
  USER: 'ผู้ใช้',
  DEPOSIT_TOTAL: 'ยอดรวมเงินฝาก',
  BET_AMOUNT: 'จำนวนเดิมพัน',
  WINNING_AMOUNT: 'จำนวนที่ชนะ',
  STATISTICS_WITHIN: 'สถิติภายใน 7 วันของ',
  HIGH_EXPLOSION: 'การระเบิดครั้งใหญ่',
  POPULAR: 'ได้รับความนิยม',
  TOTAL_BET: 'ยอดเดิมพันทั้งหมด',
  WIN_LOSE: 'ชนะ แพ้',
  SEVEN_DAY_STATISTICS: 'สถิติ 7 วัน',

  DEPOSIT_ASSISTANT: 'ผู้ช่วยฝาก',
  CALL: 'โทร',
  DOWNLOAD_APP: 'ดาวน์โหลดแอพ',

  PROMOTIONS_DESCRIPTION: 'โปรโมชั่น',
  SLOT_DESCRIPTION: 'เกมส์สล็อต',
  LIVE_CASINO_DESCRIPTION: 'คาสิโนสด',
  LOTTERY_DESCRIPTION: 'หวย',
  SPORTBOOK_DESCRIPTION: 'หนังสือกีฬา',
  FISHING: 'ตกปลา',
  ONLINE_BANKING: 'ธนาคารออนไลน์',
  CREDIT_NOT_ENOUGH: 'เครดิตไม่เพียงพอ, กรุณาติดต่อฝ่ายบริการลูกค้า.',
  INCORRECT_USERNAME_PASSWORD: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
  ERROR: 'ผิดพลาด',
  TRANSACTION_HISTORY: 'ประวัติการทำธุรกรรม',
  BEFORE: 'ก่อน',
  AFTER: 'หลัง',
  RECEIVER: 'ผู้รับ',
  TRANSFER_AMOUNT: 'จำนวนโอน',
  TRANSFER_NOW: 'โอนเดี๋ยวนี้',
  TRANSFER_ERR: 'โอนผิดพลาด',
  TRANSFER_SUC: 'โอนสำเร็จ',
  PIN: 'รหัส',
}