export default [
  {
    label: 'Creative Gaming',
    code: 'CG',
    src: 'images/icon/CG.png',
    route: '/creative-gaming?GameType=GameLobby_CGLobby_Test',
    idrActive: 0,
    id: 30
  },
  {
    src: '/images/gameLogo/slot-pp.png',
    code: 'sexy_baccarat',
    label: 'PP',
    route: 'awc/PP/SLOT',
    idrActive: 0,
    id: 23
  },
  {
    src: '/images/gameLogo/slot-fachai.png',
    code: 'sexy_baccarat',
    label: 'Fachai',
    route: 'awc/FC/SLOT',
    idrActive: 0,
    id: 23
  },
  {
    label: 'King maker',
    code: 'sexy_baccarat',
    src: 'images/gameLogo/games-kingmaker.png',
    route: 'awc/KINGMAKER/TABLE',
    idrActive: 0,
    id: 23
  },
  {  src: '/images/gameLogo/slot-sg.png',
    code: 'sexy_baccarat',
    label: 'Spade Gaming',
    route: 'awc/SPADE/SLOT',
    id: 23,
  },
  {
    src: '/images/gameLogo/slot-pg-soft.png',
    code: 'sexy_baccarat',
    label: 'PGsoft',
    route: 'pg',
    idrActive: 0,
    id: 23
  },
  {
    //   src: '/img/playtech/img/gtswng.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/pop_shctz_ab_jp_skw.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/pop_sw_yxlb_skw.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/wlg.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/wlgjp.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/xufe.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/yclong.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/zcjb.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/zcjbjp.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/zctz.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //    src: '/img/playtech/img/zctZi_146x136.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   // redirect: true
    // }, {
    //   src: '/img/games/evo888.png',
    //   code: 'evo888',
    //   label: 'EVO888',
    //   route: '/evo888',
    //   id: 20
    // }, {
    //   src: '/images/gameLogo/xe88-app.png',
    //   code: 'xe88',
    //   label: 'Xe 88',
    //   route: '',
    //   idrActive: 0,
    //   id: 18,
    //   deeplink: true,
    //   html5: 0,
    // }, {
    //   src: '/images/gameLogo/918kiss-app.png',
    //   code: '918kiss',
    //   label: '918 Kiss',
    //   route: 'lobbykissandroid://lobbykissandroid?account=',
    //   idrActive: 0,
    //   id: 2,
    //   deeplink: true,
    // }, {
    //   src: '/images/gameLogo/mega888-app.png',
    //   code: 'mega',
    //   label: 'Mega888',
    //   route: '/',
    //   id: 11,
    //   deeplink: true
    // }, {
    //   src: '/images/gameLogo/play8oy-app.png',
    //   code: 'play8oy',
    //   label: 'Play8oy',
    //   deeplink: true,
    //   idrActive: 0,
    //   id: 3,

    // }, {
      src: '/images/gameLogo/slot-joker.png',
      code: 'joker',
      label: 'Joker',
      route: 'joker',
      id: 8,
      html5: 0,
      deeplink: true,

    }, {
    //   src: '/images/gameLogo/ace333-app.png',
    //   code: 'ace333',
    //   label: 'ace333',
    //   route: '/ace333',
    //   id: 1,
    //   html5: 0,
    // }, {
    src: '/images/gameLogo/ACE333.png',
    code: 'ace333',
    label: 'ace333',
    route: '/ace333',
    id: 1,
    html5: 1
    }, {
      src: '/images/gameLogo/games-joker.png',
      code: 'joker',
      label: 'Joker',
      route: 'joker',
      id: 8,
      html5: 1,
    // }, {
    //   src: '/img/games/playtech.png',
    //   code: 'playtech',
    //   label: 'Playtech',
    //   route: 'playtech/login',
    //   id: 4,
    //   redirect: true
    // }, {
    //   src: '/images/gameLogo/slot-sg.png',
    //   code: 'spadegaming',
    //   label: 'SpadeGaming',
    //   route: 'spadegaming',
    //   id: 7,
    //   // deeplink: true
    //
    // }, {
    //   src: '/images/gameLogo/live22.png',
    //   code: 'live22',
    //   label: 'Live22',
    //   route: 'live22',
    //   idrActive: 0,
    //   id: 13
    //
    // },
    // {
    //   src: '/img/m8.png',
    //   code: 'm8',
    //   label: 'M8',
    //   route: '/m8',
    //   id: 9
    // },
    // {
    //   src: '/img/games/asiagaming.png',
    //   code: 'asiagaming-galaxy',
    //   label: 'Asia Gaming',
    //   route: '/asiagaming',
    //   id: 21
    // id: 14

    // }, {
    //   src: '/img/games/sbobet.png',
    //   code: 'sbo',
    //   label: 'SBO',
    //   route: '/sbo',
    //   id: 21
    // id: 14
  },
  {
    src: '/images/gameLogo/Jili.png',
    code: 'sexy_baccarat',
    label: 'JILI SLOT',
    route: 'jili-slot',
    idrActive: 0,
    id: 23
  },
  // {
  //   src: '/images/gameLogo/slot-redtiger.png',
  //   code: 'sexy_baccarat',
  //   label: 'Red Tiger',
  //   route: 'awc/RT/SLOT',
  //   idrActive: 0,
  //   id: 23
  // },
  // {
  //   src: '/images/gameLogo/slot-ae.png',
  //   code: 'sexy_baccarat',
  //   label: 'AE Gaming',
  //   route: 'aws-slot',
  //   idrActive: 0,
  //   id: 23
  // },
  // {
  //   src: '/images/gameLogo/slot-pg-soft.png',
  //   code: 'sexy_baccarat',
  //   label: 'Pocket games Soft',
  //   route: 'pg-slot',
  //   idrActive: 0,
  //   id: 23
  // },

  {
    src: '/images/gameLogo/slot-redtiger.png',
    code: 'sexy_baccarat',
    label: 'Red Tiger',
    route: 'awc/RT/TABLE',
    idrActive: 0,
    id: 23
  },
  {
    src: '/images/gameLogo/games-jdb.png',
    code: 'sexy_baccarat',
    label: 'JDB',
    route: 'jdbfish',
    idrActive: 0,
    id: 23
  },
  // {
  //   src: '/images/gameLogo/Jili.png',
  //   code: 'sexy_baccarat',
  //   label: 'JILI TABLE',
  //   route: 'jili-table',
  //   idrActive: 0,
  //   id: 23
  // },
  // {
  //   src: '/images/gameLogo/Jili.png',
  //   code: 'sexy_baccarat',
  //   label: 'JILI FISH',
  //   route: 'jili-fish',
  //   idrActive: 0,
  //   id: 23
  // },
  // {
  //   src: '/images/gameLogo/slot-ae.png',
  //   code: 'sexy_baccarat',
  //   label: 'AE Gaming',
  //   route: 'aws',
  //   idrActive: 0,
  //   id: 23
  // }
]
