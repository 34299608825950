/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import config from 'config'
import Joi from '@hapi/joi'
// import socket from 'service/socket'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import CurrentBalance from 'component/CurrentBalance'
import { Checkbox , Button, Form, Input, Select } from 'antd';
import numeral from 'numeral'
import InputField from '../../component/textInput/InputField'
import MenuItem from '../../component/layout/MenuItem'
import BoxLeft from '../../component/layout/BoxLeft'
import BoxRight from '../../component/layout/BoxRight'

const { Option } = Select;
const bankOnline =  [
  {
    value: "BKKB",
    title: "Bangkok Bank"
  },
  {
    value: "BAAC",
    title: "Bank for Agriculture and Agricultural Cooperatives	"
  },
  {
    value: "KSAB",
    title: "Bank of Ayudhya (Krungsri)"
  },
  {
    value: "CIMT",
    title: "CIMB Thai Bank"
  },
  {
    value: "GSBA",
    title: "Government Savings Bank	"
  },
  {
    value: "KSKB",
    title: "Kasikorn Bank	"
  },
  {
    value: "Kiatnakin Bank",
    title: "Kiatnakin Bank"
  },
  {
    value: "KTBA",
    title: "Krung Thai Bank	"
  },
  {
    value: "SCBB",
    title: "Siam Commercial Bank"
  },
  {
    value: "SCBT",
    title: "Standard Chartered Bank (Thai)"
  }
]
function Withdraw({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [bankOption, setbankOption] = React.useState({})
  const [dataForm, setDataForm] = useState({})
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('WITH_SUC')
  })

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t('WITH_ERR'),
    btnLabel: t('GOT_IT'),
    type: 'error'
  })
  const user = store.get('user')
  const onSubmit = async payload => {
    try {
      const { user_id: userId, user_account_no: username } = store.get('user')
      // console.log(payload.amount);
      const submited = {
        amount: payload.amount,
        bank_name: payload.bank_name.replace('\t', ''),
        bankCode: payload.bankCode,
        bank_account_name: payload.bank_account_name,
        bank_account_number: payload.bank_account_no,
        currency: config.currency,
        payment_method: 'transaction',
        username
      }
      await withLoading(() =>
        http.post({ path: `users/${userId}/withdraw`, payload: submited })
      )
      // socket.emit('messages', {
      //   msgType: 'withdraw.request',
      //   data: {
      //     message: `Request withdraw from '${username}'`,
      //     title: 'Withdraw Request'
      //   }
      // })
      showDialogWithMessage(t('WITH_DONE'))
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    bankName: Joi.string()
      .label('Bank Name')
      .required(),
    bankAccountName: Joi.string()
      .label('Bank Account Name')
      .required(),
    bankAccountNo: Joi.string()
      .label('Bank Account Number')
      .required(),
    amount: Joi.number()
      // .min(10)
      .required()
  })

  React.useEffect(() => {
    // fetchData()
    form.setFieldsValue({
      name: dataForm.name,
      bank_account_name: dataForm.bank_account_name,
      bank_account_no:dataForm.bank_account_no
    });
  }, [form,dataForm])
  // const [bankName, bankAccountName, bankAccountNo, amount] = formCreateInputs(['bankName', 'bankAccountName', 'bankAccountNo', 'amount'], form)
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      bank_name: user.bankName,
      bankCode: user.bankCode,
      bank_account_name: user.bankAccount,
      bank_account_no: user.bankAccountNumber
    })
  }

  return (
    <div className="containerActive">
      <MenuItem />
      <div className="styledContainer">
        <BoxLeft />
        {/* <div className="title_recoment tile_deposit">{t('WITHDRAW')}</div> */}
        <div className="profile-container">
          <div className="containerBox">
            <p className="title-profile">Current Balance</p>
            <CurrentBalance />
          </div>
          <div className="containerBox">
            <p className="title-profile">Withdraw</p>
            <div
              className="deposit-right-main profile-main"
              style={{ marginTop: '20px' }}
            >
              <Form
                form={form}
                className="form-deposit"
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="selectbank"
                  className="label-with-draw"
                  label={t('SELECT_BANK')}
                >
                  <label
                    style={{
                      margin: '0px !important'
                    }}
                    className="label-with-draw"
                  >
                    {user.bankName}
                  </label>
                </Form.Item>
                <Form.Item
                  name="bank_account_name"
                  className="label-with-draw"
                  label={t('BANK_ACCOUNT_NAME')}
                >
                  <label
                    style={{
                      margin: '0px !important'
                    }}
                    className="label-with-draw"
                  >
                    {user.bankAccount}
                  </label>
                </Form.Item>
                <Form.Item
                  name="bank_account_no"
                  className="label-with-draw"
                  label={t('BANK_ACCOUNT_NO')}
                >
                  <label
                    style={{
                      margin: '0px !important'
                    }}
                    className="label-with-draw"
                  >
                    {user.bankAccountNumber}
                  </label>
                </Form.Item>
                <Form.Item
                  name="amount"
                  className="label-with-draw"
                  label={t('WITHDRAW_AMOUNT')}
                >
                  <InputField
                    type="number"
                    size="large"
                    placeholder="WithDraw Amount..."
                  />
                </Form.Item>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 15
                    }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <div className="text-center formGroup_btn">
                    <Button
                      type="primary"
                      id="custom-primary-button-deposit"
                      loading={loading}
                      htmlType="submit"
                    >
                      {t('WITHDRAW_NOW')}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
        <div className="showBoxright">
          <BoxRight />
        </div>
      </div>
      <div className="showBoxrightmb">
        <BoxRight />
      </div>
      <Dialog />
      <ErrorDialog />
    </div>
  )
}

export default Withdraw
