export default [{
  src: '/img/gameLogo/Esport.png',
  code: 'maxbet',
  label: 'Maxbet',
  route: 'maxbet',
  idrActive: 0,
  id: 6
// }, {
//   src: '/img/games/m8bet.png',
//   code: 'm8',
//   label: 'M8',
//   route: 'm8',
//   idrActive: 1,
//   id: 9
// }, {
//   src: '/img/games/asiagaming.png',
//   code: 'asiagaming',
//   label: 'Asia Gaming',
//   route: '/asiagaming?GameType=SB',
//   id: 21
// }, {
//   src: '/img/games/sbobet.png',
//   code: 'sbo',
//   label: 'SBO',
//   route: '/sbo',
//   idrActive: 1,
//   id: 21
  // id: 14
// }, {
//   src: '/img/games/CMD.png',
//   code: 'cmd',
//   label: 'CMD',
//   route: '/cmd',
//   id: 19
}]