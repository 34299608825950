/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import {
  Divider,
  List,
  ListItem
} from '@material-ui/core'
import store from 'store'
import { map } from 'ramda'
import config from 'config'
import { useTranslation } from 'react-i18next'

const topSidebar = (tran) => [
  {
    title: tran('HOME'),
    route: '/',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-home.svg`} />,
    ACL: '*'
  },
  {
    title: tran('ACCOUNT'),
    route: '/account',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/login.png`} />,
    ACL: '*'
  },
  {
    title: tran('HISTORIES'),
    route: 'histories',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-history.svg`} />,
    ACL: '*'
  },
  {
    title: tran('REPORTS'),
    route: 'reports',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-report.svg`} />,
    ACL: '*'
  },
  {
    title: tran('TOPUP'),
    route: config.currency == 'IDR' ? 'deposit' : 'topup',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-receive.svg`} />,
    ACL: '*'
  },
  {
    title: tran('WITHDRAW'),
    route: 'withdraw',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-withdraw.svg`} />,
    ACL: '*'
  },
  {
    title: tran('SPORTS'),
    route: 'sports',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-sport.png`} />,
    ACL: ['SPORT']
  },
  {
    title: tran('FISHING'),
    route: 'fishing',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-sport.png`} />,
    ACL: ['FISHING']
  },
  {
    title: tran('LIVE_CASINO'),
    route: 'casino',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/lc.png`} />,
    ACL: ['CASINO']
  },
  {
    title: tran('SLOTS'),
    route: 'slot',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/slot.png`} />,
    ACL: ['SLOT']
  },
  {
    title: tran('LOTTERY'),
    route: 'lottery',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/lottery.png`} />,
    ACL: ['LOTTERY']
  },
  {
    title: tran('DOWNLOAD'),
    route: 'download',
    icon: <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-download.png`} />,
    ACL: '*'
  }
]

function sideList({ history, handleClickOpenLangModal }) {
  const { t } = useTranslation()
  return (
    <div style={{background: '#076045'}}>
      <List>
        {map(({ title, route, icon }) => (
          <React.Fragment key={title}>
            <ListItem button key={title} onClick={() => history.push(route)}>
              <div style={{textAlign: 'center', width: '100%'}}>
                {icon}
                <span style={{color: '#fff', display: 'block'}}>{title}</span>
              </div>
            </ListItem>
            <Divider />
          </React.Fragment>
        ), topSidebar(t).filter(({ ACL }) => ACL === '*' || ACL.some(r=> config.market.includes(r) )))}
        <ListItem button onClick={handleClickOpenLangModal}>
          <div style={{textAlign: 'center', width: '100%'}}>
            <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/lang_icon.png`} />
            <span style={{color: '#fff', display: 'block'}}>{t('LANGS')}</span>
          </div>
        </ListItem>
        <Divider />
        <ListItem button onClick={() => { history.push('/'); store.clearAll()}}>
          <div style={{textAlign: 'center', width: '100%'}}>
            <img style={{width: 40}} src={`${process.env.PUBLIC_URL}/icon/icon-logout.svg`} />
            <span style={{color: '#fff', display: 'block'}}>{t('LOGOUT')}</span>
          </div>
        </ListItem>
      </List>
    </div>
  )
}

export default sideList
