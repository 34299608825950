/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'

const { useDialog, useLoading } = component

function lotto() {
  const [Dialog, showDialog] = useDialog({
    title: '97Lotto'
  })
  const [lottoLoginUrl, updateLottoLoginUrl] = React.useState(null)
  const [loading, withLoading] = useLoading()
  const login = async () => {
    try {
      const {link} = await withLoading(() => http.post({path: 'api/authenticate/lotto-thai'}))
      // updateLottoLoginUrl(urlLogin.replace(/^http:\/\//i, 'https://'))
      window.open(link.replace(/^http:\/\//i, 'https://'))
    } catch (error) {
      showDialog(error.message)
    }
  }

  React.useEffect(() => {
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 0) {
      localStorage.loadgame = 1;
      window.location.reload(false);
    }
    login()
  }, [])

  return (
    <div className="col8">
      {loading || !lottoLoginUrl ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : <div className="play-game full-screen">
          <iframe src={lottoLoginUrl} />
        </div>}
      <Dialog />
    </div>
  )
}

export default lotto