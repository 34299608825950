import React from 'react'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'

const RightSideBar = ({ history }) => {
  const { t } = useTranslation()
  return (
    <div className="col2 show_pc">
      <div className="mainRight">
        <div className="sb_right1 mb_8">
          <div className="sb_r1_title">
            {t('START_THE_ROAD_TO_ADVANCEMENT')}
          </div>
          <h3 className="rs">{t('ROAD_TO_ADVANCEMENT')}</h3>
          <h4 className="rs">{t('START_UPDATE_EVERY_MONDAY')}</h4>
          <div className="loading">
            <span className="lv1">Lv.0</span>
            <span className="lv2">Lv.1</span>
          </div>
          <div className="sb_r1_text">
            <p className="rs">{t('BET_THIS_MONTH')}: <strong>0.00</strong></p>
            <p className="rs">{t('WASHABLE_CODE_AMOUNT_OF')}: <strong>0.00</strong></p>
          </div>
          <div className="sb_r1_btn text-center">
            <a href="">{t('TO_WASH_CODE')}</a>
          </div>
        </div>
        <div className="sb_right2">
          <div className="sb_r2_item">
            <div className="sb_r2_item_title">
              {t('MY_OFFER')}
              <a href=""></a>
            </div>
            <div className="please_login">{t('PLEASE')} <a className="c-pointer" onClick={() => history.push('/login')}>{t('LOGIN')}</a> {t('TO_VIEW')}</div>
          </div>
          <div className="sb_r2_item">
            <div className="sb_r2_item_title">
              {t('RECENTLY_PLAYED')}
              <a href=""></a>
            </div>
            <div className="please_login">{t('PLEASE')} <a className="c-pointer" onClick={() => history.push('/login')}>{t('PLEASE')}</a> {t('TO_VIEW')}</div>
          </div>
          <div className="sb_r2_item">
            <div className="sb_r2_item_title">
              {t('MY_COLLECTION')}
              <a href=""></a>
            </div>
            <div className="please_login">{t('PLEASE')} <a className="c-pointer" onClick={() => history.push('/login')}>{t('PLEASE')}</a> {t('TO_VIEW')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(RightSideBar);