/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Banking from '../NewBankingMb/banking'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import store from 'store'
import { useTranslation } from 'react-i18next'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import "./style.css"
import { Col, Row } from 'reactstrap'
import BoxRight from '../../component/layout/BoxRight'
import MenuItem from '../../component/layout/MenuItem'
import BoxLeft from '../../component/layout/BoxLeft'
import StyledButton from '../../component/button/syledButton'

function HistoriesNewTest({ classes, history }) {
  const { balanceGame } = store.get('user')
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [games, updateGame] = useState([])
  const [histories, updateHistories] = useState([])
  const [selectedType, updateSelectedType] = React.useState(null)
  // const [changeTypes, setChangeTypes] = useState("winlose")
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Histories Warning',
    btnLabel: 'Got it'
  })

  // const handleChangeTypes = (typeTr) => {
  //   setChangeTypes(typeTr)
  // }

  useEffect(() => {
    http.get({ path: 'games' }).then(updateGame)
  }, [])
  const onSubmit = async ({ txType, type, fromDate, toDate }) => {
    console.log(type)
    // console.log(changeTypes)
    console.log(txType)
    try {
      const params = {
        fromDate: moment(fromDate).format('YYYY-MM-DD 00:00:00'),
        toDate: moment(toDate).format('YYYY-MM-DD 23:59:59'),
        game: type.split('-')[0],
        txType
      }
      // console.log(params)
      await withLoading(() =>
        http
          .get({
            path: `report/transaction-history`,
            params: {
              ...params,
              game: params.game != '' ? params.game : undefined
            }
          })
          .then(rs => {
            let rabats = rs.rabats
            if (params.txType == 'winlose' && params.game != '')
              rabats = rabats.filter(x => x.game_code == params.game)
            updateHistories(rabats)
          })
      )
      updateSelectedType(type)
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    txType: Joi.string().label('txType'),
    type: Joi.string().label('Game'),
    fromDate: Joi.date()
      .label('From Date')
      .required(),
    toDate: Joi.date()
      .label('To Date')
      .required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    // validate: validate(schema),
    initialValues: {
      //   fromDate: moment().toDate(),
      //   toDate: moment().toDate(),
      type: 'all-'
    }
  })

  const [txType, type, fromDate, toDate] = formCreateInputs(
    ['txType', 'type', 'fromDate', 'toDate'],
    form
  )
  return (
    <div className="containerActive">
      <MenuItem />
      <div className="styled-title-router">History</div>
      <div className="styledContainer">
        <BoxLeft />
        <form className="deposit-new-container" onSubmit={handleSubmit}>
          <div className="deposit-new-content">
            <p className="deposit-new-title">Transaction Type</p>
            <div className="history-new-container-box">
              <div className="react-datepicker__input-container">
                <select
                  name=""
                  className="deposit-new-select"
                  {...txType.input}
                >
                  <option value="winlose">Winlose</option>
                  <option value="deposit">Deposit</option>
                  <option value="withdraw">Withdraw</option>
                  <option value="bonus">Bonus</option>
                  <option value="comm">Comm</option>
                </select>
              </div>
              {/* <div
                className={
                  changeTypes === 'winlose'
                    ? 'history-new-styled-box-active'
                    : 'history-new-styled-box'
                }
                onClick={() => handleChangeTypes('winlose')}
              >
                <p className="history-new-text">Winlose</p>
              </div>
              <div
                className={
                  changeTypes === 'deposit'
                    ? 'history-new-styled-box-active'
                    : 'history-new-styled-box'
                }
                onClick={() => handleChangeTypes('deposit')}
              >
                <p className="history-new-text">Deposit</p>
              </div>
              <div
                className={
                  changeTypes === 'withdraw'
                    ? 'history-new-styled-box-active'
                    : 'history-new-styled-box'
                }
                onClick={() => handleChangeTypes('withdraw')}
              >
                <p className="history-new-text">Withdraw</p>
              </div>
              <div
                className={
                  changeTypes === 'bonus'
                    ? 'history-new-styled-box-active'
                    : 'history-new-styled-box'
                }
                onClick={() => handleChangeTypes('bonus')}
              >
                <p className="history-new-text">Bonus</p>
              </div>
              <div
                className={
                  changeTypes === 'comm'
                    ? 'history-new-styled-box-active'
                    : 'history-new-styled-box'
                }
                onClick={() => handleChangeTypes('comm')}
              >
                <p className="history-new-text">Comm</p>
              </div> */}
            </div>
          </div>
          <div className="deposit-new-content">
            <p className="deposit-new-title">Select Games</p>
            <select name="" className="deposit-new-select" {...type.input}>
              <option value="all-"></option>
              {games.map(g => (
                <option
                  value={g.game_code + '-' + g.label}
                  key={g.game_code + g.label}
                >
                  {g.label || g.name}
                </option>
              ))}
            </select>
            <div className="history-new-container-box"></div>
          </div>
          <div className="history-new-styled-option-date">
            <div className="history-new-date-start">
              <p className="history-new-title">
                Start Date <span className="type-red">*</span>
              </p>
              <DatePicker
                showIcon={true}
                className="history-new-styled-inputdate"
                onChange={date => {
                  fromDate.input.onChange({ target: { value: date } })
                }}
                selected={fromDate.input.value}
              />
            </div>
            <div className="history-new-date-start">
              <p className="history-new-title">
                End Date <span className="type-red">*</span>
              </p>
              <DatePicker
                showIcon={true}
                className="history-new-styled-inputdate"
                onChange={date =>
                  toDate.input.onChange({ target: { value: date } })
                }
                selected={toDate.input.value}
              />
            </div>
          </div>
          <div className="styled-botton-center">
            <StyledButton
              width="276px"
              height="38px"
              background="#FF8A00"
              margin="20px 0"
              disabled={submitting}
              type="submit"
            >
              <span></span>
              Submit
            </StyledButton>
          </div>
          <div className="deposit-new-content">
            <div
              style={{ overflow: 'auto', maxWidth: '100%', background: '#fff' }}
            >
              <Banking bankings={histories} loading={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default withStyles(styles)(HistoriesNewTest)
