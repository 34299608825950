/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-final-form-hooks'
import http from 'service/http'
import { useTranslation } from 'react-i18next'
import './style.css'
import useLoading from '../loading/hook'
import store from 'store'
import ReactDOM from 'react-dom'
import { Modal, Form, DatePicker, Button } from 'antd'
import moment from 'moment'
import Joi from '@hapi/joi'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'

function CompCommon({
  getListHistory,
  className,
  history,
  show,
  title,
  close,
  children,
  icon
}) {
  // console.log("getListHistory", getListHistory());
  const [isShowDatePicker, setShowDatePicker] = useState(false)
  const [loading, withLoading] = useLoading(false)
  const [histories, updateHistories] = useState({})

  const onshowDatePicker = () => {
    setShowDatePicker(true)
  }
  const oncloseDatePicker = () => {
    setShowDatePicker(false)
  }
  const { t } = useTranslation()

  const onClose = () => {
    close()
  }
  // useEffect(() => {
  //   getListHistory(histories)
  // },[])
  const [newIcon, setIcon] = useState('images/icon/2.png')
  const onSubmit = async payload => {
    try {
      const params = {
        fromDate: convert(payload.start_date),
        toDate: convert(payload.end_date),
        game: 'all'
      }
      // console.log(params)
      const response = await withLoading(() =>
        http.get({ path: `admin/report/transaction-history`, params })
      )
      if (response) {
        getListHistory(response.deposits)
        // updateHistories(response.deposits)
        form.resetFields()
        oncloseDatePicker()
      }
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    type: Joi.string()
      .label('Game')
      .required(),
    fromDate: Joi.date()
      .label('From Date')
      .required(),
    toDate: Joi.date()
      .label('To Date')
      .required()
  })

  const [form] = Form.useForm()
  const handleSubmit = values => {
    onSubmit({ ...values })
  }
  const convert = str => {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join('-')
  }
  return (
    <>
      {show
        ? ReactDOM.createPortal(
            <React.Fragment>
              {' '}

                <div className={`Component-common ${className}`} style={{
                  height: window.innerHeight - 55,
                  position: 'absolute',
                  zIndex: 8,
                  overflowY: 'scroll'}}>
                    <div className="header-detail_mb" style={{
                      position: 'fixed',
                      top: 0,
                      zIndex: 9,
                      width: '100%',
                      background: '#fff'
                    }}>
                      <div className="link-back" style={{textAlign: 'left', paddingLeft: 10}} onClick={() => {
                        onClose()
                      }}>
                        <img style={{width: '15%'}} src="images/home/back.svg" alt="" />
                      </div>
                      <div className="title-name">
                        <label style={{fontSize: '18px', margin: '15px auto'}}>{title}</label>
                      </div>
                      <div className="link-noti" style={{textAlign: 'right', paddingRight: 10}} onClick={onshowDatePicker}>
                        <img src={icon ? icon : newIcon} alt="" />
                      </div>
                    </div>
                    {children}
                </div>

            </React.Fragment>,
            document.body
          )
        : null}
      {isShowDatePicker && (
        <Modal
          footer={null}
          title="Choose date"
          visible={isShowDatePicker}
          onCancel={oncloseDatePicker}
        >
          <Form
            form={form}
            layout="vertical"
            className="form-deposit"
            onFinish={handleSubmit}
          >
            <div
              style={{ justifyContent: 'unset' }}
              className="form-report history-transcation form-report-todate display_flex"
            >
              <Form.Item label={t('FROM_DATE')} name="start_date">
                <DatePicker
                  size="large"
                  placeholder="Start date..."
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </div>
            <div
              style={{ justifyContent: 'unset' }}
              className="form-report form-report-todate display_flex"
            >
              <Form.Item label={t('TO_DATE')} name="end_date">
                <DatePicker
                  size="large"
                  placeholder="End date..."
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </div>
            <div className="report-submit">
              <Button type="primary" loading={loading} htmlType="submit">
                {t('Report')}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default CompCommon
