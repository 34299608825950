/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import Header from 'component/header'
import CloseIcon from '@material-ui/icons/Close'
import { map, isEmpty } from 'ramda'
import Bottom from 'component/bottom'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import useLoading from '../loading/hook'
import styles from './style'
import SLOTS from './constant'
import config from 'config'
import gameData from '../../constants/gameData'
import queryString from 'query-string'
import GameAccount from '../../component/gameAccount'
import Head from '../../component/layout/Head'
import Testimonials from '../../component/layout/Testimonials'
import TotalPlay from '../../component/layout/TotalPlay'
import component from '../../component/'
import SlotGameComp from 'feature/slotGameComp'

const { useDialog } = component


const listSlotGame = [
  {
    src: './images/home/slotkingmaker.png',
    classshow: 'pages-icon-hot'
  },
  {
    src: './images/home/slotrich88.png',
    classshow: 'pages-icon-hot'
  },
  {
    src: './images/home/slotsg.png',
    classshow: 'pages-icon-hot'
  },
  {
    src: './images/home/slotjbd.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotCG.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotfachai.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotjoker.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotpgv2.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotpp.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotredtiger.png',
    classshow: 'pages-icon-hot-none'
  },
  {
    src: './images/home/slotace333.png',
    classshow: 'pages-icon-hot-none'
  }
]
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: `lobbymegarelease://lobbymegarelease?account=${account}&password=${password}`,
    2: `lobbykiss://lobbykiss?account=${account}&password=${password}`,
    8: `joker://www.joker123.net/mobile?data=${account}`,
    1: ''
  }

  return proxy[id]
}

function home({ history, classes }) {
  const {
    location: { search }
  } = history
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const [gameSelected, updateGameSelected] = React.useState({})
  const [games, setGames] = React.useState([])
  const { user_id: userId } = store.get('user') || { user_id: undefined }
  const { game, html55 } = queryString.parse(search)
  const imageslot = './images/home/bannerslot.png'
  const gamesSetup = async () => {
    const gameList = await http.get({ path: 'games' })
    setGames(gameList)
    return gameList
  }

  const loginToGame = async (
    { src, label, route, id, redirect, target, html5, code },
    gameList = null
  ) => {
    if (target === '_blank') {
      window.open(route)
    }
    if (redirect) {
      history.push(route)
      return
    }
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'Please login first !' })
      return
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    updateGameSelected({ label, src, route, id })
    try {
      const providerCode = code === 'asiagaming-galaxy' ? '?ProviderId=AG' : ''
      const { balance, err, game_password: password } = await withLoading(() =>
        http
          .post({
            path: `users/${userId}/game/logout${providerCode}`,
            payload: {
              gameLoginId: id
            }
          })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message }
            }
            return true
          })
          .then(() =>
            http.get({
              path: `users/${userId}/games/${id}/balance`,
              params: { ProviderId: 'AG' }
            })
          )
      )

      let deeplinkAcc = balance.account
      if (code === 'joker' && html5 === 0)
        deeplinkAcc = await http
          .get({
            json: false,
            path: `games/joker/login`,
            params: { GameCode: 0, mode: 'M' }
          })
          .then(rs => JSON.parse(rs).sign)
      // console.log(html5, code, html5 === 0, deeplinkAcc)
      const outDeeplink = generateDeeplink({
        id,
        account: deeplinkAcc,
        password
      })
      updateGameSelected({
        label,
        src,
        route,
        id,
        balance: balance.balance,
        account: balance.account,
        err,
        deeplink: outDeeplink,
        password,
        html5,
        code
      })
      if (
        code !== 'xe88' &&
        (html5 === 1 || !outDeeplink || outDeeplink === '')
      )
        history.push(route)
    } catch (e) {
      showDialogErrorWithMessage(e.message)
    }
  }
  const fetchGame = async () => {
    const gameList = await gamesSetup()
    // console.log(gameList)
    if (game) {
      if (html55 == 1) {
        // console.log(SLOTS, html55)
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code
        } = SLOTS.find(x => x.code === game && x.html5 === html55)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        )
      } else {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code
        } = SLOTS.find(x => x.code === game)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        )
      }
    }
  }
  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` })
    }

    fetchGame()
  }, [])

  const handleClose = () => {
    updateGameSelected({})
  }
  const { t } = useTranslation()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
      <Head image={imageslot} />
      <div className="pages">
        <button className="pages-btn-items">Slot Game</button>
        <SlotGameComp history={history} />
        <img
          style={{ width: '100%', margin: '30px 0' }}
          src="./images/home/bannerslots.png"
          alt="bannerslot"
        />
        <TotalPlay />
        <Testimonials />
      </div>
    </div>
  )
}
export default withStyles(styles)(home)
