/* eslint-disable no-restricted-globals */
import config from 'config'
import store from 'store'
import queryString from 'query-string'

function handleErrors(response) {
  if ([200, 201].includes(response.status)) {
    return response
  }
  if (response.status === 401) {
    store.clearAll()
    location.href = '/login'
  }

  throw response
}
const objectToQueryString = (obj, prefix) => {
  return Object.keys(obj).map(objKey => {
      if (obj.hasOwnProperty(objKey)) {
          const key = prefix ? `${prefix}[${objKey}]` : objKey;
          const value = obj[objKey];

          return typeof value === "object" ?
              this.objectToQueryString(value, key) :
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      return null;
  }).join("&");
}
export default {
  token: undefined,

  headers: {},

  setJwtToken(token) {
    this.token = token
    this.establishHeaderRequest()
  },

  establishHeaderRequest() {
    this.headers = {
      'content-type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
  },

  get({
    path = '',
    params,
    json = true
  }) {
    return fetch(`${config.baseUrl}/${path}?${queryString.stringify(params)}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
    })
      .then(handleErrors)
      .then(response => {
        return json ? response.json() : response.text()
      })
      .catch(async err => { throw await err.json() })
  },

  put({
    path = '',
    payload
  }) {
    return fetch(`${config.baseUrl}/${path}`, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then(response => response.json())
      .catch(async err => { throw err.json ? await err.json() : err })
  },

  post({
    path = '',
    payload,
    json = true
  }) {
    return fetch(`${config.baseUrl}/${path}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then(response => {
        return json ? response.json() : response.text()
      })
      .catch(async err => {
        console.log(err)
        throw await err.json()
      })
  },
  postParams({
    path = '',
    params,
    json = true
  }) {
    return fetch(`https://stagecorpapi.vaderpay.net/${path}?${objectToQueryString(params)}`, {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Basic guXyh1B1Q7Ev4PeAH9FErfqoKDXN5ullfkzwgiyC1wU='
      },
      redirect: "follow",
      referrer: "no-referrer",
    })
      .then(handleErrors)
      .then(response => {
        return json ? response.json() : response.text()
      })
      .catch(async err => {
        console.log(err)
        throw await err.json()
      })
  }
}