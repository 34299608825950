/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import {
  CssBaseline,
  GridList,
  GridListTile,
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import { withStyles } from '@material-ui/core/styles'
import store from 'store'
import http from 'service/http'
import component from 'component'
import styles from './style'
import GAMES from '../games'
var currentgame;
const { useDialog, Header } = component

function Login({ classes = {}, history, match: { params: { code } } }) {
  // console.log(history)
  const [loading, updateLoading] = useState(false)
  const [logged, updateLogged] = useState(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Login Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const mobiledomain = 'ld176988.com'
  const systemidvar = "424";

  window.iapiSetCallout('GetTemporaryAuthenticationToken', calloutGetTemporaryAuthenticationToken);
  window.iapiSetCallout('Logout', calloutLogout);
  window.iapiSetCallout('GetLoggedInPlayer', calloutLoggedInPlayer);

  window.iapiGetLoggedInPlayer(1);

  function opengame(game) {
    var realMode = 1;
    currentgame = game;
    window.iapiRequestTemporaryToken(realMode, systemidvar, 'GamePlay');
  }
  const launchMobileClient = async (temptoken) => {
    const { user_id: userId } = store.get('user')
    const accounts = await http.post({ path: `users/${userId}/sync-game` })
    const PTAccount = accounts.find(({ game_id: id }) => id === 4)
    const clientUrl = '//hub.' + mobiledomain + '/igaming/?gameId=' + currentgame + '&real=1&username=' + PTAccount.game_account.toUpperCase() + '&lang=en&tempToken=' + temptoken + '&lobby='
      + window.location.href.substring(0, window.location.href.lastIndexOf('/') + 1) + 'login&deposit='
      + window.location.href.substring(0, window.location.href.lastIndexOf('/') + 2) + 'topup'
    window.open(clientUrl);
    // window.open(clientUrl, '_blank');
  }

  function calloutGetTemporaryAuthenticationToken(response) {
    if (response.errorCode) {
      alert("Token failed. " + response.playerMessage + " Error code: " + response.errorCode);
    }
    else {
      launchMobileClient(response.sessionToken.sessionToken);
    }
  }

  function calloutLoggedInPlayer(response) {
    console.log(4444, response)
    // if (response.errorCode) {
    //   document.location = "/";
    // }
    // else {
    //   if (response.username == "") {
    //     document.location = "/";
    //   }
    // }
  }

  function logout(allSessions, realMode) {
    window.iapiLogout(allSessions, realMode);
  }
  const checkWith = () => {
    let width = 4
    let height = 110
    const e = document.getElementById('root')
    // if(e.clientWidth > 576) width = 4
    if(e.clientWidth > 720) {
      width = 5
      height = 140
    }
    if(e.clientWidth > 960) {
      width = 5
      height = 140
    }
    if(e.clientWidth > 1140) {
      width = 6
      height = 190
    }
    return {width, height}
  }
  function calloutLogout(response) {
    sessionStorage.clear();
    alert("Logout OK");
    document.location = "/";
  }
  const accessFishing = (code) => {
    window.location = `http://cache.download.banner.winforfun88.com/casinoclient.html?language=en&game=${code}`
  }
  const initialPT = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 4)) {
        http.post({
          path: `users/${userId}/game/logout`, payload: {
            gameLoginId: '4'
          }
        })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message }
            }
            return true
          })
          .then(async () => {
            const calloutLogin = (response) => {
              if (response.errorCode) {
                updateLoading(false)
                console.log(111, response)
                showDialogErrorWithMessage(`${response.playerMessage === '' ? response.errorText : response.playerMessage} Error  ${response.errorText}`)
              }
              else {
                if (code) {

                  accessFishing(code)
                }
                updateLoading(false)
                updateLogged(true)
                console.log("Login OK, you will be redirected to play console", code, response)
              }
            }
            updateLoading(true)
            const accounts = await http.post({ path: `users/${userId}/sync-game` })
            const PTAccount = accounts.find(({ game_id: id }) => id === 4)

            if(PTAccount.game_account) {
              // console.log(121212, PTAccount, PTAccount.game_account.toUpperCase())
              window.iapiSetCallout('Login', calloutLogin)
              window.iapiSetClientPlatform("mobile&deliveryPlatform=HTML5")
              // alert(PTAccount.game_account.toUpperCase())
              window.iapiLogin(PTAccount.game_account.toUpperCase(), PTAccount.game_password, 1, 'en')
            }
          })
          .catch(err => showDialogErrorWithMessage(err.message))
        return

      }
      showDialogErrorWithMessage('Playtech is comming')
    } catch (error) {
      console.log(error)
      showDialogErrorWithMessage(error.message)
    }
  }

  useEffect(() => {
    initialPT()

  }, [])

  return (
    <div className="col-12 col-md-8 col-md-8">
      <CssBaseline />
      {loading || !logged ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : (
          <GridList cellHeight={checkWith().height} cols={checkWith().width} className={classes.gridList}>

            {code && code === 'cashfi' ? GAMES.filter(x => x.type === 'fish').map(({ gameCode, gameName }) => (
              <GridListTile onClick={() => accessFishing(gameCode)} key={gameCode}>
                <img style={{borderRadius: 5, width: '100%'}} src={`${process.env.PUBLIC_URL}/img/playtech/${gameCode}.jpg`} alt={gameName} />

              </GridListTile>
            )) : GAMES.map(({ gameCode, gameName }) => (
              <GridListTile onClick={() => opengame(gameCode)} key={gameCode}>
                <img style={{borderRadius: 5, width: '100%'}} src={`${process.env.PUBLIC_URL}/img/playtech/${gameCode}.jpg`} alt={gameName} />
                {/*<GridListTileBar*/}
                // title={gameName}
                {/*/>*/}
              </GridListTile>
            ))}
          </GridList>)}
      <ErrorDialog />
    </div>
  )
}

export default withStyles(styles)(Login)
