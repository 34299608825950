import React from 'react';
import { Input as AntdInput } from 'antd';

const InputField = ({ className, onChange, type,value, ...rest }) => {
  const handleChange = (e) => {
    const { value: currentValue } = e.target;
    if(onChange){
      onChange(currentValue);
    }
  };

  return (
    <div className="Input">
      {type === 'password' ? (
        <AntdInput.Password type={type} onChange={handleChange} {...rest} />
      ) : (
        <AntdInput value={value} type={type} onChange={handleChange} {...rest} />
      )}
    </div>
  );
};

export default InputField;
