/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import styles from './style.css'
import moment from 'moment'
import store from 'store'
import { PropagateLoader } from 'react-spinners'

import http from 'service/http'
import './style.css'
import useLoading from '../loading/hook'
import ReactDOM from 'react-dom'
import { Modal, Form, DatePicker, Button } from 'antd'

const initDate = {
  fromDate: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
  toDate: moment().format('YYYY-MM-DD 23:59:59')
}

function NewHistoriesMb({ histories }) {
  const { t } = useTranslation()
  const user = store.get('user')
  const [loading, withLoading] = useLoading(false)
  const [isShowDatePicker, setShowDatePicker] = useState(false)
  const onshowDatePicker = () => {
    setShowDatePicker(true)
  }
  const oncloseDatePicker = () => {
    setShowDatePicker(false)
  }

  useEffect(() => {
    // fetch()

  }, [])
  const [form] = Form.useForm()
  return (
    <>
      <React.Fragment>
        <div className="show_mb content-history-mb" style={{marginTop: 80}}>
          <div className="withdraw-depo">
            <span>Withdraw/Deposit</span>
          </div>
          <div style={{
            marginTop: 'auto',
            borderRadius: 0,
            background: '#e0dede',
            marginBottom: 'auto', display: 'flex'
          }}
               className="popup-history"
            // onClick={handleClickInside}
          >
            <div className="content-history" style={{padding: 0}}>
              <div className="history">
                <table className="table">
                  <thead style={{color: '#0a611e'}}>
                  <tr>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                  </tr>
                  </thead>
                  { loading ? (
                    <div style={{ justifyContent: 'center', margin: 100 }}>
                      <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
                      />
                    </div>
                  ) : <tbody>
                  {histories && histories.map(e => {
                    return (
                      <tr>
                        <td scope="row">{moment(e.created_at).format('DD-MM HH:mm')}</td>
                        <td>{e.amount}</td>
                        <td>{e.status == 'pending' ? <img style={{width: 35}} src="images/icon/pending.png" alt="" /> : (['Success','accept', 'success'].includes(e.status) ? <img style={{width: 35}} src="images/icon/correct.png" alt="" /> : <img style={{width: 35}} src="images/icon/cross.png" alt="" />) }</td>
                      </tr>
                    )
                  })}

                  </tbody>}
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      </>
      )
}

export default withStyles(styles)(NewHistoriesMb)
