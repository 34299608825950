export default {
  LANGS: '语言',
  HOME: '主页',
  SPORTS: '体育',
  POKER: 'Poker',
  VIRTUAL_SPORTS: '虚拟体育',
  LIVE_CASINO: '真人娱乐场',
  SLOTS: '老虎机',
  LOTTERY: '彩票',
  PROMOTIONS: '促销活动',
  CONTACT_US: '联系我们',
  LIVE_CHAT: '在线聊天',
  WECHAT: '微信',
  BANKING: '银行',
  ABOUT_US: '关于我们',
  RESPONSIBLE_GAMING: '负责任的游戏',
  TERMS_OF_USE: '使用条款',
  TRUSTED_PAYMENT: '可信付款',
  TRANSFER: '转移',
  TOPUP: '充值',
  WITHDRAW: '提款',
  ACCOUNT: '帐户',
  HISTORIES: '历史',
  REPORTS: '報告',
  DOWNLOAD: '下载',
  LOGOUT: '退出',
  EDIT_PROFILE: '编辑个人资料',
  CHANGE_PASSWORD: '更改密码',

  // FORM AREA
  BANK_NAME: '请输入银行名称（完整）',
  BANK_ACCOUNT_NAME: '请输入帐号名称',
  BANK_ACCOUNT_NO: '请输入银行帐号否',
  WITHDRAW_AMOUNT: '请输入提取金额',
  WITHDRAW_NOW: '现在提取金额',
  SELECT_BANK: '选择银行',
  TOPUP_AMOUNT: '请输入金额',
  AGREEMENT: '我同意条款和条件',
  ONLINE_TOP_UP_NOW: '现在线上存款',
  DEPOSIT_WITH_RECEIPT: '以收据存款',
  DEPOSIT_AMOUNT: '请输入存款金额',
  DEPSOSIT_BONUS: '索赔存款奖金',
  TRANSFER_IN: '转入',
  TRANSFER_OUT: '转出',
  TYPE_TRANS: '选择类型交易',
  SUBMIT: '立即提交',

  LOADING: '加载',
  FROM: '从日期',
  TO: '到日期',
  TRANS_SUC: '转移成功',
  TRANS_ERR: '传输错误',
  TRANS_DONE: '传输已成功完成',
  GOT_IT: '搞定了',
  SELECT_GAME: '选择游戏',
  CLAIM_LOYALTY_BONUS: '索赔忠诚奖金',
  DEPO_SUC: '存款成功',
  DEPO_ERR: '存款错误',
  DEPO_DONE: '您的请求已成功提交',
  DEPOSIT: '存款',
  FILE: '文件',
  WITH_SUC: '提取金额成功',
  WITH_ERR: '提取错误',
  WITH_DONE: '您的请求已成功提交',
  CLICK_DOWNLOAD: '点击下载',
  ENTER_MOBILE: '输入手机号',
  ENTER_PASS: '输入密码',
  SIGN_IN: '登录',
  REGISTER: '注册',
  ENTER_DISPLAY_NAME: '输入您的显示名称',
  ENTER_EMAIL: '输入您的主电子邮件',
  ENTER_AGENT: '输入您的代理电子邮件（可选）',
  RETYPE_PASS: '重新输入密码',
  BACK_TO_LOGIN: '返回登录',
  PULL_OUT: '信用转去钱包',
  RESTORE: 'Restore',
  
  AG_FISHING_KING: 'AG钓鱼王',
  NOTICE_LABEL: '公告',
  NOTICE: '中國農業銀行將執行銀行系統',

  MEMBER_REGISTER: '会员注册',
  YOUR_ACCOUNT_CONTACT_DETAIL: '您的帐户和联系方式',
  USERNAME: '用户名',
  USERNAME_CONDITION: '仅3到9个字符，字母数字（a-z，0-9）',
  EMAIL: '电子邮件地址',
  EMAIL_CONDITION: '密码检索需要正确的电子邮件地址',
  PASSWORD: '密码',
  PASSWORD_CONDITION: '密码要求至少1个小写字母，1个大写字母，1个数字以及6至10个字母数字（A-Z，a-z，0-9）。',
  CONFIRM_PASSWORD: '确认密码',
  CURRENCE: '货币',
  PHONE: '电话号码',
  PHONE_CONDITION: '请填写正确的电话号码（仅数字格式），以便在需要重设密码时更加轻松',
  BANK: '银行名称',
  BANK_NUMBER: '银行帐号',
  BANK_NUMBER_CONDITION: '请填写正确的银行帐号，以便在需要提款时更容易',
  BANK_ACCOUNT_NAME_CONDITION: '仅在银行存折中列出姓名和字符',
  TERM_18: '*我今年18岁以上，已阅读并接受本网站上发布的条款和条件，隐私权政策和投注规则。',
  LOGIN: '登录',
  MEMBER_LOGIN: '会员登录',
  FORGOT_PASSWORD: '忘记密码?',
  MOST_POPULAR_GAMES: '最受欢迎的游戏',
  MOST_POPULAR_GAMES_DESCRIPTION: '体验貴賓感觉！',
  VIDEO_GAME_BONUS_POOL: 'Newwin 超级幸运奖',
  PLAY_NOW: '现在播放',
  RECOMMEND_GAMES: '推荐游戏',
  WINNING_LIST_YESTERDAY: '昨天获奖名单',
  NUMBER_OF_BEST_PLACED: '下注数量',
  AMOUNT_PAID_OUT: '支付金额',
  MORE: '更多',
  CURRENT_BALANCE: '当前余额',
  PAYMENT_METHOD: '付款方法',
  FROM_BANK: '从银行',
  AMOUNT: '等于',
  RELOAD: '重新載入',
  FROM_DATE: '從日期',
  TO_DATE: '至今',
  START_THE_ROAD_TO_ADVANCEMENT: '開始前進的道路',
  ROAD_TO_ADVANCEMENT: '進步之路',
  START_UPDATE_EVERY_MONDAY: '每個星期一開始更新',
  MY_OFFER: '我的報價',
  RECENTLY_PLAYED: '最近播放',
  MY_COLLECTION: '我的收藏',

  MY_ACCOUNT: '我的帐户',
  YOUR_WALLET: '你的钱包',
  MY_WALLET: '我的钱包',
  BONUS: '奖金',
  QUICK_REGISTER: '快速开户',
  LATEST_OFFERS: '最新优惠',
  GAME: '游戏',
  GAMES: '游戏类',
  LIST: '目录',
  OTHER: '其他',
  AFFILIATE_AGENT: '会员代理',
  BET_THIS_MONTH: '这个月下注',
  WASHABLE_CODE_AMOUNT_OF: '洗码量',
  TO_WASH_CODE: '清洗代码',
  PLEASE: '请',
  TO_VIEW: '查看',
  NEWS: '消息',
  FORUM: '论坛',
  COPYRIGHT: '© 2018 97 IPAY 版权所有.',
  REWARDS: '奖赏',
  BASE_PROFILE: '基本资料',
  PROFILE: '资料',
  REFERAL_ID: '推荐人ID',
  EMAIL_ADDRESS: '电子邮件地址',
  MAIN_BALANCE: '主要余额',
  JOIN_DATE: '参加日期',
  LAST_EDIT: '最后编辑',
  SUBMIT_LABEL: '提交',
  RANKING: '排行',
  USER: '用户',
  DEPOSIT_TOTAL: '存款总额',
  BET_AMOUNT: '投注金额',
  WINNING_AMOUNT: '中奖金额',
  STATISTICS_WITHIN: '7天内统计',
  HIGH_EXPLOSION: '高流行',
  POPULAR: '流行',
  TOTAL_BET: '总投注',
  WIN_LOSE: '输赢',
  SEVEN_DAY_STATISTICS: '7天统计',

  DEPOSIT_ASSISTANT: '存款助理',
  CALL: '呼叫',
  DOWNLOAD_APP: '下载应用程式',

  PROMOTIONS_DESCRIPTION: '促销活动',
  SLOT_DESCRIPTION: '花式游戏',
  LIVE_CASINO_DESCRIPTION: '真人游戏',
  LOTTERY_DESCRIPTION: '彩票',
  SPORTBOOK_DESCRIPTION: '体育',
  FISHING: '釣魚',
  ONLINE_BANKING: '網上銀行業務',
  CREDIT_NOT_ENOUGH: '信用額度不足，請聯繫客服。',
  INCORRECT_USERNAME_PASSWORD: '用戶名或密碼錯誤',
  ERROR: '錯誤'
}