export default {
  baseUrl: process.env.REACT_APP_API_BASEURL,
  socketUrl: process.env.REACT_APP_SOCKET_BASEURL,
  market: process.env.REACT_APP_MARKET,
  lang: process.env.REACT_APP_LANG,
  gamesAvailable: process.env.REACT_APP_GAMES_AVAILABLE.split(","),
  gameHTML5: process.env.REACT_APP_GAMES_HTML5.split(","),
  agent: process.env.REACT_APP_AGENT,
  adminUrl: process.env.REACT_APP_ADMIN_URL,
  currency: process.env.REACT_APP_CURRENCY,
  fireBaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  fireBaseClientEmail: process.env.REACT_APP_FIREBASE_CILENT_EMAIL,
  fireBaseClientId: process.env.REACT_APP_FIREBASE_CILENT_ID,
  fireBaseDatabaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
}