import React from 'react'
import {
  TableCell,
  TableRow
} from '@material-ui/core'

const factory = {
  report: (
    <TableRow style={{color: '#ffaf50', fontWeight: 'bold'}}>
      <TableCell style={{color: '#ffaf50', fontWeight: 'bold', fontSize: '20px'}} align="center">Game</TableCell>
      <TableCell style={{color: '#ffaf50', fontWeight: 'bold', fontSize: '20px'}} align="center">Total Bet</TableCell>
      <TableCell style={{color: '#ffaf50', fontWeight: 'bold', fontSize: '20px'}} align="center">Win lose</TableCell>
    </TableRow>
  ),

}

export default factory