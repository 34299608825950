import React, { useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffaf50'
    },
  },
  typography: { useNextVariants: true },
})

const styles = {
  select: {
    "&:before": {
      borderColor: "#ffaf50"
    },
    label: {
      color: "#ffaf50"
    },
    color: "#ffaf50"
  },
  input: {
    '&:before': {
      borderColor: "#ffaf50",
    },
    '&:after': {
      borderColor: "#ffaf50",
    },
    color: "#ffaf50"
  }
}
const dataFake = [
  {
    title: "CIMB Bank",
    value: "CIMB Bank"
  },
  {
    title: "CIMB Bank 2",
    value: "CIMB Bank"
  }
]

const NewSelectInput = ({ options, classes, input: { input, meta }, label, ...rest }) => {
  const { t } = useTranslation()
  const [openGameSelector, setOpenGameSelector] = useState(false)
  const [data, setData] = useState([{}])
  function handleCloseGameSelector() {
    setOpenGameSelector(false)
  }

  function handleOpenGameSelector() {
    setOpenGameSelector(true)
  }
  // console.log("classes", classes)
  console.log("input", input)

  return (
    // <FormControl margin="normal" required fullWidth>
    //   <MuiThemeProvider theme={theme}>
    //     <InputLabel
    //       style={{ color: '#ffaf50' }}
    //       htmlFor="demo-controlled-open-select">{label}</InputLabel>
    //     <Select
    //       className={classes.select}
    //       open={openGameSelector}
    //       onClose={handleCloseGameSelector}
    //       onOpen={handleOpenGameSelector}
    //       {...input}
    //       {...rest}
    //       inputProps={{
    //         className: classes.input
    //       }}
    //     >
    //       {options.map(({title, value}) => <MenuItem key={title} value={value}>{t(title)}</MenuItem>)}
    //     </Select>
    //   </MuiThemeProvider>
    //   {meta.touched && meta.error && (
    //     <div style={{ color: '#dc3545', marginTop: 5 }}>{meta.error}</div>
    //   )}
    // </FormControl>
    <div className="input-select-new">
      <Select
        className={classes.select}
        open={openGameSelector}
        onClose={handleCloseGameSelector}
        onOpen={handleOpenGameSelector}
        {...input}
        {...rest}
        inputProps={{
          className: classes.input
        }}
      >
        {options.map(({ title, value }) => <MenuItem key={title} value={value}>{t(title)}</MenuItem>)}
      </Select>
    </div>
  )
}

export default withStyles(styles)(NewSelectInput)