export default [
  {
    label: 'King maker',
    code: 'sexy_baccarat',
    // src: 'images/gameLogo/games-kingmaker.png',
    src: './images/home/slotkingmaker.png',
    classshow: 'pages-icon-hot',
    route: 'awc/KINGMAKER/TABLE',
    idrActive: 0,
    id: 23
  },
  {
    label: 'Rich88',
    code: 'rich88',
    // src: 'images/gameLogo/16819162614882448910499172725626.png',
    src: './images/home/slotrich88.png',
    classshow: 'pages-icon-hot',
    route: '/rich88',
    idrActive: 0,
    id: 31
  },
  {
    src: './images/home/slotsg.png',
    classshow: 'pages-icon-hot',
    // src: '/images/gameLogo/slot-sg.png',
    code: 'sexy_baccarat',
    label: 'Spade Gaming',
    route: 'awc/SPADE/SLOT',
    id: 23
  },
  {
    src: './images/home/slotjbd.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/games-jdb.png',
    code: 'JDB',
    label: 'JDB',
    route: 'jdb',
    idrActive: 0,
    id: 25
  },
  {
    label: 'Creative Gaming',
    code: 'CG',
    src: './images/home/slotCG.png',
    classshow: 'pages-icon-hot-none',
    // src: 'images/icon/CG.png',
    route: '/creative-gaming?GameType=GameLobby_CGLobby_Test',
    idrActive: 0,
    id: 30
  },
  {
    src: './images/home/slotfachai.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/slot-fachai.png',
    code: 'sexy_baccarat',
    label: 'Fachai',
    route: 'awc/FC/SLOT',
    idrActive: 0,
    id: 23
  },

  {
    src: './images/home/slotjoker.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/slot-joker.png',
    code: 'joker',
    label: 'Joker',
    route: 'joker',
    id: 8,
    html5: 1,
    deeplink: true
  },
  {
    src: './images/home/slotpgv2.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/slot-pg-soft.png',
    code: 'sexy_baccarat',
    label: 'PGsoft',
    route: 'pg-slot',
    idrActive: 0,
    id: 23
  },
  {
    src: './images/home/slotpp.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/Jili.png',
    code: 'sexy_baccarat',
    label: 'JILI SLOT',
    route: 'jili-slot',
    idrActive: 0,
    id: 23
  },
  {
    src: './images/home/slotredtiger.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/slot-redtiger.png',
    code: 'sexy_baccarat',
    label: 'Red Tiger',
    route: 'awc/RT/SLOT',
    idrActive: 0,
    id: 23
  },
  // {
  //   src: '/images/gameLogo/slot-ae.png',
  //   code: 'sexy_baccarat',
  //   label: 'AE Gaming',
  //   route: 'aws-slot',
  //   idrActive: 0,
  //   id: 23
  // },
  {
    //   src: 'images/icon/kiss918 icon.png',
    //   code: '918kiss',
    //   label: '918 Kiss',
    //   route: 'lobbykissandroid://lobbykissandroid?account=',
    //   idrActive: 0,
    //   id: 2,
    //   deeplink: true,
    // }, {
    //   src: 'images/icon/megaicon.png',
    //   code: 'mega',
    //   label: 'Mega888',
    //   route: '/',
    //   id: 11,
    //   deeplink: true
    // },
    // {
    src: './images/home/slotace333.png',
    classshow: 'pages-icon-hot-none',
    // src: '/images/gameLogo/ACE333.png',
    code: 'ace333',
    label: 'ace333',
    route: '/ace333',
    id: 1,
    html5: 1
    // },{
    //   src: '/img/m8.png',
    //   code: 'm8',
    //   label: 'M8',
    //   route: '/m8',
    //   id: 9
  }
]
