import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import { Divider } from '@mui/material'
import './Styles.css'

const data = [
  {
    image: './images/home/minibanners1.png'
  },
  {
    image: './images/home/minibanners2.png'
  },
  {
    image: './images/home/minibanners3.png'
  },
  {
    image: './images/home/minibanners4.png'
  },
  {
    image: './images/home/minibanners1.png'
  },
  {
    image: './images/home/minibanners2.png'
  },
  {
    image: './images/home/minibanners3.png'
  },
  {
    image: './images/home/minibanners4.png'
  }
]

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  adaptiveHeight: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1124,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const Head = ({ image }) => {
  return (
    <div style={{ width: '100%' }}>
      <div>
        <img src={image} alt="banner" />
      </div>
      <div className="head-slideshow-wrapper">
        <Slider className="head-styled-slider" {...settings}>
          {data.map((i, ix) => (
            <div key={ix}>
              <img
                className="head-slideshow-imgslide"
                src={i.image}
                alt={i.alt}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '30px',
          justifyContent: 'space-between'
        }}
      >
        <div className="head-slideshow-styled-text">
          <div className="head-slideshow-text-new">News</div>
          <p className="head-slideshow-text">
            Try our new Aviator game on Spribe and take control of ypur
            multiplire up to 1,000,000x! Start flying now!
          </p>
        </div>
        <p className="head-slideshow-text-more">More</p>
      </div>
    </div>
  )
}
export default Head
