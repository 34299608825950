/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import numeral from 'numeral'

function Histories({ classes, history }) {

  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [games, updateGame] = useState([])
  const [histories, updateHistories] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [selectedType, updateSelectedType] = React.useState(null)
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Histories Warning',
    btnLabel: 'Got it'
  })
  useEffect(() => {
    http.get({ path: `games` }).then(rs => {
      const gamess = rs.map(r => {
        return {
          value: r.game_code,
          title: r.name
        }
      })
      // console.log(gamess)
      gamess.unshift({
        value: 'all',
        title: 'All games'
      })
      updateGame(gamess)
    })
  }, [])
  const onSubmit = async ({ type, fromDate, toDate }) => {
    try {
      const params = {
        fromDate: moment(fromDate).format('YYYY-MM-DD 00:00:00'),
        toDate: moment(toDate).format('YYYY-MM-DD 23:59:59'),
        game: type
      }
      // console.log(params)
      const response = await withLoading(() =>
        http.get({ path: `admin/report/transaction-history`, params })
      )
      updateSelectedType(type)
      updateHistories(response)
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    type: Joi.string()
      .label('Game')
      .required(),
    fromDate: Joi.date()
      .label('From Date')
      .required(),
    toDate: Joi.date()
      .label('To Date')
      .required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      type: 'all',
    },
  })

  const [type, fromDate, toDate] = formCreateInputs(['type', 'fromDate', 'toDate'], form)

  return (
    <div className="col8">
      <div className="title_recoment tile_deposit">{t('TRANSACTION_HISTORY')}</div>
      <div className="reportW">
        {/*<h3 className="rs h3_deposit">Deposit Report</h3>*/}
        <div className="reportMain">
          <form onSubmit={handleSubmit}>
            <div className="report-search display_flex">
              <div style={{ justifyContent: 'unset' }} className="form-report display_flex">
                <label for="">{t('SELECT_GAME')}</label>
                <div className="react-datepicker-wrapper">
                  <div className="react-datepicker__input-container">
                    <select
                      name=""
                      className="input_block"
                      style={{ padding: '0px 10px', color: 'black', background: 'white', height: '30px' }}
                      {...type.input}
                    >
                      {games.map(({ title, value }) => (
                        <option style={{ color: 'black' }} value={value}>{title}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                <label for="">{t('FROM_DATE')}</label>
                {/* <input type="text" className="input_block" /> */}
                <DatePicker
                  // {...fromDate.input}
                  onChange={(date) => fromDate.input.onChange({ target: { value: date } })}
                  selected={fromDate.input.value}
                // selected={this.state.startDate}
                // onChange={this.handleChange}
                />
              </div>
              <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                <label for="">{t('TO_DATE')}</label>
                {/* <input type="text" className="input_block" /> */}
                <DatePicker
                  // {...toDate.input}
                  onChange={(date) => toDate.input.onChange({ target: { value: date } })}
                  selected={toDate.input.value}
                // selected={this.state.startDate}
                // onChange={this.handleChange}
                />
              </div>
              <div className="report-submit">
                <button disabled={submitting} type="submit" className="reload"><span></span>{t('REPORTS')}</button>
              </div>
            </div>
            <div className="reportTable">
              <div className="table-responsive">
                <table className="rs table table-report">
                  <tr>
                    <td>{t('GAME')}</td>
                    <td>{t('TOTAL_BET')}</td>
                    <td>{t('WIN_LOSE')}</td>
                    <td>{t('AMOUNT')}</td>
                    <td>{t('BEFORE')}</td>
                    <td>{t('AFTER')}</td>
                    <td>{t('TYPE')}</td>
                  </tr>
                  {loading ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
                    >
                      <PropagateLoader
                        sizeUnit="px"
                        size={20}
                        color="#f50057"
                        loading={loading}
                      />
                    </div>
                  ) : (
                      <>
                        {histories.rabats &&
                          histories.rabats.map(rabat => {
                            return <tr>
                              <td>{rabat.gameName}</td>
                              <td>{numeral(rabat.betAmount).format('0,0.000')}</td>
                              <td>{numeral(rabat.winlose).format('0,0.000')}</td>
                              <td>{numeral(rabat.amountRabat).format('0,0.000')}</td>
                              <td>{numeral(rabat.before).format('0,0.000')}</td>
                              <td>{numeral(rabat.after).format('0,0.000')}</td>
                              <td>{'Rabat'}</td>
                            </tr>
                          })

                        }
                        {histories.withdraws &&
                        histories.withdraws.map(rabat => {
                          return <tr>
                            <td>{rabat.gameName}</td>
                            <td></td>
                            <td></td>
                            <td>{numeral(rabat.amount).format('0,0.000')}</td>
                            <td>{numeral(rabat.before).format('0,0.000')}</td>
                            <td>{numeral(rabat.after).format('0,0.000')}</td>
                            <td>{'Withdraw'}</td>
                          </tr>
                        })

                        }
                        {histories.deposits &&
                        histories.deposits.map(rabat => {
                          return <tr>
                            <td>{rabat.gameName}</td>
                            <td></td>
                            <td></td>
                            <td>{numeral(rabat.amount).format('0,0.000')}</td>
                            <td>{numeral(rabat.before).format('0,0.000')}</td>
                            <td>{numeral(rabat.after).format('0,0.000')}</td>
                            <td>{'Deposit'}</td>
                          </tr>
                        })

                        }
                        {histories.transfers &&
                        histories.transfers.map(rabat => {
                          return <tr>
                            <td>{rabat.origin}</td>
                            <td></td>
                            <td></td>
                            <td>{numeral(rabat.amount).format('0,0.000')}</td>
                            <td>{rabat.origin}</td>
                            <td>{rabat.target}</td>
                            <td>{'Login Game'}</td>
                          </tr>
                        })

                        }
                      </>
                    )}
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog />
    </div>
  )
}

export default Histories
