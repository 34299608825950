/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import queryString from 'query-string'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import config from 'config'
import { useTranslation } from 'react-i18next'
import TextInput from 'component/textInput'
import LoginDialog from '../../component/layout/Login'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'


function Login({ classes, history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [step, updateStep] = useState(1)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Register Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const toLogin = () => history.push('login')
  const onSubmit = async ({ password, phone }) => {
    try {
      const { location: { search } } = history
      const { parent, user_level: userLevel } = queryString.parse(search)

      return
      const payload = {
        password,
        username: phone,
        mobile_phone: phone,
        user_account_no: phone,
        parent_id: parent || config.agent,
        user_level: userLevel || 'PLAYER'
      }
      const { id_token: token, user } = await withLoading(() =>
        http.post({ path: 'users', payload })
      )

      http.setJwtToken(token)
      store.set('token', token)
      store.set('user', user)
      if (payload.user_level === 'PLAYER') {
        return history.push('home')
      }
      window.location = config.adminUrl
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    phone: Joi.string().required(),
    password: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/).required(),
    confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [phone, password, confirmPassword] = formCreateInputs(['phone', 'password', 'confirmPassword'], form)

  useEffect(() => {
    if (history.location.search === '?step=2') {
      updateStep(2)
    }
  }, [history.location.search])

  return (
      <LoginDialog
        tabActive="register"
        onClose={history.goBack}
      />
  );
}

export default Login
