import Joi from '@hapi/joi'

const schema = Joi.object().keys({
  toBank: Joi.any()
    .label('Bank Name')
    .required(),
  amount: Joi.number()
    .min(10)
    .required(),
  bonus: Joi.any()
})

export default schema