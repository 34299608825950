/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'

const { useDialog, useLoading, Header } = component

function Allbet({ history }) {
  const [Dialog, showDialog] = useDialog({
    title: 'New Win'
  })
  const [newWinLoginUrl, updateNewWinLoginUrl] = React.useState(null)
  const [loading, withLoading] = useLoading()
  const login = async () => {
    try {
      const { link, mobileLink } = await withLoading(() => http.post({ path: 'api/authenticate/lotto' }))

      if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
      ){
        updateNewWinLoginUrl(mobileLink)
      }
      else {
        updateNewWinLoginUrl(link)
      }
    } catch (error) {
      showDialog(error.message)
    }
  }

  React.useEffect(() => {
    // const [jdivs] = document.getElementsByClassName('globalClass_ET')
    // jdivs.classList.add('hide')
    login()
    // return function cleanup() {
    //   jdivs.classList.remove('hide')
    // }
  }, [])

  return (
    <div className="col8">

      {loading || !newWinLoginUrl ? (
        <div>
          {/* <Header history={history} title='Newwin' /> */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }} >
            <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading} />
          </div>
        </div>
      ) : <div className="play-game full-screen">
          <iframe src={newWinLoginUrl} />
        </div>}
      <Dialog />
    </div>
  )
}

export default Allbet