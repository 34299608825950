/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
// import socket from 'service/socket'
import http from 'service/http'
import CurrentBalance from 'component/CurrentBalance'
import { PropagateLoader } from 'react-spinners'
import { Done, Clear } from '@material-ui/icons'
import { Badge } from 'reactstrap'
import { Dialog, DialogActions, DialogContent, Button, DialogTitle, Fab, TextField } from '@material-ui/core'
import store from 'store'
import DatePicker from "react-datepicker"
import moment from 'moment'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'


function Withdraw({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [data, setData] = useState([])
  const [dataAccept, setDataAccept] = useState({
    pin: null,
    id: null
  })
  const [openPin, setOpenPin] = useState(false)
  const [search, setSearch] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  })
  const { user_id: userId, user_account_no: username } = store.get('user')
  const [DialogM, showDialogWithMessage] = useDialog({
    title: t('TRANSFER_SUC')
  })

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t('TRANSFER_ERR'),
    btnLabel: t('GOT_IT'),
    type: 'error'
  })
  const showDialogWithMessagePin = (id) => {
    setOpenPin(true)
    setDataAccept({...dataAccept, id})
  }
  const initData = async () => {
    const params = {
      fromDate: moment(search.fromDate).format('YYYY-MM-DD 00:00:00'),
      toDate: moment(search.toDate).format('YYYY-MM-DD 23:59:59')
    }
    // console.log(params)
    const searchData = await withLoading(() =>
      http.get({ path: `users/${userId}/transfer`, params })
    )
    setData(searchData)
  }
  const accept = async () => {
    setOpenPin(false)
    try {
      const submited = {
        pin: dataAccept.pin,
        status: 'accept',
      }
      await withLoading(() =>
        http.put({ path: `users/${userId}/transfer/${dataAccept.id}`, payload: submited })
      )
      showDialogWithMessage(t('WITH_DONE'))
      initData()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const onSubmit = async payload => {
    try {

      const submited = {
        amount: payload.amount,
        receiver: payload.receiver,
        pin: payload.pin
      }
      await withLoading(() =>
        http.post({ path: `users/${userId}/transfer`, payload: submited })
      )
      // socket.emit('messages', {
      //   msgType: 'transfer.request',
      //   data: {
      //     message: `Request transfer '${payload.amount}' from '${username}' to '${payload.receiver}'`,
      //     title: 'Transfer Request'
      //   }
      // })
      showDialogWithMessage(t('WITH_DONE'))
      initData()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    receiver: Joi.string().required(),
    pin: Joi.number().required(),
    amount: Joi.number()
    // .min(10)
      .required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const updateStatus = async (id, pin, status) => {
    await http.put({ path: `users/${userId}/transfer/${id}`, payload: { status, pin } })
    initData()
  }
  React.useEffect(() => {
    initData()
  }, [])
  const [receiver, amount, pin] = formCreateInputs(['receiver', 'amount', 'pin'], form)

  return (
    <div className="col8">
      <div className="title_recoment tile_deposit">{t('TRANSFER')}</div>
      <div className="depositW display_flex">
        <div className="deposit-left">
          <h3 className="rs h3_deposit">{t('CURRENT_BALANCE')}</h3>
          <CurrentBalance />
        </div>
        <div className="deposit-right">
          <h3 className="rs h3_deposit">{t('TRANSFER')}</h3>
          <div className="deposit-right-main" style={{marginTop: '20px'}}>
            <form onSubmit={handleSubmit}>
              <div className="formGroup display_flex">
                <label for="">{t('RECEIVER')}</label>
                <div className="inputW">
                  <input
                    type="text"
                    className="input_block"
                    {...receiver.input}
                  />
                </div>
              </div>

              <div className="formGroup display_flex">
                <label for="">{t('TRANSFER_AMOUNT')}</label>
                <div className="inputW">
                  <input
                    type="text"
                    className="input_block"
                    {...amount.input}
                  />
                </div>
              </div>

              <div className="formGroup display_flex">
                <label for="">{t('PIN')}</label>
                <div className="inputW">
                  <input
                    type="text"
                    className="input_block"
                    {...pin.input}
                  />
                </div>
              </div>

              {loading ? (
                <div
                  style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
                >
                  <PropagateLoader
                    sizeUnit="px"
                    size={20}
                    color="#f50057"
                    loading={loading}
                  />
                </div>
              ) : (
                <div className="text-center formGroup_btn">
                  <button
                    disabled={submitting}
                    type="submit"
                    className="reload"
                    style={{ cursor: 'pointer' }}
                  >
                    <span></span>{t('TRANSFER_NOW')}
                  </button>
                </div>
              )}
            </form>
          </div>

        </div>
      </div>
      <div className="title_recoment tile_deposit">{t('TRANSFER')}</div>
      <div className="reportW">
        <div className="reportMain">
            <div className="report-search display_flex">

              <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                <label htmlFor="">{t('FROM_DATE')}</label>
                <DatePicker
                  onChange={(date) => setSearch({...search, fromDate: date})}
                  selected={search.fromDate}
                />
              </div>
              <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                <label htmlFor="">{t('TO_DATE')}</label>
                <DatePicker
                  onChange={(date) => setSearch({...search, toDate: date})}
                  selected={search.toDate}
                />
              </div>
              <div className="report-submit">
                <button onClick={initData} className="reload btn"><span></span>{t('REPORTS')}</button>
              </div>
            </div>
            <div className="reportTable">
              <div className="table-responsive">
                <table className="rs table table-report">
                  <tr>
                    <td>{t('RECEIVER')}</td>
                    <td>{t('AMOUNT')}</td>
                    <td>{t('PIN')}</td>
                    <td>{t('STATUS')}</td>
                    <td>{t('ACTION')}</td>
                  </tr>
                  {loading ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
                    >
                      <PropagateLoader
                        sizeUnit="px"
                        size={20}
                        color="#f50057"
                        loading={loading}
                      />
                    </div>
                  ) : (
                    <>
                      { data.map(da => (
                        <tr>
                          <td>{da.receiver}</td>
                          <td>{da.amount}</td>
                          <td>{da.pin}</td>
                          <td>
                            {da.status === 'waiting' && <Badge color="warning">{da.status}</Badge>}
                            {da.status === 'reject' && <Badge color="danger">{da.status}</Badge>}
                            {da.status === 'accepted' && <Badge color="success">{da.status}</Badge>}
                          </td>
                          <td>
                            { da.status === 'waiting' && da.username === username && <Fab style={{ height: 25, width: 25, minHeight: 25 }} color="secondary" title="reject" aria-label="Edit"
                                 onClick={() => updateStatus(da.id, da.pin,'reject')}
                            >
                              <Clear />
                            </Fab> }
                            { da.status === 'waiting' && da.receiver === username && <Fab style={{ height: 25, width: 25, minHeight: 25, background: 'green' }} color="inherit" title="accept" aria-label="Edit"
                                 onClick={() => showDialogWithMessagePin(da.id)}
                            >
                              <Done />
                            </Fab> }
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </table>
              </div>

            </div>
        </div>
      </div>
      <DialogM/>
      <Dialog
        open={openPin}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={'success'} id="responsive-dialog-title">{t('PIN')}</DialogTitle>
        <DialogContent>
          <TextField id="standard-basic" label="Pin" onChange={(e) => setDataAccept({...dataAccept, pin: e.target.value})} value={dataAccept.pin}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={accept} color="primary" autoFocus>{t('ACCEPT')}</Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog />
    </div>
  )
}

export default Withdraw
