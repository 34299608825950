/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import {
  CssBaseline,
  GridList,
  GridListTile,
  ListSubheader,
  GridListTileBar
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import { withStyles } from '@material-ui/core/styles'
import store from 'store'
import http from 'service/http'
import component from 'component'
import styles from './style'

const { useDialog, Header, useLoading } = component

function Login({ classes, history, match: { params: { code } } }) {
  const [loading, withLoading] = useLoading(false)
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const [GAMES, setGAMES] = React.useState(null)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Error',
    btnLabel: 'Got it',
    type: 'error'
  })

  const initialPT = async () => {

    try {
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 23)) {
        let Platform = 'D'
        if( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        ){
          Platform = 'M'
        }
        const { url } = await http.get({ path: `games/23/login`, params: {isLobby: true, Platform, GameType: 'FH', PlatformGame: 'JILI'} })
        return updateLoginUrl(url)
      }
      return showDialogErrorWithMessage('JDB table is comming')
    } catch (error) {
      console.log(error)
      return showDialogErrorWithMessage(error.message)
    }
  }
  const checkWith = () => {
    let width = 2
    const e = document.getElementById('root')
    if(e.clientWidth > 576) width = 3
    if(e.clientWidth > 720) width = 4
    if(e.clientWidth > 960) width = 5
    if(e.clientWidth > 1140) width = 6
    return width
  }
  useEffect(() => {
    initialPT()
  }, [])

  return (
    <div style={{padding: 20}} className={`col-12 col-md-12 col-sm-4 ${!loginUrl && !code ? classes.main : classes.main1}`}>
      <CssBaseline />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : !loginUrl && !code ? (<GridList  cols={checkWith()} className={classes.gridList}>
          {GAMES && GAMES.map(({ GameCode, GameName, img }) => {


            return (
              <GridListTile style={{height: 210}} onClick={() => history.push(`/authenticate/pg-slot/${GameCode}`)} key={GameCode}>
                <img style={{ width: '100%', height: '90%', borderRadius: 10, cursor: 'pointer' }} src={`/images/sexy/${img}`} alt={GameName} />
                <GridListTileBar
                  style={{display: 'block'}}
                  title={GameName}
                />
              </GridListTile>
            )
          })}
        </GridList>)
        : <div className="play-game full-screen">
          <iframe src={loginUrl} />
        </div>}
      <ErrorDialog />
    </div>
  )
}

export default withStyles(styles)(Login)
