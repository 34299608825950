import React from 'react'
import { withRouter } from 'react-router'
import './Styles.css'

const listMenu = [
  {
    title: 'My Profile',
    link: '/account'
  },
  {
    title: 'Deposit',
    link: 'deposit'
  },
  {
    title: 'Withdraw',
    link: '/withdraw'
  },
  {
    title: 'History',
    link: '/histories'
  }
]

const MenuItem = ({ history}) => {
  return (
    <div className="styled-menu-item-wrapper">
      <div className="styled-menu-item-list-item">
        {listMenu.map((i, ix) => (
          <div style={{cursor: "pointer"}} key={ix} onClick={() => history.push(`${i.link}`)}>
            {i.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default withRouter(MenuItem)
