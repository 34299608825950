export default {
    LANGS: 'ဘာသာစကား',
    HOME: 'ပင်မစာမျက်နှာ',
    SPORTS: 'ရုပ်ပိုင်းဆိုင်ရာပညာရေး',
    POKER: 'Poker',
    VIRTUAL_SPORTS: 'Virtual အားကစား',
    LIVE_CASINO: 'တိုက်ရိုက်ကာစီနို',
    SLOTS: 'အထိုင်',
    LOTTERY: 'ထီလက်မှတ်',
    PROMOTIONS: 'ပရိုမိုးရှင်းများ',
    CONTACT_US: 'ဆက်သွယ်ရန်',
    LIVE_CHAT: 'တိုက်ရိုက်စကားပြောခန်း',
    WECHAT: 'WeChat',
    BANKING: 'ဘဏ်',
    ABOUT_US: 'ကျွန်ုပ်တို့အကြောင်း',
    RESPONSIBLE_GAMING: 'တာဝန်ရှိဂိမ်း',
    TERMS_OF_USE: 'အသုံးပြုမှုဆိုင်ရာသတ်မှတ်ချက်များ',
    TRUSTED_PAYMENT: 'ယုံကြည်ရသောငွေပေးချေမှု',
    TRANSFER: 'လွှဲပြောင်းခြင်း',
    TOPUP: 'အားသွင်းပါ',
    WITHDRAW: 'ထုတ်ယူခြင်း',
    ACCOUNT: 'အကောင့်',
    HISTORIES: 'သမိုင်း',
    REPORTS: 'အစီရင်ခံစာ',
    DOWNLOAD: 'ဒေါင်းလုပ်',
    LOGOUT: 'ထွက်ပေါက်',
    EDIT_PROFILE: 'ပရိုဖိုင်းကိုတည်းဖြတ်ပါ',
    CHANGE_PASSWORD: 'စကားဝှက်ကိုပြောင်းပါ',

    // FORM AREA
    BANK_NAME: 'ကျေးဇူးပြု၍ ဘဏ်နာမည်ဖြည့်ပါ။',
    BANK_ACCOUNT_NAME: 'ကျေးဇူးပြု၍ အကောင့်နာမည်ပေးပါ',
    BANK_ACCOUNT_NO: 'ကျေးဇူးပြုပြီးသင့်ဘဏ်အကောင့်နံပါတ်ထည့်ပါ',
    WITHDRAW_AMOUNT: 'ငွေထုတ်ယူပါ',
    WITHDRAW_NOW: 'ငွေပမာဏကိုယခုထုတ်ယူပါ',
    SELECT_BANK: 'ဘဏ်ရွေးပါ',
    TOPUP_AMOUNT: 'ကျေးဇူးပြုပြီးငွေထည့်ပါ',
    AGREEMENT: 'စည်းကမ်းချက်များကိုကျွန်ုပ်သဘောတူပါသည်',
    ONLINE_TOP_UP_NOW: 'ယခုအွန်လိုင်းငွေသွင်းပါ',
    DEPOSIT_WITH_RECEIPT: 'လက်ခံဖြတ်ပိုင်းအားဖြင့်အပ်နှံ',
    DEPOSIT_AMOUNT: 'ငွေပမာဏကိုထည့်ပါ',
    DEPSOSIT_BONUS: 'အပ်ငွေအပိုဆုတောင်းခံပါ',
    TRANSFER_IN: 'သို့လွှဲပြောင်း',
    TRANSFER_OUT: 'လွှဲပြောင်းပါ',
    TYPE_TRANS: 'ငွေပေးငွေယူအမျိုးအစားကိုရွေးချယ်ပါ',
    SUBMIT: 'ယခုတင်သွင်းပါ',

    LOADING: 'တင်နေသည်',
    FROM: 'နေ့စွဲမှစ',
    TO: 'ယနေ့အထိ',
    TRANS_SUC: 'လွှဲပြောင်းအောင်မြင်စွာ',
    TRANS_ERR: 'ဂီယာအမှား',
    TRANS_DONE: 'လွှဲပြောင်းမှုအောင်မြင်စွာပြီးဆုံး',
    GOT_IT: 'ပြီးပြီ',
    SELECT_GAME: 'ဂိမ်းတစ်ခုရွေးပါ',
    CLAIM_LOYALTY_BONUS: 'သစ္စာစောင့်သိအပိုဆုတောင်းဆို',
    DEPO_SUC: 'အပ်ငွေအောင်မြင်ပါသည်',
    DEPO_ERR: 'အပ်ငွေအမှား',
    DEPO_DONE: 'သင်၏တောင်းဆိုမှုကိုအောင်မြင်စွာတင်သွင်းပြီးပါပြီ',
    DEPOSIT: 'အပ်ငွေ',
    FILE: 'ဖိုင်',
    WITH_SUC: 'ငွေပမာဏကိုအောင်မြင်စွာထုတ်ယူပါ',
    WITH_ERR: 'ထုတ်ယူမှုအမှား',
    WITH_DONE: 'သင်၏တောင်းဆိုမှုကိုအောင်မြင်စွာတင်သွင်းပြီးပါပြီ',
    CLICK_DOWNLOAD: 'ဒေါင်းလုပ်လုပ်ရန်ကိုနှိပ်ပါ',
    ENTER_MOBILE: 'ဖုန်းနံပါတ်ထည့်ပါ',
    ENTER_PASS: 'စကားဝှက်ကိုရိုက်ထည့်ပါ',
    SIGN_IN: 'ဆိုင်းအင်လုပ်ပါ',
    REGISTER: 'မှတ်ပုံတင်မယ်',
    ENTER_DISPLAY_NAME: 'သင်၏ရိုက်ထည့်ပါပြသနာအမည်',
    ENTER_EMAIL: 'သင်၏အဓိကအီးမေးလ်ကိုရိုက်ထည့်ပါ',
    ENTER_AGENT: 'သင်၏ proxy အီးမေးလ်ကိုရိုက်ထည့်ပါ။',
    RETYPE_PASS: 'လျှို့ဝှက်နံပါတ်ကိုရိုက်ထည့်ပါ',
    BACK_TO_LOGIN: 'သို့ပြန်သွားရန်',
    PULL_OUT: 'ပိုက်ဆံအိတ်မှအကြွေးလွှဲပြောင်း',
    RESTORE: 'Restore',
    AG_FISHING_KING: 'AG ကငါးဖမ်းဘုရင်',
    NOTICE_LABEL: 'ကြေငြာချက်',
    NOTICE: 'တရုတ်စိုက်ပျိုးရေးဘဏ်သည်ဘဏ်စနစ်ကိုအကောင်အထည်ဖော်ရန်',

    MEMBER_REGISTER: 'အသင်းဝင်မှတ်ပုံတင်ခြင်း',
    YOUR_ACCOUNT_CONTACT_DETAIL: 'သင်၏အကောင့်နှင့်ဆက်သွယ်ရန်အချက်အလက်',
    USERNAME: 'အသုံးပြုသူအမည်',
    USERNAME_CONDITION: 'အက္ခရာ ၃ မှ ၉ အထိသာအက္ခရာနံပါတ် (a-z, 0-9)',
    EMAIL: 'အီးမေးလ်လိပ်စာ',
    EMAIL_CONDITION: 'စကားဝှက်ကိုပြန်လည်ရယူရန်အတွက်မှန်ကန်သောအီးမေးလ်လိပ်စာလိုအပ်သည်',
    PASSWORD: 'စကားဝှက်',
    PASSWORD_CONDITION: 'စကားဝှက်သည်အနည်းဆုံးစာလုံးအသေး ၁ လုံး၊ စာလုံးကြီး ၁ လုံး၊ ဂဏန်း ၁ လုံးနှင့်အက္ခရာနံပါတ် ၆ မှ ၁၀ လုံးလိုအပ်သည် (A-Z, a-z, 0-9) ။',
    CONFIRM_PASSWORD: 'စကားဝှက်ကိုအတည်ပြုပါ',
    CURRENCE: 'ငွေကြေး',
    PHONE: 'ဖုန်းနံပါတ်',
    PHONE_CONDITION: 'သင်၏စကားဝှက်ကိုလွယ်ကူစွာပြန်ဖွင့်နိုင်ရန်မှန်ကန်သောဖုန်းနံပါတ် (နံပါတ်ပုံစံသာ) ကိုဖြည့်ပါ',
    BANK: 'ဘဏ်အမည်',
    BANK_NUMBER: 'ဘဏ်အကောင့်',
    BANK_NUMBER_CONDITION: 'သင်ငွေထုတ်ရန်လိုအပ်သည့်အခါမှန်ကန်သောဘဏ်အကောင့်နံပါတ်ကိုဖြည့်ပါ',
    BANK_ACCOUNT_NAME_CONDITION: 'အမည်များနှင့်အက္ခရာများကိုဘဏ်စာရင်းများတွင်သာစာရင်းပြုစုပါ',
    TERM_18: '* ကျွန်ုပ်သည်အသက် ၁၈ နှစ်ကျော်ပြီး ဖြစ်၍ ဤ ၀ က်ဘ်ဆိုက်တွင်ဖော်ပြထားသောစည်းကမ်းချက်များ၊ အခြေအနေများ၊ သီးသန့်တည်ရှိမှုမူဝါဒနှင့်လောင်းကစားခြင်းစည်းမျဉ်းများကိုဖတ်ပြီးလက်ခံခဲ့သည်။',
    LOGIN: 'ဆိုင်းအင်လုပ်ပါ',
    MEMBER_LOGIN: 'အသင်းဝင်',
    FORGOT_PASSWORD: 'သင်၏စကားဝှက်ကိုမေ့နေပါသလား?',
    MOST_POPULAR_GAMES: 'လူကြိုက်အများဆုံးဂိမ်းများ',
    MOST_POPULAR_GAMES_DESCRIPTION: 'High-end အတွေ့အကြုံသည်စစ်မှန်သော VIP ၏အသွင်အပြင်နှင့်ခံစားမှုကိုဆောင်ကြဉ်းပေးသည်။',
    VIDEO_GAME_BONUS_POOL: 'Newwin ေလာင္းေၾကးေငြအပံု',
    PLAY_NOW: 'အခုကစားပါ',
    RECOMMEND_GAMES: 'အကြံပြုဂိမ်းများ',
    WINNING_LIST_YESTERDAY: 'မနေ့ကဆုရရှိသူစာရင်း',
    NUMBER_OF_BEST_PLACED: 'အလောင်းအစားအရေအတွက်',
    AMOUNT_PAID_OUT: 'ငွေပေးချေမှုပမာဏ',
    MORE: 'နောက်ထပ်',
    CURRENT_BALANCE: 'လက်ကျန်ငွေ',
    PAYMENT_METHOD: 'ငွေပေးချေမှုနည်းလမ်း',
    FROM_BANK: 'ဘဏ်ထဲက',
    AMOUNT: 'ညီမျှသည်',
    RELOAD: 'ပြန်တင်ပါ',
    FROM_DATE: 'နေ့စွဲမှစ',
    TO_DATE: 'အခု',
    START_THE_ROAD_TO_ADVANCEMENT: 'စတင်ရန်နည်းလမ်း',
    ROAD_TO_ADVANCEMENT: 'တိုးတက်မှုသို့လမ်း',
    START_UPDATE_EVERY_MONDAY: 'တနင်္လာနေ့တိုင်း နောက်ဆုံးသတင်း လုပ်ပါ',
    MY_OFFER: 'ငါ့ကမ်းလှမ်းချက်',
    RECENTLY_PLAYED: 'မကြာသေးမီကကစားခဲ့သည်',
    MY_COLLECTION: 'ကျွန်တော့်စုဆောင်းမှု',

    MY_ACCOUNT: 'ကျွန်ုပ်အကောင့်',
    YOUR_WALLET: 'မင်းပိုက်ဆံအိတ်',
    MY_WALLET: 'ငါ့ပိုက်ဆံအိတ်',
    BONUS: 'အပိုဆု',
    QUICK_REGISTER: 'လျင်မြန်စွာအကောင့်ဖွင့်လှစ်',
    LATEST_OFFERS: 'နောက်ဆုံးကမ်းလှမ်းမှုများ',
    GAME: 'ဂိမ်း',
    GAMES: 'ဂိမ်းများ',
    LIST: 'လမ်းညွှန်',
    OTHER: 'အခြား',
    AFFILIATE_AGENT: 'အသင်း ၀ င်ကိုယ်စားလှယ်',
    BET_THIS_MONTH: 'ဒီလအတွက် ဒါပေမယ့်',
    WASHABLE_CODE_AMOUNT_OF: 'အဝတ်လျှော်ငွေပမာဏ',
    TO_WASH_CODE: 'သန့်ရှင်းရေးကုဒ်',
    PLEASE: 'ကျေးဇူးပြု၍',
    TO_VIEW: 'ကြည့်ရှုပါ',
    NEWS: 'သတင်း',
    FORUM: 'ရန်',
    COPYRIGHT: '© 2018 97 IPAY All rights reserved.',
    REWARDS: 'ဆုလာဘ်',
    BASE_PROFILE: 'အခြေခံအချက်အလက်',
    PROFILE: 'သတင်းအချက်အလက်',
    REFERAL_ID: 'ရည်ညွှန်း ID',
    EMAIL_ADDRESS: 'အီးမေးလ်လိပ်စာ',
    MAIN_BALANCE: 'အဓိကချိန်ခွင်လျှာ',
    JOIN_DATE: 'ပါဝင်သည့်နေ့စွဲ',
    LAST_EDIT: 'နောက်ဆုံးတည်းဖြတ်မှု',
    SUBMIT_LABEL: 'တင်သွင်းပါ',
    RANKING: 'အဆင့်',
    USER: 'အသုံးပြုသူ',
    DEPOSIT_TOTAL: 'စုစုပေါင်းအပ်ငွေ',
    BET_AMOUNT: 'အလောင်းအစားပမာဏ',
    WINNING_AMOUNT: 'အနိုင်ရရှိငွေပမာဏ',
    STATISTICS_WITHIN: '၇ ရက်အတွင်းစာရင်းအင်းများ',
    HIGH_EXPLOSION: 'ရေပန်းစားသည်',
    POPULAR: 'လူကြိုက်များ',
    TOTAL_BET: 'စုစုပေါင်းအလောင်းအစား',
    WIN_LOSE: 'အနိုင်ရသို့မဟုတ်ရှုံး',
    SEVEN_DAY_STATISTICS: '၇ ရက်ကြာစာရင်းဇယားများ',

    DEPOSIT_ASSISTANT: 'အပ်ငွေအထောက်အပံ့',
    CALL: 'ခေါ်ပါ',
    DOWNLOAD_APP: 'App ကိုဒေါင်းလုပ်ဆွဲပါ',

    PROMOTIONS_DESCRIPTION: 'မြှင့်တင်ရေး',
    SLOT_DESCRIPTION: 'slot ကဂိမ်းမျာ',
    LIVE_CASINO_DESCRIPTION: 'တိုက်ရိုက်ကာစီနို',
    LOTTERY_DESCRIPTION: 'ထေရဝါဒ',
    SPORTBOOK_DESCRIPTION: 'ဘောလုံး',
    FISHING: 'ငါးဖမ်းခြင်း',
    ONLINE_BANKING: 'အွန်လိုင်းဘဏ်လုပ်ငန်း',
    CREDIT_NOT_ENOUGH: 'မလုံလောက်ပါက ကျေးဇူးပြု၍ ဖောက်သည်ဝန်ဆောင်မှုကိုဆက်သွယ်ပါ။',
    INCORRECT_USERNAME_PASSWORD: 'အသုံးပြုသူအမည်သို့မဟုတ်စကားဝှက်မမှန်ပါ',
    ERROR: 'အမှား'
}