const styles = () => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: 'none'
  },
})

export default styles