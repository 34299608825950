/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import config from 'config'
import Joi from '@hapi/joi'
// import socket from 'service/socket'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import CurrentBalance from 'component/CurrentBalance'
import { Checkbox, Button, Form, Input, Select } from 'antd'
import numeral from 'numeral'
import InputField from '../../component/textInput/InputField'
import MenuItem from '../../component/layout/MenuItem'
import BoxLeft from '../../component/layout/BoxLeft'
import BoxRight from '../../component/layout/BoxRight'
import StyledButton from '../../component/button/syledButton'


const { Option } = Select
const bankOnline = [
  {
    value: 'BKKB',
    title: 'Bangkok Bank'
  },
  {
    value: 'BAAC',
    title: 'Bank for Agriculture and Agricultural Cooperatives	'
  },
  {
    value: 'KSAB',
    title: 'Bank of Ayudhya (Krungsri)'
  },
  {
    value: 'CIMT',
    title: 'CIMB Thai Bank'
  },
  {
    value: 'GSBA',
    title: 'Government Savings Bank	'
  },
  {
    value: 'KSKB',
    title: 'Kasikorn Bank	'
  },
  {
    value: 'Kiatnakin Bank',
    title: 'Kiatnakin Bank'
  },
  {
    value: 'KTBA',
    title: 'Krung Thai Bank	'
  },
  {
    value: 'SCBB',
    title: 'Siam Commercial Bank'
  },
  {
    value: 'SCBT',
    title: 'Standard Chartered Bank (Thai)'
  }
]

function withdrawNewTest({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [dataForm, setDataForm] = useState({})
  const [checkbank, setCheckbank] = useState(store.get('user'))
  const [checKissBank, setChecKissBank] = useState('')
  const [kissvipBanks, setKissvipbanks] = useState([])
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('WITH_SUC')
  })
  const [depositOption, setDepositOption] = useState(1)
  const [typeBank, setTypeBank] = useState('banking')

   const handlerShowBankSelected = item => {
     setDepositOption(item)
     if (item == 2) {
       setTypeBank(item == 2 ? 'kissvippay' : 'banking')
     } else {
       setTypeBank(item == 1 ? 'banking' : 'kissvippay')
     }
   }

  const handlerSelected = item => {
    // console.log(item)
    const f = kissvipBanks.find(x => x.id === item)
    setChecKissBank(f)
  }

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t('WITH_ERR'),
    btnLabel: t('GOT_IT'),
    type: 'error'
  })
  const user = store.get('user')
  const onSubmit = async payload => {
    if(!checKissBank.id) showDialogErrorWithMessage('Please select to bank')
    else try {
      const { user_id: userId, user_account_no: username } = store.get('user')

      const submited = {
        amount: payload.amount,
        bank_name: payload.bank_name.replace('\t', ''),
        bankCode: payload.bankCode,
        to_bank_id: checKissBank.id,
        bank_account_name: payload.bank_account_name,
        bank_account_number: payload.bank_account_no,
        currency: config.currency,
        payment_method: 'transaction',
        username
      }
      console.log(submited);
      await withLoading(() =>
        http.post({ path: `users/${userId}/withdraw`, payload: submited })
      )
      // socket.emit('messages', {
      //   msgType: 'withdraw.request',
      //   data: {
      //     message: `Request withdraw from '${username}'`,
      //     title: 'Withdraw Request'
      //   }
      // })
      showDialogWithMessage(t('WITH_DONE'))
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  React.useEffect(() => {
    // fetchData()
    form.setFieldsValue({
      name: dataForm.name,
      bank_account_name: dataForm.bank_account_name,
      bank_account_no: dataForm.bank_account_no
    })
  }, [form, dataForm])
  const [form] = Form.useForm()
  const handleSubmit = values => {
    onSubmit({
      ...values,
      bank_name: user.bankName,
      bankCode: user.bankCode,
      bank_account_name: user.bankAccount,
      bank_account_no: user.bankAccountNumber
    })
  }
  const fetchBAnks = async () => {
    const [b] = await withLoading(() =>
      Promise.all([
        http.get({ path: `payment-kissvip/banks` })
      ]))
    setKissvipbanks(b.accounts.filter(x => x.status === "A"))
  }
  React.useEffect(() => {
    fetchBAnks()
  }, [])
  return (
    <div className="containerActive">
      <MenuItem />
      <div className="styled-title-router">Withdraw</div>
      <div className="styledContainer">
        <BoxLeft />
        <div className="profile-container">
          <div className="withdraw-container">
            <div>
              <p className="withdraw-title">
                Withdraw Options <span className="type-red">*</span>
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  flexWrap: 'wrap'
                }}
              >
                <div className="deposit-new-container-box">
                  <div
                    className={
                      depositOption === 1
                        ? 'deposit-new-box-active'
                        : 'deposit-new-box'
                    }
                    onClick={() => handlerShowBankSelected(1)}
                  >
                    {depositOption === 1 ? (
                      <img
                        style={{ width: '30px' }}
                        src="./images/home/bankwhite.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ width: '30px' }}
                        src="./images/home/bank.svg"
                        alt=""
                      />
                    )}
                  </div>
                  <p className="deposit-new-text">Bank Transfer</p>
                </div>
                <div className="deposit-new-container-box">
                  <div
                    className={
                      depositOption === 2
                        ? 'deposit-new-box-active'
                        : 'deposit-new-box'
                    }
                    onClick={() => handlerShowBankSelected(2)}
                  >
                    <img
                      style={{ width: '81%' }}
                      src="./images/home/logovipwhite.png"
                      alt=""
                    />
                  </div>
                  <p className="deposit-new-text">VIP Pay</p>
                </div>
              </div>
            </div>
            {typeBank === 'banking' && (
              <div className="styled-group-banks">
                <div className="styled-bank">
                  <div
                    className={
                      checkbank.bankName === 'Kbank'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                  >
                    {checkbank.bankName === 'Kbank' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/kbankactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/kbankunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Kbank</p>
                  </div>
                  <div
                    className={
                      checkbank.bankName === 'Krungthai'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                  >
                    {checkbank.bankName === 'Krungthai' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/krungactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/krungunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Krungthai</p>
                  </div>
                  <div
                    className={
                      checkbank.bankName === 'SCB'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                  >
                    {checkbank.bankName === 'SCB' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/acbactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/acbunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">SCB</p>
                  </div>
                  <div
                    className={
                      checkbank.bankName === 'Krungsri'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                  >
                    {checkbank.bankName === 'krungsri' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/krungsriactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/krungsriunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Krungsri</p>
                  </div>
                  <div
                    className={
                      checkbank.bankName === 'Bangkok Bank'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                  >
                    {checkbank.bankName === 'Bangkok Bank' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/bankokbank.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/bankokbank.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">Bangkok Bank</p>
                  </div>
                  <div
                    className={
                      checkbank.bankName === 'TTB'
                        ? 'styled-item-bank-active'
                        : 'styled-item-bank'
                    }
                  >
                    {checkbank.bankName === 'TTB' ? (
                      <img
                        className="style-bank"
                        src="./images/logo/ttbactive.png"
                        alt="bank"
                      />
                    ) : (
                      <img
                        className="style-bank"
                        src="./images/logo/ttbunactive.png"
                        alt="bank"
                      />
                    )}
                    <p className="withdraw-text">TTB</p>
                  </div>
                </div>
              </div>
            )}
            {typeBank === 'kissvippay' && (
              <div className="styled-group-banks">
                <div className="styled-bank">
                  {kissvipBanks.map((bank, ix) => (
                    <div
                      key={ix}
                      className={
                        checKissBank.name === bank.name
                          ? 'styled-item-bank-active'
                          : 'styled-item-bank'
                      }
                      onClick={() => handlerSelected(bank.id)}
                    >
                      <img
                        className="style-bank"
                        src={bank.logo_url}
                        alt="bank"
                      />
                      <p className="withdraw-text">{bank.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Form form={form} onFinish={handleSubmit} className="style-from">
              <div className="styled-from-account-banks">
                <div>
                  <p className="withdraw-text-form">
                    Account Name <span className="type-red">*</span>
                  </p>
                  <input
                    type="text"
                    className="styled-input-from"
                    placeholder="Enter Account Name"
                    defaultValue={checkbank.bankAccount}
                  />
                </div>
                <div>
                  <p className="withdraw-text-form">
                    Account No <span className="type-red">*</span>
                  </p>
                  <input
                    type="text"
                    className="styled-input-from"
                    placeholder="Enter Account No"
                    defaultValue={checkbank.bankAccountNumber}
                  />
                </div>
                <div>
                  <p className="withdraw-text-form">
                    Amount <span className="type-red">*</span>
                  </p>
                  <Form.Item name="amount">
                    <input
                      type="number"
                      size="large"
                      className="styled-input-from"
                      placeholder="Enter Amount"
                    />
                  </Form.Item>
                  <div className="wraning-amount">
                    <img
                      className="styled-warning"
                      src="./images/home/warningAmount.svg"
                      alt=""
                    />
                    Daily Limit Balance:50,000/Daily Count Balance:10
                  </div>
                </div>
              </div>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 15
                  }}
                >
                  <PropagateLoader
                    sizeUnit="px"
                    size={20}
                    color="#f50057"
                    loading={loading}
                  />
                </div>
              ) : (
                <div className="text-center formGroup_btn">
                  <StyledButton
                    width="250px"
                    background="#FF8A00"
                    type="primary"
                    // id="custom-primary-button-deposit"
                    loading={loading}
                    htmlType="submit"
                  >
                    Submit
                  </StyledButton>
                </div>
              )}
            </Form>
            {/* <div className="styled-container-table">
              <table className="styled-table">
                <tr>
                  <th>Transaction Date</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
                <tr>
                  <td>Alfreds Futterkiste</td>
                  <td>Maria Anders</td>
                  <td>Germany</td>
                  <td>Germany</td>
                </tr>
              </table>
            </div> */}
          </div>
        </div>
        <div className="showBoxright">
          <BoxRight />
        </div>
      </div>
      <div className="showBoxrightmb">
        <BoxRight />
      </div>
      <Dialog />
      <ErrorDialog />
    </div>
  )
}

export default withdrawNewTest
