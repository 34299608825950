import React from 'react'

const AppDownload = () => {
  return (
    <div className="app-download">
      <div className="app-download-left">
        <p className="app-download-left-text">App Download</p>
        <div className="app-download-left-title">
          The future of gaming is here. Great games on all devices. New
          <img
            style={{ width: '70px', paddingBottom: '5px' }}
            src="./images/home/logovipp.png"
            alt="logo"
          />
          App
        </div>
        <div className="app-download-left-detail">
          Sign up today for never ending rewards, promotion and lots of
          freebies.
        </div>
        <div className="app-download-groupqr">
          <div className="app-download-styledqr-dowload">
            <img
              style={{ width: '110px' }}
              src="./images/home/qr.png"
              alt="qr"
            />
            <div className="app-download-appapple">
              <img
                style={{ width: '18px', height: '18px' }}
                src="./images/home/apple.svg"
                alt="apple"
              />
              Apple IOS
            </div>
            <div className="app-download-appIntrstions">intrustions</div>
          </div>
          {/* <div className="app-download-styledqr-dowload">
            <img
              style={{ width: '110px' }}
              src="./images/home/qr.png"
              alt="qr"
            />
            <div className="app-download-appapple">
              <img
                style={{ width: '18px', height: '18px' }}
                src="./images/home/android.png"
                alt="apple"
              />
              Android
            </div>
            <div className="app-download-appIntrstions">intrustions</div>
          </div> */}
        </div>
      </div>
      <div className="app-download-right">
        <div className="app-download-right-left">
          <img src="./images/home/vertical01.png" alt="vertical" />
        </div>
        <div className="app-download-right-right">
          <div className="app-download-right-item">
            <img src="./images/home/vertical01.png" alt="vertical" />
            <img src="./images/home/vertical02.png" alt="vertical" />
          </div>
          <div
            className="app-download-right-item"
            style={{ marginTop: '60px' }}
          >
            <img src="./images/home/vertical03.png" alt="vertical" />
            <img src="./images/home/vertical04.png" alt="vertical" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppDownload
