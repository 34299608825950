export default [
  {
    src: '/images/gameLogo/casino-biggaming.png',
    code: 'BGAWS',
    label: 'Big gaming',
    route: 'big-gaming',
    idrActive: 0,
    id: 28
  },
  {
    src: '/images/gameLogo/casino-venus.png',
    code: 'sexy_baccarat',
    label: 'Venus Casino',
    route: 'venus',
    idrActive: 0,
    id: 23
  },
  {
    src: '/images/gameLogo/casino-sexy.png',
    code: 'sexy_baccarat',
    label: 'Sexy Baccarat',
    route: 'awc/SEXYBCRT/LIVE',
    idrActive: 0,
    // game: ,
    id: 23
  },
  {
    src: '/images/gameLogo/world-intertainment.png',
    code: 'WE',
    label: 'WE',
    route: 'we',
    idrActive: 0,
    id: 26
  }
  // {
  //   src: '/img/games/BGbanner.png',
  //   code: 'BG',
  //   label: 'BG',
  //   route: '/bg',
  //   id: 24
  // }, {
  //   src: '/img/games/asiagaming.png',
  //   code: 'asiagaming',
  //   label: 'Asia Gaming',
  //   route: '/asiagaming?GameType=LC',
  //   id: 21
  // }, {
  //   src: '/images/gameLogo/allbet.png',
  //   code: 'allbet',
  //   label: 'Allbet',
  //   route: 'allbet',
  //   idrActive: 0,
  //   id: 5
  // }, {
  //   src: '/img/games/playtech.png',
  //   code: 'playtech',
  //   label: 'Playtech',
  //   route: '/authenticate/playtech/7bal',
  //   idrActive: 1,
  //   id: 4,
  //   // target: "_blank"
  // }, {
  //   src: '/img/m8.png',
  //   code: 'm8',
  //   label: 'M8',
  //   route: '/m8',
  //   idrActive: 0,
  //   id: 9
  // },{

  //   src: '/img/games/mega888.png',
  //   code: 'mega',
  //   label: 'Mega888',
  //   idrActive: 1,
  //   route: '/',
  //   id: 11
  // },
  // {
  //   src: '/img/games/sbobet.png',
  //   code: 'sbo',
  //   label: 'SBO',
  //   route: '/sbo?GameType=Casino',
  //   idrActive: 1,
  //   id: 21
  // }, {
  //   src: '/images/gameLogo/sexy.jpg',
  //   code: 'sexy_baccarat',
  //   label: 'Sexy Baccarat',
  //   route: 'sexy-baccarat',
  //   idrActive: 0,
  //   id: 23

  // }, {
  //   src: '/img/games/evolutiongaming.png',
  //   code: 'evolution',
  //   label: 'EVOLUTION',
  //   route: '/evolution-games',
  //   id: 15
  // }
]
