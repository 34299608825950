/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import Header from 'component/header'
import CloseIcon from '@material-ui/icons/Close'
import { map, isEmpty } from 'ramda'
import Bottom from 'component/bottom'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import useLoading from '../loading/hook'
import styles from './style'
import SLOTS from './constant'
import config from 'config'
import queryString from 'query-string'
import Head from '../../component/layout/Head'
import Testimonials from '../../component/layout/Testimonials'
import TotalPlay from '../../component/layout/TotalPlay'
import component from 'component'
import LotteryComp from '../LotteryComp'
const { useDialog } = component

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: '',
    7: '',
    2: '',
    1: ''
  }

  return proxy[id]
}
const listLottery = [
  {
    image: './images/home/lottovip.png',
    class: 'pages-icon-coming'
  },
  {
    image: './images/home/lottokissvip.png',
    class: 'pages-icon-hot'
  }
]
function home({ history, classes }) {
  const {
    location: { search }
  } = history
  const { game } = queryString.parse(search)
  const [loading, withLoading] = useLoading(false)
  const [gameSelected, updateGameSelected] = React.useState({})
  const [games, setGames] = React.useState([])
  const { user_id: userId } = store.get('user') || { user_id: undefined }
  const image = './images/home/bannerlottery.png'
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Warning',
    btnLabel: 'Got it',
    type: 'error'
  })
  const gamesSetup = async () => {
    const gameList = await http.get({ path: 'games' })
    setGames(gameList)
    return gameList
  }

  const loginToGame = async (
    { src, label, route, id, redirect, target, deeplink },
    gameList = null
  ) => {
    if (target === '_blank') {
      window.open(route)
    }
    if (redirect) {
      history.push(route)
      return
    }

    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    updateGameSelected({ label, src, route, id })
    const {
      balance,
      account,
      err,
      game_password: password,
      status,
      message
    } = await withLoading(
      () =>
        http
          .post({
            path: `users/${userId}/game/logout`,
            payload: {
              gameLoginId: id
            }
          })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              throw { message: error.message }
            }
            return true
          })
      // .then(() => deeplink || [10].includes(id) ? true : http.get({ path: `games/${id}/login` }))
      // .then(() => http.get({ path: `users/${userId}/games/${id}/balance` }))
    ).catch(error => {
      return { err: error.message }
    })

    const outDeeplink = generateDeeplink({ id, account, password })
    updateGameSelected({
      label,
      src,
      route,
      id,
      balance: balance.balance,
      account: balance.account,
      err,
      deeplink: outDeeplink,
      password
    })
    if (status && status === 0)
      showDialogErrorWithMessage(
        message === 'credit not enough' ? t('CREDIT_NOT_ENOUGH') : message
      )
    else if (label !== 'NewWin' && !outDeeplink && outDeeplink !== '')
      history.push(route)
  }

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` })
    }
    async function fetchGame() {
      const gameList = await gamesSetup()
      if (game) {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink
        } = SLOTS.find(x => x.code === game)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink },
          gameList
        )
      }
    }
    fetchGame()
  }, [])

  const handleClose = () => {
    updateGameSelected({})
  }
  const { t } = useTranslation()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
      <Head image={image} alt="bannerlottery" />
      <div className="pages">
        <button className="pages-btn-items">Lottery</button>
        <LotteryComp history={history} />
        <img
          style={{ width: '100%', margin: '30px 0' }}
          src="./images/home/bannerlotteryplay.png"
          alt="bannerlottery"
        />
        <TotalPlay />
        <Testimonials />
      </div>
    </div>
  )
}
export default withStyles(styles)(home)
