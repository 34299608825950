export default [{
//   src: '/img/NewWin-icon-gold.png',
//   code: 'newwin',
//   label: 'NewWin',
//   route: '/newwin',
//   id: 10
// }, {
  label: '97Lotto',
  code: '97lotto',
  src: '/img/97lotto.jpg',
  route: '/97lotto',
  idrActive: 0,
  id: 12
}]