import React from 'react'
import './Styles.css'

const ModalHeader = ({ isShowMenu, history, onShowMenu }) => {
  const handlePushLinks = ( link ) => {
    history.push(link)
    onShowMenu()
  }
  return (
    <div className="modal-header-wrapper">
      <div className={`show_menu_mb ${isShowMenu ? 'show' : ''}`}>
        <div className="box_menu">
          <div className="modal-header-nav">
            <div className="modal-header-styled-logo">
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/')}
              >
                <img
                  className="styledlogo"
                  src="./images/logo/logovip.png"
                  alt="logo"
                />
              </p>
            </div>
            <div
              className="modal-header-styled-close"
              onClick={() => onShowMenu()}
            >
              <img
                style={{ width: '16px', height: '16px' }}
                src="./images/header/x.png"
                alt="x"
              />
            </div>
          </div>
          <div className="modal-header-styled-grouplink">
            <div className="modal-header-styled-link">
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/')}
              >
                Home
              </p>
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/slot')}
              >
                Slots
              </p>
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/casino')}
              >
                Casino
              </p>
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/lottery')}
              >
                Lottery
              </p>
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/sports')}
              >
                Sports
              </p>
              {/* <Link onClick={() => history.push('/fishing')}>Fishing</Link> */}
            </div>
            <div className="modal-header-styled-link">
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/download')}
              >
                Download
              </p>
              <p
                className="modal-header-link"
                onClick={() => handlePushLinks('/login')}
              >
                Logout
                <img
                  style={{ width: '18px', height: '15px', marginLeft: '6px' }}
                  src="./images/header/iconlogout.png"
                  alt="Log Out"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalHeader
