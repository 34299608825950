import React from 'react'
import { FormControl, TextField } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import moment from 'moment'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffaf50'
    },
  },
  typography: { useNextVariants: true },
})

const styles = {
  input: {
    color: "#ffaf50"
  },
  label: {
    color: "#ffaf50"
  },
  underline: {
    "&:before": {
      borderBottom: `1px solid #ffaf50`
    }
  }
}

const Input = ({classes, input: { input, meta }, label, ...rest }) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  return (
    <FormControl margin="normal" required fullWidth>
      <MuiThemeProvider theme={theme}>
        <TextField
          id="date"
          label={label}
          type="date"
          {...input}
          InputLabelProps={{
            shrink: true,
            className: classes.label
          }}
          InputProps={{
            classes: {underline: classes.underline},
            className: classes.input
          }}
        />
        {/*<TextField*/}
          {/*id="date"*/}
          {/*label={label}*/}
          {/*type="date"*/}
          {/*{...input}*/}
          {/*defaultValue={moment(new Date()).format("MM/DD/YYYY")}*/}
          {/*InputProps={{*/}
            {/*classes: {underline: classes.underline},*/}
            {/*className: classes.input*/}
          {/*}}*/}
          {/*InputLabelProps={{*/}
            {/*shrink: true,*/}
            {/*className: classes.label*/}
          {/*}}*/}
        {/*/>*/}

      </MuiThemeProvider>
      {meta.touched && meta.error && (
        <div style={{ color: '#dc3545', marginTop: 5 }}>{meta.error}</div>
      )}
    </FormControl>
  )
}

export default withStyles(styles)(Input)