import React from 'react'
import './Styles.css'
import StyledButton from '../button/syledButton'

// const TotalPlayNow = styled.div`
//   width: 100%;
//   max-height: 200px;
//   height: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   background: #dfdfdf;
//   padding: 20px;
//   margin: 25px 0;
//   border-radius: 12px;
//   @media (max-width: 575px) {
//     padding: 20px 10px;
//     margin: 10px 0;
//   }
// `
// const Title = styled.p`
//   font-size: 32px;
//   line-height: 42px;
//   font-weight: 800;
//   color: #000;
//   margin: 0;
//   @media (max-width: 575px) {
//     font-size: 20px;
//     line-height: 28px;
//   }
// `
// const Text = styled.p`
//     font-size: 18px;
//     line-height: 24px;
//     font-weight: 400;
//     color: #FF8202;
//     margin: 0;
//     @media (max-width: 575px) {
//       font-size: 13px;
//       line-height: 18px;
//     }
// `
// const BtnPlay = styled.button`
//     width: 200px;
//     height: 60px;
//     border-radius: 12px;
//     border: none;
//     font-size: 18px;
//     line-height: 24px;
//     font-weight: 600;
//     cursor: pointer;
//     color: #fff;
//     background: #FF8100;
//     &:hover {
//       opacity: 0.5;
//     }
//     @media (max-width: 575px) {
//       width: 130px;
//       height: 40px;
//       font-size: 14px;
//       line-height: 18px;
//     }
// `
// const Group = styled.div`
//     width: 50%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     @media (max-width: 950px) {
//       flex-direction: column;
//       align-items: center;
//       gap: 20px;
//     }
// `

const TotalPlay = () => {
  return (
    <div className="total-play-wrapper">
      <div className="total-play-group">
        <div>
          <p className="total-play-title">2000+</p>
          <p className="total-play-text">Exciting Games</p>
        </div>
        <div>
          <p className="total-play-title">MYR 6,888</p>
          <p className="total-play-text">Weekly Rescue Bonus</p>
        </div>
      </div>
      <div className="total-play-group">
        <div>
          <p className="total-play-title">Up to 50%</p>
          <p className="total-play-text">Unlimited Reload Bonus</p>
        </div>
        <div>
          <StyledButton background="#FF8100">PLAY NOW</StyledButton>
        </div>
      </div>
    </div>
  )
}
export default TotalPlay
