import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'ramda'
import { PropagateLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
import schema from './schema'
import store from 'store'
import formCreateInputs from 'service/form/create'
import { useForm } from 'react-final-form-hooks'
import validate from 'service/form/validation'
import useLoading from '../loading/hook'
// import socket from 'service/socket'
import useDialog from '../dialog/hook'
import Topup from './topup'
import Resizer from 'react-image-file-resizer';
import http from 'service/http'

const fileToBase64 = () => {
  return new Promise(resolve => {
    const { files: [file] } = document.querySelector('input[type=file]')
    const formData = new FormData()
    formData.append(0, [file])
    console.log(formData)
    const reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // width: 500,
    color: '#fff'
  },
});
const fileChangedHandler = () =>  {
  const { files: [file] } = document.querySelector('input[type=file]')
  let fileInput = false
  if(file) {
    fileInput = true
  }
  return new Promise(resolve => {
    if (fileInput) {
      Resizer.imageFileResizer(
        file,
        1900,
        900,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'base64'
      );
    }
  })
}
function DepositTab({ classes, theme, banks, history}) {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)
  const [loading, withLoading] = useLoading(false)
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Deposit Successful'
  })
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Deposit Error',
    btnLabel: 'Got it',
    type: 'error'
  })

  const handleChange = (event, value1) => {
    setValue(value1);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const onSubmit = async payload => {
    try {
      const { toBank, amount, bonus } = payload
      const toBankDetail = JSON.parse(toBank)
      const { user_id: userId, user_account_no: username } = store.get('user')
      // const proofOfDeposit = await fileToBase64()
      const proofOfDeposit = await fileChangedHandler()
      console.log(proofOfDeposit)
      const submited = {
        amount,
        currency: 'MYR',
        to_bank_account_name: toBankDetail.bankAccountName,
        to_bank_account_number: toBankDetail.bankAccountNo,
        to_bank: toBankDetail.name,
        to_bank_id: toBankDetail.id,
        username,
        receipt_image: proofOfDeposit,
        is_bonus: bonus
      }
      await withLoading(() =>
        http.post({ path: `users/${userId}/deposit`, payload: submited })
      )
      // socket.emit('messages', {
      //   msgType: 'deposit.request',
      //   data: {
      //     message: `Request deposit from '${username}'`,
      //     title: 'Deposit Request'
      //   }
      // })
      showDialogWithMessage('Your request submitted successfully', () => history.push('/account'))
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }
  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema),
  })
  const [amount, toBank, bonus] = formCreateInputs(['amount', 'toBank', 'bonus'], form)
  const toBankDetail = React.useMemo(() => {
    try {
      return JSON.parse(toBank.input.value)
    } catch (error) {
      return {}
    }
  }, [toBank.input.value])
  const fetchBonus = async () => {
    const bonus = await http.get({ path: `admin/promotion` })
    console.log(bonus)
  }
  React.useEffect(() => {
    fetchBonus()
  }, [])
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default" style={{background: '#373a4e', borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          height: '55px',
          color: '#fff'
        }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
          >
            <Tab label={t('ONLINE_BANKING')} />
            <Tab label={t('BANKING')} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={'x-reverse'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabContainer>
            <Topup/>
          </TabContainer>
          <TabContainer>
            <div className="deposit-right-main">
              <form onSubmit={handleSubmit}>

                <div className="formGroup display_flex">
                  <label htmlFor="">{t('SELECT_BANK')}</label>
                  <div className="inputW">
                    <select
                      name=""
                      className="input_block"
                      id=""
                      {...toBank.input}
                    >
                      <option value="">{t('SELECT_BANK')}</option>
                      {banks.map(({ title, value }) => (
                        <option key={title} value={JSON.stringify(value)}>{title}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {!isEmpty(toBankDetail)
                  ? (
                    <ul style={{ color: '#ffaf50' }}>
                      <li>Bank: {toBankDetail.name}</li>
                      <li>Bank Name: {toBankDetail.bankAccountName}</li>
                      <li>Bank Number: {toBankDetail.bankAccountNo}</li>
                    </ul>
                  )
                  : null
                }

                <div className="formGroup display_flex">
                  <label htmlFor="">{t('DEPOSIT_AMOUNT')}</label>
                  <div className="inputW">
                    <input
                      type="text"
                      className="input_block input_white"
                      name=""
                      {...amount.input}
                    />
                  </div>
                </div>

                <div className="formGroup display_flex">
                  <label htmlFor="">Choose File</label>
                  <div className="inputW">
                    <input type="file"
                           className="input_block input_white"
                           />
                    {/*<input*/}
                      {/*className="input_block input_white"*/}
                      {/*type="file"*/}
                      {/*onChange={alert}*/}
                      {/*{...receipt.input}*/}
                    {/*/>*/}

                  </div>
                </div>

                {loading ? (
                  <div
                    style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <div className="text-center formGroup_btn">
                    <button disabled={submitting} type="submit" className="reload"><span></span>{t('DEPOSIT')}</button>
                    {/*<button onClick={() => history.push('/topup')} className="reload"><span></span>{t('TOPUP')}</button>*/}
                  </div>
                )}
              </form>
            </div>
          </TabContainer>

        </SwipeableViews>
        <Dialog />
        <ErrorDialog />
      </div>
    );

}
export default withStyles(styles)(DepositTab)
