/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import http from 'service/http'
import socket from 'service/socket'
import numeral from 'numeral'
import moment from 'moment'
import store from 'store'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import './style.css'
import { Button, Form, Select } from 'antd'
import InputField from '../../component/textInput/InputField'
import { PropagateLoader } from 'react-spinners'
import { showNotification } from '../../utils/function'
import { ETypeNotification } from '../../constants/gameData'

// socket.on('login', (data) => {
//   localStorage.clear();
//   alert('Your account just login from other place !')
//   window.location = 'login'
// })

function NewMyAcc({ history }) {
  const { t } = useTranslation()
  const user = store.get('user');
  const [loading, withLoading] = useLoading(false)
  const [form] = Form.useForm();

  const onSubmit = async payload => {
    try {
      const { user_id: userId, user_account_no: username } = store.get('user')
      const submited = {
        fullname: payload.fullname,
        mobile_phone: payload.phone,
      }
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: { ...submited } })
      )

      showNotification(ETypeNotification.SUCCESS, 'Success !')
    } catch (error) {
      showNotification(ETypeNotification.ERROR, error.message)
    }
  }
  const handleSubmit = (values) => {
    onSubmit({...values})
  }
  React.useEffect(() => {
    form.setFieldsValue({
      fullname: user.fullname,
      user_account_no: user.user_account_no,
      phone: user.mobile_phone
    });
  }, [form])
  return (
    <div className="content-account" style={{marginTop: 60, width: '100%', padding: '0 10px' }}>
      <div style={{marginTop: '20px'}}>
      <Form form={form}  className="form-deposit" onFinish={handleSubmit}>

        <Form.Item name="fullname" className='label-with-draw' label={t('FULL_NAME')}>
          <InputField size="large" placeholder="Full name..." />
        </Form.Item>
        <Form.Item name="user_account_no" className='label-with-draw' label={t('USERNAME')}>
          <InputField disabled size="large" placeholder="Username..." />
        </Form.Item>

        <Form.Item name="phone" className='label-with-draw' label={t('PHONE')}>
          <InputField size="large" placeholder="Phone..." />
        </Form.Item>

        {loading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
          >
            <PropagateLoader
              sizeUnit="px"
              size={20}
              color="#f50057"
              loading={loading}
            />
          </div>
        ) : (
          <div className="text-center formGroup_btn">
            <Button style={{background: '#ae7b00'}} type="primary" id='custom-primary-button-deposit' loading={loading} htmlType='submit'>{t('UPDATE')}</Button>

          </div>
        )}
      </Form>
      </div>
    </div>
  );
}

export default NewMyAcc
