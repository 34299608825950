import React, { useState, useRef, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import config from 'config'
import store from 'store'
import CompCommon from 'feature/CompCommon'
import NewAccountMb from 'feature/NewAccountMb'
import NewBankingMb from 'feature/NewBankingMb'

import gameData from '../../constants/gameData'

const BottomNavigation = ({ history }) => {
  const { t } = useTranslation()
  const drawer = useRef(null)
  const menuGameElm = useRef(null)
  const [openMenu, updateOpenMenu] = useState(false)
  // const [activeHistory, setActiveHistory] = useState(false)

  const [isShowCompBanking, setShowCompCommonBanking] = useState(false)
  const [isShowCompAcc, setShowCompCommonAcc] = useState(false)
  const user = store.get('user')

  const handleCloseDropdown = event => {
    const gameElm = menuGameElm.current
    const drawerElm = drawer.current
    if (
      gameElm &&
      !gameElm.contains(event.target) &&
      drawerElm &&
      !drawerElm.contains(event.target)
    ) {
      updateOpenMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleCloseDropdown)
    document.addEventListener('touchstart', handleCloseDropdown)
    return () => {
      document.addEventListener('click', handleCloseDropdown)
      document.addEventListener('touchstart', handleCloseDropdown)
    }
  }, [])

  const handleRedirect = url => {
    updateOpenMenu(false)
    window.location.href = url
    // history.push(url);
  }

  const { user_id: userId } = store.get('user') || {}

  const onSetShow = (isShow, data) => {
    if (data === 'banking') {
      setShowCompCommonBanking(isShow)
      setShowCompCommonAcc(false)
    } else if(data === 'deposit') {
      document.getElementById('openDepositmobile').click()
    } else {
      setShowCompCommonAcc(isShow)
      setShowCompCommonBanking(false)
    }
  }
  return (
    <>
      <div
        ref={drawer}
        className={`show_ipad menuMb-game ${openMenu ? 'show' : ''}`}
      >
        <div className="menu_sb2">
          <span className="menu_sb2_title">{t('GAME')}</span>
          {/*<a onClick={() => handleRedirect('/deposit')} className="acc-pointerive"><span className="icon_menu0"></span>{t('DEPOSIT')}</a>*/}

          {gameData.bodyContents
            .filter(({ ACL }) => config.market.includes(ACL))
            .map(({ title, route, icon }) => (
              <a
                key={title}
                onClick={() => handleRedirect(route)}
                className="acc-pointerive"
              >
                <span className={icon}></span>
                {t(title)}
              </a>
            ))}
          <a
            onClick={() => handleRedirect('/download')}
            className="acc-pointerive"
          >
            <span className="icon_menu8"></span>
            {t('DOWNLOAD')}
          </a>
        </div>
      </div>
      <div className="show_ipad menuMb">
        <div className="w_1220">
          <div
            className="menu_mb display_flex"
            style={{ position: 'relative' }}
          >
            <a
              onClick={() => {
                if (isShowCompAcc) setShowCompCommonAcc(!isShowCompAcc)
                if (isShowCompBanking)
                  setShowCompCommonBanking(!isShowCompBanking)
                history.push('/home')
              }}
              className="menu_item menu_item3"
            >
              {!isShowCompAcc && !isShowCompBanking ? (
                <img src="images/icon/homee.png" alt="" />
              ) : (
                <img src="images/icon/homeblack.png" alt="" />
              )}

              <p style={{ margin: 0 }}>{t('HOME')}</p>
            </a>
            <a
              style={{ marginRight: 20 }}
              onClick={() => {
                user && user.user_id
                  ? history.push('/histories')
                  : // ? onSetShow(true, 'banking')
                    history.push('/home')
              }}
              className="menu_item menu_item3"
            >
              {!isShowCompBanking && (
                <img src="images/icon/historynavi.png" alt="" />
              )}
              {isShowCompBanking && (
                <img src="images/icon/history.png" alt="" />
              )}
              <p style={{ margin: 0 }}>{t('HISTORY')}</p>{' '}
            </a>
            <a
              style={{
                position: 'absolute',
                left: '40%',
                bottom: 0.5
              }}
              onClick={() => {
                user && user.user_id
                  ? history.push('/deposit')
                  : history.push('/home')
              }}
              // onClick={() => onSetShow(true, 'deposit')}
              className="menu_item menu_item3"
            >
              <img
                style={{ width: 70 }}
                src="images/icon/deposit1.png"
                alt=""
              />
            </a>
            <a
              style={{ marginLeft: 20 }}
              onClick={() => onSetShow(true, 'acc')}
              className="menu_item menu_item3"
              id="myInfo"
            >
              {!isShowCompAcc && <img src="images/icon/accBlack.png" alt="" />}
              {isShowCompAcc && <img src="images/icon/myInfo.png" alt="" />}
              <p style={{ margin: 0 }}>{t('ACCOUNT')}</p>{' '}
            </a>
            <a
              onClick={() => history.push('/')}
              className="menu_item menu_item3"
            >
              <img src="images/icon/chat.png" alt="" />
              <p style={{ margin: 0 }}>{t('Live Chat')}</p>{' '}
            </a>
          </div>
        </div>
      </div>
      <CompCommon
        title={'My Accounts'}
        show={isShowCompAcc}
        close={() => {
          setShowCompCommonAcc(!isShowCompAcc)
        }}
      >
        <NewAccountMb />
      </CompCommon>
      <CompCommon
        title={'Banking'}
        show={isShowCompBanking}
        close={() => {
          setShowCompCommonBanking(!isShowCompBanking)
        }}
      >
        <NewBankingMb />
      </CompCommon>
    </>
  )
}

export default withRouter(BottomNavigation)
