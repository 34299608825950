export default [
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Amazon Wild",
    "gameCode": "ashamw"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Archer",
    "gameCode": "arc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Arctic Treasure",
    "gameCode": "art"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Atlantis Queen",
    "gameCode": "gtsatq"
  },
  {
    "gameType": "Card Games",
    "gameGroup": "Table Games",
    "gameName": "Baccarat",
    "gameCode": "ba"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Beach Life",
    "gameCode": "bl"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Bermuda Triangle",
    "gameCode": "bt"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Bonus Bears",
    "gameCode": "bob"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "A Night Out",
    "gameCode": "hb"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Captains Treasure",
    "gameCode": "ct"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Captain&#39;s Treasure Pro",
    "gameCode": "ctp2"
  },
  {
    "gameType": "Card Games",
    "gameGroup": "Table Games",
    "gameName": "Casino Hold &#39;Em",
    "gameCode": "cheaa"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Cherry Love",
    "gameCode": "chl"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Chinese Kitchen",
    "gameCode": "cm"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Cops N&#39; Bandits",
    "gameCode": "gtscnb"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Cowboys &amp; Aliens",
    "gameCode": "gtscbl"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Crazy 7",
    "gameCode": "c7"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Desert Treasure",
    "gameCode": "dt"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Dolphin Reef",
    "gameCode": "dnr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Easter Surprise",
    "gameCode": "eas"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Esmeralda",
    "gameCode": "esmk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Everybody&#39;s Jackpot",
    "gameCode": "evj"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Football Rules",
    "gameCode": "fbr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Forest of Wonder",
    "gameCode": "fow"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Fountain of Youth",
    "gameCode": "foy"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fortunes of the Fox",
    "gameCode": "fxf"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Frankie Dettori&#39;s Magic Seven",
    "gameCode": "fdt"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Full Moon Fortunes",
    "gameCode": "ashfmf"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Funky Fruits Farm",
    "gameCode": "fff"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Funky Fruits Jackpot Game",
    "gameCode": "fnfrj"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Funky Monkey",
    "gameCode": "fm"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Gladiator",
    "gameCode": "glr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Gladiator Jackpot",
    "gameCode": "glrj"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Golden Tour",
    "gameCode": "gos"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Great Blue",
    "gameCode": "bib"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Greatest Odyssey",
    "gameCode": "gro"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Halloween Fortune",
    "gameCode": "hlf"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Haunted House",
    "gameCode": "hh"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Heart of The Jungle",
    "gameCode": "ashhotj"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Highway Kings",
    "gameCode": "hk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Highway Kings Pro",
    "gameCode": "gtshwkp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Hot Gems",
    "gameCode": "gts50"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Geisha Story",
    "gameCode": "ges"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Irish Luck",
    "gameCode": "gtsirl"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Jackpot Giant",
    "gameCode": "jpgt"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Kong The Eighth Wonder Of The World",
    "gameCode": "kkg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Marilyn Monroe",
    "gameCode": "gtsmrln"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Mr. Cashback",
    "gameCode": "mcb"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Neptunes Kingdom",
    "gameCode": "nk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Panther Moon",
    "gameCode": "pmn"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Party Line",
    "gameCode": "pl"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Penguin Vacation",
    "gameCode": "pgv"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Pharaoh&#39;s Secrets",
    "gameCode": "pst"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Plenty O&#39;Fortune",
    "gameCode": "gtspor"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Rome and Glory",
    "gameCode": "gtsrng"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "European Roulette",
    "gameCode": "ro"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Safari Heat",
    "gameCode": "sfh"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Samba Brazil",
    "gameCode": "gtssmbr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Santa Surprise",
    "gameCode": "ssp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Secrets of the Amazon",
    "gameCode": "samz"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Sherlock Mystery",
    "gameCode": "shmst"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Silent Samurai",
    "gameCode": "sis"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Silver Bullet",
    "gameCode": "sib"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Standard Fivereel",
    "gameCode": "sfr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Streak of Luck",
    "gameCode": "sol"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Sweet Party",
    "gameCode": "cnpr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Thai Paradise",
    "gameCode": "tpd2"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Thai Temple",
    "gameCode": "thtk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Love Boat",
    "gameCode": "lvb"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Tres Amigos",
    "gameCode": "ta"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "True Love",
    "gameCode": "trl"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Ugga Bugga",
    "gameCode": "ub"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Vacation Station",
    "gameCode": "er"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "White King",
    "gameCode": "whk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wild Gambler",
    "gameCode": "gtswg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wu Long",
    "gameCode": "wlg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Zhao Cai Jin Bao",
    "gameCode": "zcjb"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Vikingmania",
    "gameCode": "gts52"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Football Carnival",
    "gameCode": "gtsfc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Three Musketeers and The Queen&#39;s Diamond",
    "gameCode": "tmqd"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Lucky Panda",
    "gameCode": "gts51"
  },
  {
    "gameType": "Scratch Cards",
    "gameGroup": "Scratchcards",
    "gameName": "3 Clowns Scratch",
    "gameCode": "tclsc"
  },
  {
    "gameType": "Scratch Cards",
    "gameGroup": "Scratchcards",
    "gameName": "Classic Slot Scratch",
    "gameCode": "scs"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Rocky",
    "gameCode": "rky"
  },
  {
    "gameType": "Card Games",
    "gameGroup": "Table Games",
    "gameName": "Blackjack Surrender",
    "gameCode": "bjsd_mh5"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Piggies and the Wolf",
    "gameCode": "paw"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Mummy",
    "gameCode": "mmy"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Queen of Pyramids",
    "gameCode": "qop"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Gold Rally",
    "gameCode": "grel"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "American Roulette",
    "gameCode": "rodz"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "Premium American Roulette",
    "gameCode": "rodz_g"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Daring Dave &amp; the Eye of Ra",
    "gameCode": "gtsdrdv"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Discovery",
    "gameCode": "dcv"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Pink Panther",
    "gameCode": "pnp"
  },
  {
    "gameType": "Classic Slots",
    "gameGroup": "Slots",
    "gameName": "Magic Slots",
    "gameCode": "ms"
  },
  {
    "gameType": "Card Games",
    "gameGroup": "Table Games",
    "gameName": "Perfect Blackjack Multihand 5",
    "gameCode": "pfbj_mh5"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Desert Treasure II",
    "gameCode": "dt2"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Top Trumps Celebs",
    "gameCode": "ttc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Diamond Valley Pro",
    "gameCode": "dv2"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fruit Mania",
    "gameCode": "fmn"
  },
  {
    "gameType": "Fixed-Odds Games",
    "gameGroup": "Others",
    "gameName": "Cashblox",
    "gameCode": "gtscb"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "Premium (European) Roulette",
    "gameCode": "ro_g"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Dragon Kingdom",
    "gameCode": "gtsdgk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Sparta",
    "gameCode": "spr"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Golden Games",
    "gameCode": "glg"
  },
  {
    "gameType": "Fixed-Odds Games",
    "gameGroup": "Others",
    "gameName": "Rubik&#39;s Riches",
    "gameCode": "gtsru"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Jazz Club",
    "gameCode": "gtsjzc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wings Of Gold Regular",
    "gameCode": "gtswng"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Dr Love More",
    "gameCode": "dlm"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Skazka Pro",
    "gameCode": "skp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wild Spirit",
    "gameCode": "wis"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "7 Seat Baccarat Live",
    "gameCode": "7bal"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Baccarat Live",
    "gameCode": "bal"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Blackjack Live",
    "gameCode": "bjl"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Casino Hold’Em Live",
    "gameCode": "chel"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Exclusive Roulette (Live VIP Roulette)",
    "gameCode": "rodl"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Live French Roulette",
    "gameCode": "rofl"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Progressive Live Baccarat",
    "gameCode": "plba"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Roulette Live",
    "gameCode": "rol"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Sic Bo Live",
    "gameCode": "sbl"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "Unlimited Blackjack Live",
    "gameCode": "ubjl"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "VIP Baccarat Live",
    "gameCode": "vbal"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fei Cui Gong Zhu",
    "gameCode": "fcgz"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Vacation Station Deluxe",
    "gameCode": "vcstd"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Cat In Vegas",
    "gameCode": "ctiv"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Riches of Don Quixote",
    "gameCode": "donq"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Chests of Plenty",
    "gameCode": "ashcpl"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Sinbad&#39;s Golden Voyage",
    "gameCode": "ashsbd"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Ice Run",
    "gameCode": "gtsir"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Great Ming Empire",
    "gameCode": "gtsgme"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fei Long Zai Tian",
    "gameCode": "gtsflzt"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Bounty of the Beanstalk",
    "gameCode": "ashbob"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fairest of Them All",
    "gameCode": "ashfta"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Sun Wukong",
    "gameCode": "gtsswk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Magical Stacks",
    "gameCode": "mgstk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Top Gun",
    "gameCode": "topg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Zhao Cai Jin Bao Jackpot",
    "gameCode": "zcjbjp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Zhao Cai Tong Zi",
    "gameCode": "zctz"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Frankie Dettori&#39;s Magic Seven Jackpot",
    "gameCode": "fdtjg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Bai Shi",
    "gameCode": "bs"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Si Xiang",
    "gameCode": "sx"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Ji Xiang 8",
    "gameCode": "gtsjxb"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Land of Gold",
    "gameCode": "lndg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Geisha Story Jackpot",
    "gameCode": "gesjp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wu Long Jackpot",
    "gameCode": "wlgjp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Silent Samurai Jackpot",
    "gameCode": "sisjp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Queen Of Wands",
    "gameCode": "qnw"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Liu Fu Shou",
    "gameCode": "kfp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Jin Qian Wa",
    "gameCode": "jqw"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of The Gods ™",
    "gameCode": "aogs"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of The Gods: Furious Four",
    "gameCode": "furf"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Five Tiger Generals",
    "gameCode": "ftg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wu Lu Cai Shen",
    "gameCode": "wlcsh"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Spud O&#39;Reillys Crops of Gold",
    "gameCode": "spud"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "No Commision Live Baccarat",
    "gameCode": "nc_7bal"
  },
  {
    "gameType": "Live Dealer Games",
    "gameGroup": "Live Dealers Casino",
    "gameName": "No Commission Mini Baccarat Live",
    "gameCode": "nc_bal"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of The Gods: Goddess of Wisdom",
    "gameCode": "athn"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Long Long Long",
    "gameCode": "longlong"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Chaoji 888",
    "gameCode": "chao"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Buffalo Blitz",
    "gameCode": "bfb"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of The Gods: King of Olympus",
    "gameCode": "zeus"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Gem Queen",
    "gameCode": "gemq"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Inca Jackpot",
    "gameCode": "aztec"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of The Gods: Prince of Olympus",
    "gameCode": "hrcls"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Halloween Fortune II",
    "gameCode": "hlf2"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Feng Kuang Ma Jiang",
    "gameCode": "fkmj"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "Fish Prawn Crab",
    "gameCode": "gtsfpc",
    "type": 'fish'
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Ri Ri Jin Cai",
    "gameCode": "ririjc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Miss Fortune",
    "gameCode": "mfrt"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of The Gods: Fate Sisters",
    "gameCode": "ftsis"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Ri Ri Sheng Cai",
    "gameCode": "ririshc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Jade Emperor",
    "gameCode": "gtsje"
  },
  {
    "gameType": "Fixed-Odds Games",
    "gameGroup": "Others",
    "gameName": "Cash Fish",
    "gameCode": "cashfi",
    "type": 'fish'
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Triple Monkey",
    "gameCode": "trpmnk"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Wild Gambler 2: Arctic Adventure",
    "gameCode": "ashwgaa"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fortune Day",
    "gameCode": "fday"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Lotto Madness",
    "gameCode": "lm"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Lie Yan Zuan Shi",
    "gameCode": "ght_a"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Time for a Deal",
    "gameCode": "ashtmd"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Adventures in Wonderland",
    "gameCode": "ashadv"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "The Glass Slipper",
    "gameCode": "ashglss"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Savannah Cash",
    "gameCode": "savcas"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Fortune Lions",
    "gameCode": "frtln"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Hot KTV",
    "gameCode": "hotktv"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "Age Of The Gods Roulette",
    "gameCode": "aogro"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Heavenly Ruler",
    "gameCode": "heavru"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Si Ling",
    "gameCode": "sling"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Super Lion",
    "gameCode": "slion"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Haoshi Cheng Shuang",
    "gameCode": "haocs"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Warriors Gold",
    "gameCode": "warg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "8 Treasures 1 Queen",
    "gameCode": "treasq"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Tiao Tiao Mao Mao",
    "gameCode": "titimama"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Egypt Spin",
    "gameCode": "egspin"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Sugar Land",
    "gameCode": "sugla"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Age of the Gods: God of Storms",
    "gameCode": "aeolus"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Heart of the Frontier",
    "gameCode": "ashhof"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Ice Cave",
    "gameCode": "ashicv"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Legacy Of The Wild",
    "gameCode": "legwld"
  },
  {
    "gameType": "Table Games",
    "gameGroup": "Table Games",
    "gameName": "Dragon Jackpot Roulette",
    "gameCode": "drgro"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Funky Monkey Jackpot",
    "gameCode": "fmjp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Great blue Jackpot",
    "gameCode": "grbjp"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Yun Cong Long",
    "gameCode": "yclong"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Xuan Pu Lian Huan",
    "gameCode": "xufe"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Gaelic Luck",
    "gameCode": "popc"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Pixel Samurai",
    "gameCode": "pisa"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Pharaoh’s Treasure Deluxe",
    "gameCode": "phtd"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Jungle Giants",
    "gameCode": "jnglg"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Epic Ape",
    "gameCode": "epa"
  },
  {
    "gameType": "Video Slots",
    "gameGroup": "Slots",
    "gameName": "Dragon Champions",
    "gameCode": "drgch"
  }
]