import React, { useRef, useState } from 'react'
import {
  Checkbox,
  CssBaseline,
  FormControlLabel
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import TextInput from 'component/textInput'
import SelectInput from 'component/selectInput'
import Joi from '@hapi/joi'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import { useTranslation } from 'react-i18next'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import config from 'config'
import numeral from 'numeral'

function Topup1({ classes, history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Deposit Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const banksOnline = {
    MYR: [{
      title: 'Maybank',
      value: 'MBB'
    }, {
      title: 'Public Bank',
      value: 'PBB'
    }, {
      title: 'CIMB Bank',
      value: 'CIMB'
    }, {
      title: 'Hong Leong Bank',
      value: 'HLB'
    }, {
      title: 'RHB Banking Group',
      value: 'RHB'
    }, {
      title: 'AmBank Group',
      value: 'AMB'
    }, {
      title: 'Bank Islam Malaysia',
      value: 'BIMB'
    }],
    IDR: [{
      title: 'Bank Central Asia',
      value: 'BCA'
    }, {
      title: 'Bank Negara Indonesia',
      value: 'BNI'
    }, {
      title: 'Bank Rakyat Indonesia',
      value: 'BRI'
    }, {
      title: 'Mandiri Bank',
      value: 'MDR'
    }, {
      title: 'CIMB Niaga',
      value: 'CIMBN'
    }]
  }
  const banks = banksOnline[config.currency]
  // console.log(banks)
  const formPayment = useRef(null)
  const [lang, updateLang] = React.useState('en-us')

  const [payment, setPayment] = useState({})
  const [requestAmount, setRquestAmount] = useState()
  const onSubmit = async ({ bankCode, amount }) => {
    // console.log(bankCode)
    const newAmount = numeral(amount).format('0,0.00')
    setRquestAmount(newAmount)
    try {
      const { user_id: userId } = store.get('user')
      const ip =  await withLoading(() => fetch(`https://api.ipify.org?format=json`).then(response => response.json()))
      const payload = await withLoading(() =>
        http.get({
          path: `api/payment/${userId}/sign`, params: {
            bankCode, amount,
            ip: ip.ip
          }
        })
      )
console.log(payload)
      setPayment(payload)
      console.log(formPayment.current)
      formPayment.current.submit()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  React.useEffect(() => {
    const lng = store.get('lang')
    switch (lng) {
      case 'en':
        updateLang('en-us')
        break
      case 'cn':
        updateLang('zh-cn')
        break
      case 'th':
        updateLang('th')
        break
      case 'ma':
        updateLang('ms-my')
        break
      case 'idn':
        updateLang('id-id')
        break
    }
  }, [])

  const schema = Joi.object().keys({
    bankCode: Joi.string().required(),
    amount: Joi.number().min(50).required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [bankCode, amount] = formCreateInputs(['bankCode', 'amount'], form)

  return (
    <div className="deposit-right-main">
      <form onSubmit={handleSubmit} className={classes.form}>
        <SelectInput input={bankCode} options={banks} label={t('SELECT_BANK')}/>
        <TextInput input={amount} label={t('TOPUP_AMOUNT')}/>
        <FormControlLabel
          className='form-label-control'
          control={<Checkbox style={{ color: '#ffaf50' }} value="remember" color="primary"/>}
          label={t('AGREEMENT')}
        />
        {loading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
          >
            <PropagateLoader
              sizeUnit="px"
              size={20}
              color="#f50057"
              loading={loading}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className="text-center formGroup_btn">
              <button disabled={submitting} type="submit" className="col-md-8 col-12 reload"><span></span>{t('TOPUP')}
              </button>
            </div>
          </React.Fragment>
        )}
      </form>

      <form style={{ display: 'none' }} method="post" action="https://api.mxpay.asia/WebForm/Deposit.aspx"
            ref={formPayment}>
        <input type="hidden" name="MerchantCode" value={payment.MerchantCode}/>
        <input type="hidden" name="TransNum" value={payment.TransNum}/>
        <input type="hidden" name="Currency" value={payment.currency}/>
        <input type="hidden" name="Amount" {...amount.input} value={requestAmount}/>
        <input type="hidden" name="PaymentDesc" value=""/>
        <input type="hidden" name="FirstName" value={payment.member_id}/>
        <input type="hidden" name="LastName" value=""/>
        <input type="hidden" name="EmailAddress" value=""/>
        <input type="hidden" name="PhoneNum" value=""/>
        <input type="hidden" name="Address" value=""/>
        <input type="hidden" name="City" value=""/>
        <input type="hidden" name="State" value=""/>
        <input type="hidden" name="Country" value="MYS"/>
        <input type="hidden" name="Postcode" value=""/>
        <input type="hidden" name="MerchantRemark" value=""/>
        <input type="hidden" name="CheckString" value={payment.CheckString}/>
      </form>

      <ErrorDialog/>
    </div>
  )
}

export default withStyles(styles)(Topup1)
