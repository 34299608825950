import React, { useState, useEffect } from 'react'
import config from 'config'
import store from 'store'
import http from 'service/http'
// import socket from 'service/socket'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

import useLoading from '../feature/loading/hook'
import useDialog from '../feature/dialog/hook'

const CurrentBalance = () => {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(true)
  const [gameBalances, updateGameBalances] = useState([])
  const [user, updateUser] = useState({})
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Your Account'
  })

  const fetchAccount = () => {
    const { user_id: userId } = store.get('user')
    setTimeout(async () => {
      const [userResp] = await withLoading(() => Promise.all([
        http.get({ path: 'me' }),

      ]))

      store.set('user', userResp)
      updateUser(userResp)
      console.log(userResp.balanceGame)
      updateGameBalances(userResp.balanceGame.filter(x => x.balance).map(t => {
        return t.balance
      }))
    }, 2000)

  }

  useEffect(() => {
    // socket.on('messages', () => {
    //   fetchAccount()
    // })
    fetchAccount()
  }, [])

  const onPullOut = async () => {
    const { user_id: userId } = store.get('user')
    const tasks = []
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== 'asiagaming') {
        const providerCode = game_code === 'ag_galaxy' ? '?ProviderId=AG' : ''
        tasks.push(http.post({
          path: `users/${userId}/game/${gameId}/withdraw${providerCode}`, payload: {
            amount: balance
          }
        }))
      }
    })
    await withLoading(() => Promise.all(tasks))
    showDialogWithMessage('Pull out done!')
    fetchAccount()
  }


  const { user_money: balance, } = user

  return (
    <div
      className="deposit-left-main profile-main"
      style={{ background: '#fff' }}
    >
      <Dialog />
      <div className="">
        <div style={{ float: 'left', fontStyle: 'normal', fontWeight: 'bold' }}>
          <span>Main Wallet ({config.currency})</span>
          <p style={{ fontSize: 18 }}>{numeral(balance).format('0,0.00')}</p>
        </div>
        <div style={{ float: 'right', textAlign: 'right', paddingRight: 5 }}>
          <img
            src="../images/icon/Artboard.png"
            alt=""
            style={{ width: 50, marginRight: 10 }}
          />
          <br />
          <a
            className="reload"
            style={{ cursor: 'pointer', padding: '0' }}
            onClick={() => onPullOut()}
          >
            {t('REFRESH')}
          </a>
        </div>
      </div>
      <div className="list_current" style={{ width: '100%' }}>
        {loading ? (
          <div
            style={{ width: '100%', textAlign: 'center' }}
            className="pro_title"
          >
            {t('LOADING')} ...
          </div>
        ) : (
          <>
            {[...gameBalances]
              .filter(
                x =>
                  x.game_code !== 'asiagaming' &&
                  x.game_code !== 'sexy_baccarat' &&
                  x.game_code !== 'BGAWS'
              )
              .map(item => (
                <div key={item.gameName} className="item_current display_flex">
                  {item.game_code !== 'sexy_baccarat' && (
                    <p className="rs">
                      {item.gameName != 'sbo'
                        ? item.game_code !== 'maxbet'
                          ? item.gameName
                          : 'M-Sport'
                        : 'SBO & Asia Gaming'}
                    </p>
                  )}
                  <span>{`${numeral(item.balance).format(
                    '0,0.00'
                  )} ${config.currency || 'MYR'}`}</span>
                </div>
              ))}
            {[...gameBalances]
              .filter(
                x =>
                  x.game_code !== 'asiagaming' &&
                  x.game_code === 'sexy_baccarat'
              )
              .map(item => (
                <div key={item.gameName} className="item_current display_flex">
                  <p className="rs">
                    <p>(AWC Game)</p>
                    <p>
                      Slot game = FC, Spade gaming, PP, Red Tiger, JILI,Pocket
                      Games Soft.
                    </p>
                    <p>Live Casino = Sexy, Venus, BG.</p>
                    <p>Sportsbook = E1SPORT.</p>
                  </p>
                  <span>{`${numeral(item.balance).format(
                    '0,0.00'
                  )} ${config.currency || 'MYR'}`}</span>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  )
}

export default CurrentBalance;