/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import DateInput from 'component/dateInput'
import SelectInput from 'component/selectInput'
import Bottom from 'component/bottom'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import store from 'store'
import Header from 'component/header'
import { useTranslation } from 'react-i18next'
import factoryHeader from './factoryHeader'
import factoryBody from './factoryBody'
import styles from './style'
import useLoading from '../loading/hook'
import useDialog from '../dialog/hook'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import numeral from 'numeral'

function Histories({ classes, history }) {

  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [games, updateGame] = useState([])
  const [histories, updateHistories] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [selectedType, updateSelectedType] = React.useState(null)
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Histories Warning',
    btnLabel: 'Got it'
  })
  useEffect(() => {
    http.get({ path: `games` }).then(rs => {
      const gamess = rs.map(r => {
        return {
          value: r.game_code,
          title: r.name
        }
      })
      // console.log(gamess)
      gamess.unshift({
        value: 'all',
        title: 'All games'
      })
      updateGame(gamess)
    })
  }, [])
  const onSubmit = async ({ type, fromDate, toDate }) => {
    try {
      const user = store.get('user')
      const params = {
        fromDate: moment(fromDate).format('YYYY-MM-DD 00:00:00'), toDate: moment(toDate).format('YYYY-MM-DD 23:59:59'), agent: user.parent_id, username: user.user_account_no
      }
      // console.log(params)
      const response = await withLoading(() =>
        http.get({ path: `admin/report/${type}/winlose`, params })
      )
      updateSelectedType(type)
      updateHistories(response)
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    type: Joi.string()
      .label('Game')
      .required(),
    fromDate: Joi.date()
      .label('From Date')
      .required(),
    toDate: Joi.date()
      .label('To Date')
      .required()
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      type: 'all',
    },
  })

  const [type, fromDate, toDate] = formCreateInputs(['type', 'fromDate', 'toDate'], form)

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, histories.length - page * rowsPerPage)

  return (
    <div className="col8">
      <div className="title_recoment tile_deposit">{t('REPORTS')}</div>
      <div className="reportW">
        {/*<h3 className="rs h3_deposit">Deposit Report</h3>*/}
        <div className="reportMain">
          <form onSubmit={handleSubmit}>
            <div className="report-search display_flex">
              <div style={{ justifyContent: 'unset' }} className="form-report display_flex">
                <label for="">{t('SELECT_GAME')}</label>
                <div className="react-datepicker-wrapper">
                  <div className="react-datepicker__input-container">
                    <select
                      name=""
                      className="input_block"
                      style={{ padding: '0px 10px', color: 'black', background: 'white', height: '30px' }}
                      {...type.input}
                    >
                      {games.map(({ title, value }) => (
                        <option style={{ color: 'black' }} value={value}>{title}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                <label for="">{t('FROM_DATE')}</label>
                {/* <input type="text" className="input_block" /> */}
                <DatePicker
                  // {...fromDate.input}
                  onChange={(date) => fromDate.input.onChange({ target: { value: date } })}
                  selected={fromDate.input.value}
                // selected={this.state.startDate}
                // onChange={this.handleChange}
                />
              </div>
              <div style={{ justifyContent: 'unset' }} className="form-report form-report-todate display_flex">
                <label for="">{t('TO_DATE')}</label>
                {/* <input type="text" className="input_block" /> */}
                <DatePicker
                  // {...toDate.input}
                  onChange={(date) => toDate.input.onChange({ target: { value: date } })}
                  selected={toDate.input.value}
                // selected={this.state.startDate}
                // onChange={this.handleChange}
                />
              </div>
              <div className="report-submit">
                <button disabled={submitting} type="submit" className="reload"><span></span>{t('REPORTS')}</button>
              </div>
            </div>
            <div className="reportTable">
              <div className="table-responsive">
                <table className="rs table table-report">
                  <tr>
                    <td>{t('GAME')}</td>
                    <td>{t('TOTAL_BET')}</td>
                    <td>{t('WIN_LOSE')}</td>
                  </tr>
                  {loading ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
                    >
                      <PropagateLoader
                        sizeUnit="px"
                        size={20}
                        color="#f50057"
                        loading={loading}
                      />
                    </div>
                  ) : (
                      <>
                        {histories.totals ? (
                          <tr>
                            <td>{selectedType}</td>
                            <td>{numeral(histories.totals.totalBetAmount).format('0,0.000')}</td>
                            <td>{numeral(histories.totals.totalWinLose).format('0,0.000')}</td>
                          </tr>
                        ) : null}
                      </>
                    )}
                </table>
              </div>
              {/* <div className="text-right">
                <div className="reportNavigation">
                  <a href="" className="reportNavi-left"></a>
                  <a href="" className="reportNavi-right"></a>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <Dialog />
    </div>
  )
  // return (
  //   <div className={classes.root}>
  //     <Header history={history} title={t('REPORTS')}/>
  //     <div className={classes.container}>
  //       <CssBaseline />
  //         <form onSubmit={handleSubmit} className={classes.form}>
  //         <SelectInput input={type} options={games} label={t('SELECT_GAME')}/>
  //           <DateInput input={fromDate} type='date' label={t('FROM')} InputLabelProps={{
  //             shrink: true,
  //           }}/>
  //           <DateInput input={toDate} type='date' label={t('TO')} InputLabelProps={{
  //             shrink: true,
  //           }}/>
  //           {loading ? (
  //             <div
  //               style={{ display: 'flex', justifyContent: 'center', margin: 15 }}
  //             >
  //               <PropagateLoader
  //                 sizeUnit="px"
  //                 size={20}
  //                 color="#f50057"
  //                 loading={loading}
  //               />
  //             </div>
  //           ) : (
  //             <Button
  //               type="submit"
  //               fullWidth
  //               variant="contained"
  //               color="primary"
  //               disabled={submitting}
  //               className={classes.submit}
  //             >
  //               <img alt='iplay' style={{width: 30, marginRight: 15}} src={`${process.env.PUBLIC_URL}/icon/icon-history.svg`} />
  //               <Typography variant="button" color="inherit" className={classes.button}>
  //                 {t('SUBMIT')}
  //               </Typography>
  //             </Button>
  //           )}
  //         </form>
  //         {histories.totals
  //           ? <div>
  //               <div className="table-wrapper">
  //                 <Table className="table" aria-labelledby="tableTitle">
  //                   <TableHead style={{color: '#ffaf50', fontWeight: 'bold'}}>
  //                   {factoryHeader['report']}
  //                   </TableHead>
  //                   <TableBody>
  //                     {factoryBody['report'](selectedType, histories.totals)}
  //                   </TableBody>
  //                 </Table>
  //               </div>

  //             </div>
  //           : null
  //         }
  //       </div>
  //     <Bottom history={history} />
  //     <Dialog/>
  //   </div>
  // )
}

export default Histories
