/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import Header from 'component/header'
import CloseIcon from '@material-ui/icons/Close'
import { map, isEmpty } from 'ramda'
import Bottom from 'component/bottom'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import useLoading from '../loading/hook'
import styles from './style'
import SLOTS from './constant'
import config from 'config'
import gameData from '../../constants/gameData'
import queryString from 'query-string'
import GameAccount from '../../component/gameAccount'
import component from '../../component/'

const { useDialog } = component

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: `lobbymegarelease://lobbymegarelease?account=${account}&password=${password}`,
    2: `lobbykiss://lobbykiss?account=${account}&password=${password}`,
    8: `joker://www.joker123.net/mobile?data=${account}`,
    1: ''
  }

  return proxy[id]
}

function SlotGameComp({ history, classes }) {
  const {
    location: { search }
  } = history
  const [loading, withLoading] = useLoading(false)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const [gameSelected, updateGameSelected] = React.useState({})
  const [games, setGames] = React.useState([])
  const { user_id: userId } = store.get('user') || { user_id: undefined }
  const { game, html55 } = queryString.parse(search)
  const gamesSetup = async () => {
    const gameList = await http.get({ path: 'games' })
    setGames(gameList)
    return gameList
  }

  const loginToGame = async (
    { src, label, route, id, redirect, target, html5, code },
    gameList = null
  ) => {
    if (target === '_blank') {
      window.open(route)
    }
    // alert(route)

    if (redirect) {
      history.push(route)
      return
    }
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'Please login first !' })
      return
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: 'System Maintenance ' })
      return
    }
    updateGameSelected({ label, src, route, id })
    try {
      const providerCode = code === 'asiagaming-galaxy' ? '?ProviderId=AG' : ''
      const { balance, err, game_password: password } = await withLoading(() =>
        http
          .post({
            path: `users/${userId}/game/logout${providerCode}`,
            payload: {
              gameLoginId: id
            }
          })
          .catch(error => {
            if (error.message !== 'Not enough money !') {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message }
            }
            return true
          })
          .then(() =>
            http.get({
              path: `users/${userId}/games/${id}/balance`,
              params: { ProviderId: 'AG' }
            })
          )
      )

      let deeplinkAcc = balance.account
      if (code === 'joker' && html5 === 0)
        deeplinkAcc = await http
          .get({
            json: false,
            path: `games/joker/login`,
            params: { GameCode: 0, mode: 'M' }
          })
          .then(rs => JSON.parse(rs).sign)
      const outDeeplink = generateDeeplink({
        id,
        account: deeplinkAcc,
        password
      })
      updateGameSelected({
        label,
        src,
        route,
        id,
        balance: balance.balance,
        account: balance.account,
        err,
        deeplink: outDeeplink,
        password,
        html5,
        code
      })
      console.log(html5, route)

      if (
        code !== 'xe88' &&
        (html5 === 1 || !outDeeplink || outDeeplink === '')
      )
        history.push(route)
    } catch (e) {
      showDialogErrorWithMessage(e.message)
    }
  }
  const fetchGame = async () => {
    const gameList = await gamesSetup()

    if (game) {
      if (html55 == 1) {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code
        } = SLOTS.find(x => x.code === game && x.html5 === html55)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        )
      } else {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code
        } = SLOTS.find(x => x.code === game)
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        )
      }
    }
  }
  React.useEffect(() => {
    // if (userId) {
    //   http.post({ path: `users/${userId}/sync-game` })
    // }

    fetchGame()
  }, [])

  const handleClose = () => {
    updateGameSelected({})
  }
  const { t } = useTranslation()
  return (
    <>
      <div className="pages-card">
        {map(
          ({
            src,
            classshow,
            label,
            route,
            id,
            redirect,
            target,
            deeplink,
            idrActive,
            html5,
            code
          }) => (
            <div
              key={id}
              cols={2.5}
              onClick={() =>
                loginToGame({
                  src,
                  classshow,
                  label,
                  route,
                  id,
                  redirect,
                  target,
                  deeplink,
                  html5,
                  code
                })
              }
            >
              <div className="pages-item">
                <img src={src} alt={src} />
                <div className={classshow}>
                  {classshow !== '' && (
                    <>
                      {classshow === 'pages-icon-hot' ? 'HOT' : 'Comming Soon'}
                    </>
                  )}
                </div>
              </div>
            </div>
          ),
          SLOTS
        )}
      </div>
      <Dialog
        fullScreen
        open={!isEmpty(gameSelected)}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {gameSelected.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            background: '#23232A',
            padding: 25,
            marginTop: 70,
            height: 'calc(100vh - 70px)',
            overflow: 'auto'
          }}
        >
          <Card
            style={{
              background: 'none',
              border: '1px solid #ffaf50',
              borderRadius: '16px',
              borderRadius: '16px',
              maxWidth: '400px',
              margin: '0 auto'
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                image={gameSelected.src}
                title="Contemplative Reptile"
              />
              <CardContent style={{ textAlign: 'center' }}>
                {gameSelected.err ? (
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: 'bold', color: '#ffaf50' }}
                  >
                    {gameSelected.err}
                  </Typography>
                ) : loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 15
                    }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <GameAccount
                    account={gameSelected.account}
                    password={gameSelected.password}
                    deeplink={gameSelected.deeplink}
                    route={gameSelected.route}
                    html5={gameSelected.html5}
                    history={history}
                    balance={gameSelected.balance}
                  ></GameAccount>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Dialog>
      <ErrorDialog />
    </>
  )
}

export default withStyles(styles)(SlotGameComp)
