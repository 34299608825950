/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'

import store from 'store'
import http from 'service/http'
import component from 'component'
var currentgame;

function Login({ history }) {
  const query = new URLSearchParams(history.location.search)
  const code = query.get('code')
  // console.log(history.location, code)
  const mobiledomain = 'ld176988.com'
  const systemidvar = "424";

  window.iapiSetCallout('GetTemporaryAuthenticationToken', calloutGetTemporaryAuthenticationToken);
  window.iapiSetCallout('Logout', calloutLogout);
  window.iapiSetCallout('GetLoggedInPlayer', calloutLoggedInPlayer);

  window.iapiGetLoggedInPlayer(1);

  function opengame(game) {
    var realMode = 1;
    currentgame = game;
    window.iapiRequestTemporaryToken(realMode, systemidvar, 'GamePlay');
  }
  const launchMobileClient = async (temptoken) => {
    const { user_id: userId } = store.get('user')
    const accounts = await http.post({ path: `users/${userId}/sync-game` })
    const PTAccount = accounts.find(({ game_id: id }) => id === 4)
    const clientUrl = '//hub.' + mobiledomain + '/igaming/?gameId=' + currentgame + '&real=1&username=' + PTAccount.game_account.toUpperCase() + '&lang=en&tempToken=' + temptoken + '&lobby='
      + window.location.href.substring(0, window.location.href.lastIndexOf('/') + 1) + 'login&deposit='
      + window.location.href.substring(0, window.location.href.lastIndexOf('/') + 2) + 'topup'
    window.open(clientUrl);
    // window.open(clientUrl, '_blank');
  }

  function calloutGetTemporaryAuthenticationToken(response) {
    if (response.errorCode) {
      alert("Token failed. " + response.playerMessage + " Error code: " + response.errorCode);
    }
    else {
      launchMobileClient(response.sessionToken.sessionToken);
    }
  }

  function calloutLoggedInPlayer(response) {
    console.log(4444, response)
  }

  function logout(allSessions, realMode) {
    window.iapiLogout(allSessions, realMode);
  }
  const checkWith = () => {
    let width = 4
    let height = 110
    const e = document.getElementById('root')
    // if(e.clientWidth > 576) width = 4
    if(e.clientWidth > 720) {
      width = 5
      height = 140
    }
    if(e.clientWidth > 960) {
      width = 5
      height = 140
    }
    if(e.clientWidth > 1140) {
      width = 6
      height = 190
    }
    return {width, height}
  }
  function calloutLogout(response) {
    sessionStorage.clear();
    alert("Logout OK");
    document.location = "/";
  }
  const accessFishing = (code) => {
    window.location = `http://cache.download.banner.winforfun88.com/casinoclient.html?language=en&game=${code}`
  }
  const initialPT = async () => {
    try {
        const calloutLogin = (response) => {
          if (response.errorCode) {
            console.log(111, response)
          }
          else {
            if (code) {

              accessFishing(code)
            }
            console.log("Login OK, you will be redirected to play console", code, response)
          }
        }
        const PTAccount = {
          game_account: query.get('game_account'), //159001MYR0123450012
          game_password: query.get('game_password'),
        }

        if(PTAccount.game_account) {
          // console.log(121212, PTAccount, PTAccount.game_account.toUpperCase())
          window.iapiSetCallout('Login', calloutLogin)
          window.iapiSetClientPlatform("mobile&deliveryPlatform=HTML5")
          // alert(PTAccount.game_account.toUpperCase())
          window.iapiLogin(PTAccount.game_account.toUpperCase(), PTAccount.game_password, 1, 'en')
        }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initialPT()

  }, [])

  return (
    <div className="col-12 col-md-8 col-md-8">
      Hello
    </div>
  )
}

export default Login
