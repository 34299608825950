export default  [
  {
    value: "BKKB",
    title: "Bangkok Bank"
  },
  {
    value: "BAAC",
    title: "Bank for Agriculture and Agricultural Cooperatives	"
  },
  {
    value: "KSAB",
    title: "Bank of Ayudhya (Krungsri)"
  },
  {
    value: "CIMT",
    title: "CIMB Thai Bank"
  },
  {
    value: "GSBA",
    title: "Government Savings Bank	"
  },
  {
    value: "KSKB",
    title: "Kasikorn Bank	"
  },
  {
    value: "Kiatnakin Bank",
    title: "Kiatnakin Bank"
  },
  {
    value: "KTBA",
    title: "Krung Thai Bank"
  },
  {
    value: "SCBB",
    title: "Siam Commercial Bank"
  },
  {
    value: "SCBT",
    title: "Standard Chartered Bank (Thai)"
  }
]