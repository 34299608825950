export default [ {
  src: '/img/games/playtech.png',
  code: 'playtech',
  label: 'Playtech',
  route: '/authenticate/playtech/cashfi',
  idrActive: 1,
  id: 4,
  // target: "_blank"
}, {
  src: '/images/gameLogo/slot-sg.png',
  code: 'spadegaming',
  label: 'SpadeGaming',
  route: 'spadegaming-fishing',
  id: 7,
}, {
  src: '/img/games/BGfishing.png',
  code: 'BG',
  label: 'BG',
  route: '/bg-fh',
  id: 24
}, {
  src: '/img/games/JDB.png',
  code: 'JDB',
  label: 'JDB',
  route: '/jdb',
  id: 25
}, {
  src: '/img/games/joker.png',
  code: 'joker',
  label: 'Joker',
  route: 'joker-fishing',
  id: 8
}]