/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  Slide
} from '@material-ui/core'
import { map } from 'ramda'
import store from 'store'
import { Menu } from '@material-ui/icons'
import Sidebar from 'component/drawer'
import Login from 'feature/login'
import { useTranslation } from 'react-i18next'
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles'
import './style.css'
import config from '../../config'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LANGS = ['en', 'cn', 'idn', 'ma', 'th', 'mya']

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffaf50'
    }
  },
  typography: { useNextVariants: true }
})

const styles = {
  icon: {
    color: 'white'
  }
}

function Header({ classes, history, title, rightSidebar = false }) {

  const token = store.get('token')
  const [lang, updateLang] = useState(store.get('lang') || config.lang)
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: rightSidebar
  })
  const { i18n } = useTranslation()
  const [openLang, setOpenLang] = React.useState(false)

  const [openLangModal, updateOpenLangModal] = React.useState(false)

  function handleClickOpenLangModal() {
    updateOpenLangModal(true)
  }

  function handleCloseLangModal() {
    updateOpenLangModal(false)
  }

  function handleChangeLang(event) {
    const {
      target: { value }
    } = event
    updateLang(value)
    store.set('lang', value)
    updateOpenLangModal(false)
    return i18n.changeLanguage(value)
  }

  function handleCloseLang() {
    setOpenLang(false)
  }

  function handleOpenLang() {
    setOpenLang(true)
  }

  const onToggleDrawer = (side, open) => () => {
    history.push(history.location.pathname)
    setState({ ...state, [side]: open })
  }

  return (
    <header>
      <div className="w_1220 display_flex">
        <div className="col2">
          <a href="" className="logo"></a>
          <a href="" className="head-chat show_ipad">
            <span></span>Live chat
          </a>
        </div>
        <div className="col8">
          <div className="head-marrqe">
            Announcement time: Agricultural Bank of China will perform banking
            system
          </div>
        </div>
        <div className="col2 col2-right">
          <ul className="rs list-inline head-right">
            <li className="list-inline-item">
              <a href="" className="head-ico icon_notice">
                <img
                  src="images/icon_notice.svg"
                  className="img-fluid"
                  alt=""
                />
                <span className="">News</span>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="" className="head-ico icon_mess">
                <img src="images/icon_mess.svg" className="img-fluid" alt="" />
                <span className="">Forum</span>
              </a>
            </li>
            <li className="list-inline-item">
              <div className="dropdown head-drop-usa">
                <a
                  className="drop-btn flex_center icon_caret"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="images/img_american.png"
                    className="img-fluid"
                    alt=""
                    width="18"
                    height="14"
                  />
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    <img
                      src="images/img_american.png"
                      className="img-fluid"
                      alt=""
                      width="18"
                      height="14"
                    />{' '}
                    American
                  </a>
                  <a className="dropdown-item" href="#">
                    <img
                      src="images/img_american.png"
                      className="img-fluid"
                      alt=""
                      width="18"
                      height="14"
                    />{' '}
                    American
                  </a>
                  <a className="dropdown-item" href="#">
                    <img
                      src="images/img_american.png"
                      className="img-fluid"
                      alt=""
                      width="18"
                      height="14"
                    />{' '}
                    American
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <div className="show_ipad">
            <div className="menu_sb1 head-menu_sb1">
              <a href="" className="icon_top1 mr-1">
                WithDraw
              </a>
              <a href="" className="icon_top2 mr-1">
                Deposit
              </a>
              <a href="" className="icon_top3">
                Report
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default withStyles(styles)(Header)
