/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography
} from '@material-ui/core'
import store from 'store'
import { GetApp } from '@material-ui/icons'
import Header from 'component/header'
import { map, pipe, filter } from 'ramda'
import config from 'config'
import http from 'service/http'
import Bottom from 'component/bottom'
import { useTranslation } from 'react-i18next'
import useLoading from '../loading/hook'
import styles from './style'

const parseDownload = ({ game_ui_url: download, game_code: gameCode }) => {
  switch (gameCode) {
    case 'playtech':
      const { Live, Slot } = JSON.parse(download)
      return [{
        label: 'Live App',
        url: Live
      }, {
        label: 'Slot App',
        url: Slot
      }]
    case 'joker':
    case 'xe88':
      const { app } = JSON.parse(download)
      return [{
        label: 'App',
        url: app
      }]
    default:
      return [{
        label: 'App',
        url: download
      }]
  }
}
function home({ history, classes }) {
  const { t } = useTranslation()
  const [, withLoading] = useLoading(false)

  const [games, updateGames] = useState([])

  const fetchData = async () => {
    const { user_id: userId } = store.get('user')
    const [gameList, gameSynced] = await withLoading(() => Promise.all([
      http.get({ path: 'games' }),
      http.post({ path: `users/${userId}/sync-game` }),
      // http.get({ path: 'games/11/login' }).catch(err => ({ downloadUrl: undefined }))
    ]))

    const gamesInfo = gameSynced.map(item => {
      const found = gameList.find(({ id }) => id === item.game_id)
      // console.log(found)
      if (!found) {
        return item
      }
      return {
        ...item,
        name: found.name,
        download: parseDownload(found),
        gameCode: found.game_code
      }
    })
      .filter(({ gameCode }) => config.gamesAvailable.includes(gameCode))
    // console.log(gamesInfo)
    updateGames(gamesInfo)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="col8">
      <div className="title_recoment tile_deposit">{t('DOWNLOAD')}</div>
      <div className={`row`}>
        {
          pipe(
            filter(({ name }) => !config.gameHTML5.includes(name)),
            map(({ game_id: gameId, game_account: gameAccount, game_password: gamePassword, download, name, game_sufix: gameSufix, gameCode }) => (
              <div key={gameId} className="col-12 col-sm-6">
                <Card className={classes.card} key={gameId}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      className={classes.media}
                      image={`${process.env.PUBLIC_URL}/img/${gameCode}.png`}
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{ textAlign: 'center' }}>
                      <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
                        {name}
                      </Typography>
                      <Typography component="p">
                        <span style={{ fontWeight: 'bold', color: '#ffaf50' }}>Username: {gameAccount + (gameCode != 'mega' && gameSufix ? gameSufix : '')}</span>}
                      </Typography>
                      <Typography component="p">
                        <span style={{ fontWeight: 'bold', color: '#ffaf50' }}>Password: {gamePassword}</span>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  {map(({ label, url }) => (<CardActions key={label} className={classes.actions} disableActionSpacing>
                      <IconButton aria-label="Add to favorites" style={{ marginLeft: 'auto' }}>
                        <GetApp style={{ color: '#ffaf50' }} />
                        <Typography component="label">
                          {
                            // name === 'mega' ? (<a target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ffaf50' }} href={'http://m.mega689.com/mega/index.html'}>{`${t('CLICK_DOWNLOAD')} ${label}`}</a>) :

                            (<a target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ffaf50' }} href={url}>{`${t('CLICK_DOWNLOAD')} ${label}`}</a>)
                          }
                        </Typography>
                      </IconButton>
                    </CardActions>), download)
                  }

                </Card>
              </div>
            )))(games)
        }
      </div>
    </div>
  )
}
export default withStyles(styles)(home)
