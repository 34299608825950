/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import store from 'store'
import { useTranslation } from 'react-i18next'
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import config from '../../config'
import http from 'service/http'
import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue, get, child } from "firebase/database"

import CompCommon from 'feature/CompCommon'
import NewAccountMb from 'feature/NewAccountMb'
import NewBankingMb from 'feature/NewBankingMb'
import PopupCommon from '../dialog/PopupCommon'
import NewDeposit from 'feature/NewDeposit'
import NewHistories from 'feature/NewHistories'
import useLoading from '../loading/hook'
import numeral from 'numeral'
import StyledButton from '../button/syledButton'
import ModalHeader from './ModalHeader'
import './Styles.css'

const firebaseConfig = {
  "type": "service_account",
  "project_id": config.fireBaseProjectId,
  "client_email": config.fireBaseClientEmail,
  "client_id": config.fireBaseClientId,
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": `https://www.googleapis.com/robot/v1/metadata/x509/${config.fireBaseClientEmail}`,
  "universe_domain": "googleapis.com",
  databaseURL: config.fireBaseDatabaseURL
}

const app = initializeApp(firebaseConfig)
const database = getDatabase(app);
const cartRef = ref(database, 'kissvip/users');
const LANGS = [
  { key: 'en', name: 'English' },
  { key: 'cn', name: '中文' },
  { key: 'idn', name: 'Bahasa Indonesia' },
  { key: 'ma', name: 'Bahasa Malaysia' },
  { key: 'th', name: 'ภาษาไทย' },
  { key: 'mya', name: 'မြန်မာ' }
]

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffaf50'
    }
  },
  typography: { useNextVariants: true }
})

const styles = {
  icon: {
    color: 'white'
  }
}

function Header({ classes, history, title, rightSidebar = false }) {
  const { t } = useTranslation()
  const user = store.get('user')
  const [userRespeStore, setUserRespeStore] = useState(user)
  const [lang, updateLang] = useState(store.get('lang') || config.lang)
  const [announcement, updateData] = useState({
    en: '',
    cn: '',
    th: '',
    idn: '',
    ma: '',
    mya: ''
  })
  const [isShowMenu, setShowMenu] = useState(false)

  const { i18n } = useTranslation()
  const initial = async () => {
    const data = await http.get({ path: 'config' })
    if (data && data.announcement && data.announcement !== '') {
      const anno = JSON.parse(data.announcement)
      updateData({
        en: anno.en,
        cn: anno.cn,
        th: anno.thai,
        idn: anno.in,
        ma: anno.my,
        mya: anno.myanr
      })
      // document.getElementsByTagName("marquee")[0].start()
    }
  }
  const [isShowPopupDep, setShowPopupDep] = useState(false)
  const [isShowPopupHistory, setShowPopupHistory] = useState(false)
  const [clickedOutside, setClickedOutside] = useState(false)
  const [userInfo, updateUser] = useState({})
  const [loading, withLoading] = useLoading(false)
  const [isShowCompAcc, setShowCompCommonAcc] = useState(false)
  const [isShowCompBanking, setShowCompCommonBanking] = useState(false)
  const myRef = useRef()
  const [gameBalances, updateGameBalances] = useState([])

  const handleClickOutside = e => {
    if (myRef && myRef.current && !myRef.current.contains(e.target)) {
      setClickedOutside(true)
      setShowMenu(false)
    }
  }
  const handleClickInside = () => {
    setClickedOutside(false)
  }
  const fetchAccount = async () => {
    try {
      setTimeout(async () => {
        const [userResp] = await withLoading(() =>
          Promise.all([http.get({ path: 'me' })])
        )
        setUserRespeStore(userResp)
        store.set('user', userResp)
        updateUser(userResp)
        // console.log(config.gamesAvailable)
        updateGameBalances(
          userResp.balanceGame
            .filter(
              x =>
                x.balance && config.gamesAvailable.includes(x.balance.game_code)
            )
            .map(t => {
              return t.balance
            })
        )
      }, 2000)
    } catch (error) {}
  }
  
  function handleChangeLang(language) {
    updateLang(language)
    store.set('lang', language)
    return i18n.changeLanguage(language)
  }
  useEffect(() => {
    initial()
    if (user) {
      fetchAccount()
      onValue(cartRef, (snapshot) => {
        const data = snapshot.val();
        if( data && data.to.toLowerCase() === user.user_account_no.toLowerCase()) {
          console.log(data);
          fetchAccount()
        } else {
          console.log('Data not found');
        }
      });
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])
  // countdonw
  const [currentDateTime, setCurrentDateTime] = useState(new Date())
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date())
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  const formatNumber = number => {
    return number < 10 ? '0' + number : number
  }

  const onShowMenu = () => {
    setShowMenu(!isShowMenu)
  }
  const updateShowDialog = (value, type) => {
    switch (type) {
      case 'history':
        setShowPopupHistory(value)
        break
      case 'deposit':
        setShowPopupDep(value)
        break
    }
  }
  
  return (
    <div className="new-header-wrapper">
      <div className="new-header-styled-header">
        <div className="new-header-styled-header-left">
          <div className="styled-logo-header">
            <div className="new-header-styled-show-menu" ref={myRef}>
              <a onClick={onShowMenu} type="button">
                <img
                  style={{ width: '36px', height: '36px' }}
                  src="./images/header/line.png"
                  alt="icon"
                />
              </a>
              <ModalHeader
                isShowMenu={isShowMenu}
                history={history}
                onShowMenu={onShowMenu}
              />
            </div>
            <div className="new-header-styled-logo">
              <a onClick={() => history.push('/')}>
                <img
                  className="new-header-styled-img-logo"
                  src="./images/logo/logovip.png"
                  alt="logo"
                />
              </a>
            </div>
            <div className="new-header-styled-showmb">
              <div className="new-header-styled-mb">
                {user ? (
                  <div className="styled-account-header">
                    <div
                      className="styled-account"
                      // onClick={() => history.push('/account')}
                      onClick={() => {
                        user && user.user_id
                          ? history.push('/account')
                          : history.push('/home')
                      }}
                    >
                      <img src="./images/header/use.png" alt="use" />
                    </div>
                  </div>
                ) : (
                  <div className="styled-account-header">
                    <p
                      className="new-header-link"
                      onClick={() => history.push('/playtech/login')}
                    >
                      Login
                    </p>
                    <StyledButton
                      onClick={() => history.push('/register')}
                      background="#f78212"
                    >
                      Join Now
                    </StyledButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="new-header-styled-link">
            <p
              className="new-header-link"
              onClick={() => history.push('/slot')}
            >
              Slots
            </p>
            <p
              className="new-header-link"
              onClick={() => history.push('/casino')}
            >
              Casino
            </p>
            <p
              className="new-header-link"
              onClick={() => history.push('/lottery')}
            >
              Lottery
            </p>
            <p
              className="new-header-link"
              onClick={() => history.push('/sports')}
            >
              Sports
            </p>
            {/* <Link onClick={() => history.push('/fishing')}>Fishing</Link> */}
          </div>
          <div className="new-header-styled_show">
            <div className="new-header-styled-mb">
              {user ? (
                <div className="styled-account-header">
                  <div
                    className="styled-account"
                    onClick={() => history.push('/account')}
                  >
                    <img src="./images/header/use.png" alt="use" />
                  </div>
                </div>
              ) : (
                <div className="styled-account-header">
                  <p
                    className="new-header-link"
                    onClick={() => history.push('/playtech/login')}
                  >
                    Login
                  </p>
                  <StyledButton
                    onClick={() => history.push('/register')}
                    background="#f78212"
                  >
                    Join Now
                  </StyledButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="new-header-styled-header-right">
          <div className="new-header-form-no-login">
            <div className="new-header-styled-date-time">{`${formatNumber(
              currentDateTime.getDate()
            )}/${formatNumber(
              currentDateTime.getMonth() + 1
            )}/${currentDateTime.getFullYear()} ${formatNumber(
              currentDateTime.getHours()
            )}:${formatNumber(currentDateTime.getMinutes())}:${formatNumber(
              currentDateTime.getSeconds()
            )}`}</div>

            {user ? (
              <div className="styled-account-header">
                {/* <div className="account-history">
              <div
                className="trans-his item-account"
                onClick={() => updateShowDialog(true, 'history')}
              >
                <img src="images/icon/T.hitory.png" alt="" />
                <span>Transaction History</span>
              </div>
            </div> */}
                <div
                  className="styled-account"
                  onClick={() => history.push('/account')}
                >
                  {/* {console.log('userRespeStore', userRespeStore)} */}
                  <span>
                    {userRespeStore && userRespeStore.user_account_no}
                  </span>
                  <img src="./images/header/use.png" alt="use" />
                </div>
                <div className="styled-line-header" />
                <div className="styled-tbh">
                  <span>
                    {userRespeStore && userRespeStore.user_currency}
                  </span>
                  {numeral(userInfo.user_money).format('0,0.00')}
                  <img
                    style={{
                      width: '20px',
                      height: '20px',
                      marginLeft: '5px'
                    }}
                    src="./images/header/add.png"
                    alt="add"
                  />
                </div>
                <StyledButton
                  background="#1138F7"
                  onClick={() => history.push('/deposit')}
                  // onClick={() => updateShowDialog(true, 'deposit')}
                >
                  {t('Deposit')}
                </StyledButton>
                <StyledButton
                  background="#1138F7"
                  onClick={() => history.push('/withdraw')}
                >
                  <a className="text position-relative">{t('Withdraw')}</a>
                </StyledButton>
                <div className="new-header-styled-lang">
                  <img
                    style={{ width: '30px', height: '30px' }}
                    src="./images/home/EN.png"
                    alt="en"
                  />
                </div>
              </div>
            ) : (
              <div className="styled-account-header">
                <p
                  className="new-header-link"
                  onClick={() => history.push('/playtech/login')}
                >
                  Login
                </p>
                <StyledButton
                  onClick={() => history.push('/register')}
                  background="#f78212"
                >
                  Join Now
                </StyledButton>
                <img
                  style={{ width: '30px', height: '30px' }}
                  src="./images/home/EN.png"
                  alt="en"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <CompCommon
        title={'Banking'}
        show={isShowCompBanking}
        close={() => {
          setShowCompCommonBanking(!isShowCompBanking)
        }}
      >
        <NewBankingMb />
      </CompCommon>
      <CompCommon
        title={'My Accounts'}
        show={isShowCompAcc}
        close={() => {
          setShowCompCommonAcc(!isShowCompAcc)
        }}
      >
        <NewAccountMb />
      </CompCommon>
      <PopupCommon
        title={'Deposit'}
        show={isShowPopupDep}
        onClose={() => {
          setShowPopupDep(false)
        }}
      >
        <NewDeposit />
      </PopupCommon>
      <PopupCommon
        title={'Transaction History'}
        show={isShowPopupHistory}
        onClose={() => {
          setShowPopupHistory(false)
        }}
      >
        <NewHistories />
      </PopupCommon>
    </div>
  )
}
export default withRouter(Header)
