export default {
  bodyContents: [
    {
      title: 'SLOTS',
      route: '/slot',
      icon: 'icon_menu3',
      chils: [{
        src: '/img/918kiss.png',
        code: '918kiss',
        label: '918 Kiss',
        route: '/',
        id: 2
      }, {
        src: '/img/asiangaming.png',
        code: 'asiagaming',
        label: 'Asia Gaming',
        route: '/asiagaming',
        id: 14
      }, {
        src: '/img/playtech.png',
        code: 'playtech',
        label: 'Playtech',
        route: '/playtech/login',
        id: 4,
        // redirect: true
      }, {
        src: '/img/joker.png',
        code: 'joker',
        label: 'Joker',
        route: '/joker',
        id: 8
      }, {
        src: '/img/spadegaming.png',
        code: 'spadegaming',
        label: 'SpadeGaming',
        route: '/spade',
        id: 7
      }, {
        src: '/img/play8oy.png',
        code: 'play8oy',
        label: 'Play8oy',
        deeplink: true,
        id: 3,

      }, {
        src: '/img/ace333.png',
        code: 'ace333',
        label: 'ace333',
        route: '/',
        id: 1,
        deeplink: true
      }, {
        src: '/img/live22.png',
        code: 'live22',
        label: 'Live22',
        route: '/live22',
        id: 13
      }, {
        src: '/img/mega.png',
        code: 'mega',
        label: 'Mega888',
        route: '/',
        id: 11,
        deeplink: true
      }, {
        src: '/img/sbo.png',
        code: 'sbo',
        label: 'SBO',
        route: '/sbo-games',
        id: 14

      }],
      ACL: 'SLOT'
    },
    {
      title: 'LIVE_CASINO',
      icon: 'icon_menu4',
      route: '/casino',
      chils: [{
        src: '/img/asiangaming.png',
        code: 'asiagaming',
        label: 'Asia Gaming',
        route: '/asiagaming-cs',
        id: 14
      }, {
        src: '/img/allbet.png',
        code: 'allbet',
        label: 'Allbet',
        route: '/allbet',
        id: 5
      }, {
        src: '/img/playtech.png',
        code: 'playtech',
        label: 'Playtech',
        route: '/authenticate/playtech/7bal',
        id: 4,
        // target: "_blank"
      }, {
        src: '/img/mega.png',
        code: 'mega',
        label: 'Mega888',
        route: '/',
        id: 11
      }, {
        src: '/img/sbo.png',
        code: 'sbo',
        label: 'SBO',
        route: '/sbo?GameType=Casino',
        id: 14
      }, {
        src: '/img/evolution-gaming.png',
        code: 'evolution',
        label: 'EVOLUTION',
        route: '/evolution-games',
        id: 15
      }],
      ACL: 'CASINO'
    },
    {
      title: 'FISHING',
      route: 'fishing',
      icon: 'icon_menu7',
      chils: [{
        src: '/img/playtech.png',
        code: 'playtech',
        label: 'Playtech',
        route: 'playtech/login',
        id: 4,
        // redirect: true
      }],
      ACL: 'FISHING'
    },
    {
      title: 'SPORTS',
      icon: 'icon_menu7',
      route: '/sports',
      chils: [{
        src: '/img/maxbet.png',
        code: 'maxbet',
        label: 'Maxbet',
        route: '/maxbet',
        id: 6
      }, {
        src: '/img/m8.png',
        code: 'm8',
        label: 'M8',
        route: '/m8',
        id: 9
      }, {
        src: '/img/sbo.png',
        code: 'sbo',
        label: 'SBO',
        route: '/sbo?GameType=SportsBook',
        id: 14
      }],
      ACL: 'SPORT'
    },
    {
      title: 'VIRTUAL_SPORTS',
      icon: 'icon_menu7',
      route: '/virtual-sports',
      chils: [{
        src: '/img/sbo.png',
        code: 'sbo',
        label: 'SBO',
        route: '/sbo?GameType=VirtualSports',
        id: 14
      }],
      ACL: 'VIRTUAL_SPORTS'
    },
    {
      title: 'LOTTERY',
      icon: 'icon_menu6',
      route: '/lottery',
      chils: [{
        src: '/img/NewWin-icon-gold.png',
        code: 'newwin',
        label: 'NewWin',
        route: '/newwin',
        id: 10
      }, {
        label: '97Lotto',
        code: 'lottery_thai',
        src: '/img/97lotto.jpg',
        route: '/97lotto',
        id: 12
      }],
      ACL: 'LOTTERY'
    }
  ],
  topControll: [
    {
      title: 'SLOTS',
      route: '/slot',
      src: `${process.env.PUBLIC_URL}/images/icon_menu3.svg`
    },
    {
      title: 'LIVE_CASINO',
      route: '/casino',
      src: `${process.env.PUBLIC_URL}/images/icon_menu4.svg`
    },
    {
      title: 'PROMOTIONS',
      src: `${process.env.PUBLIC_URL}/images/icon_menu5.svg`
    },
    {
      title: 'LOTTERY',
      route: '/lottery',
      src: `${process.env.PUBLIC_URL}/images/icon_menu6.svg`
    },
    {
      title: 'SPORTS',
      route: '/sports',
      src: `${process.env.PUBLIC_URL}/images/icon_menu7.svg`
    },
    {
      title: 'POKER',
      route: '/poker',
      src: `${process.env.PUBLIC_URL}/images/icon_menu4.svg`
    },
  ],
  lstNewLottery: [
    {
      label: '97Lotto',
      code: '97lotto',
      src: '/img/97lotto.jpg',
      route: '/97lotto',
      idrActive: 0,
      id: 12
    },
    {
      label: '97Lotto',
      code: '97lotto',
      src: '/img/97lotto.jpg',
      route: '/97lotto',
      idrActive: 0,
      id: 12
    },
    {
      label: '97Lotto',
      code: '97lotto',
      src: '/img/97lotto.jpg',
      route: '/97lotto',
      idrActive: 0,
      id: 12
    },
    {
      label: '97Lotto',
      code: '97lotto',
      src: '/img/97lotto.jpg',
      route: '/97lotto',
      idrActive: 0,
      id: 12
    },
    {
      label: '97Lotto',
      code: '97lotto',
      src: '/img/97lotto.jpg',
      route: '/97lotto',
      idrActive: 0,
      id: 12
    }
  ]
}
export const ETypeNotification = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};