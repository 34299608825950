import React, { useState, PropTypes } from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    color: '#ffaf50',
    '&$checked': {
      color: '#ffaf50',
    },
  },
  checked: {},
  input: {
    color: "#ffaf50"
  },
  label: {
    color: "#ffaf50"
  },
  underline: {
    "&:before": {
      borderBottom: `1px solid #ffaf50`
    }
  }
}
const CheckInputNew = (handleCheckboxChange, label, name, content) => {
  const [isChecked, setChecked] = useState(true)

  const toggleCheckboxChange = () => {
    setChecked(!isChecked)
    handleCheckboxChange();
  }
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        value={"a"}
        checked={isChecked}
        onChange={toggleCheckboxChange}
      />

      {label ? (
        <label
          style={{ marginLeft: 5, cursor: "pointer" }}
          className="txt blue"
        >
          {label}
        </label>
      ) : (
        ""
      )}
    </div>
  );
}

// Checkbox.propTypes = {
//   label: PropTypes.string.isRequired,
//   handleCheckboxChange: PropTypes.func.isRequired,
// };

export default withStyles(styles)(CheckInputNew);