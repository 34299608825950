/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import {
  CssBaseline,
  GridList,
  GridListTile,
  ListSubheader,
  GridListTileBar
} from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import { withStyles } from '@material-ui/core/styles'
import store from 'store'
import http from 'service/http'
import component from 'component'
import styles from './style'

const { useDialog, Header, useLoading } = component

function Login({ classes, history, match: { params: { code } } }) {
  const [loading, withLoading] = useLoading(false)
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const [GAMES, setGAMES] = React.useState(null)
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Login Error',
    btnLabel: 'Got it',
    type: 'error'
  })

  const initialPT = async () => {
    const { games } = await withLoading(() => http.get({ path: `games/7/gamelist` }))
    setGAMES(games)
    try {
      if (code) {
        const { user_id: userId } = store.get('user')
        const gameList = await http.get({ path: 'games' })
        if (gameList.find(({ id }) => id === 7)) {
          const { gameURL } = await http.get({ path: `games/7/login` })
          return updateLoginUrl(`${gameURL}&game=${code}`)
        }
        return showDialogErrorWithMessage('Spade is comming')
      }
    } catch (error) {
      console.log(error)
      return showDialogErrorWithMessage(error.message)
    }
    return true
  }
  const checkWith = () => {
    let width = 2
    const e = document.getElementById('root')
    if(e.clientWidth > 576) width = 3
    if(e.clientWidth > 720) width = 4
    if(e.clientWidth > 960) width = 5
    if(e.clientWidth > 1140) width = 6
    return width
  }
  useEffect(() => {
    initialPT()
  }, [])

  return (
    <div className={`col-12 col-md-8 col-md-8 ${!loginUrl && !code ? classes.main : classes.main1}`}>
      <CssBaseline />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : !loginUrl && !code ? (
        <GridList cellHeight={250}  cols={checkWith()} className={classes.gridList}>
          {GAMES && GAMES.filter(x => x.gameCode.includes('F-')).map(({ gameCode, gameName, thumbnail }) => (
            <GridListTile onClick={() => window.open(`/authenticate/spade/${gameCode}`)} key={gameCode}>
              <img style={{ width: '100%', height: '80%' }} src={`https://api-egame-staging.sgplay.net${thumbnail}`} alt={gameName} />
              <GridListTileBar
                title={gameName}
              />
            </GridListTile>
          ))}
        </GridList>)
          : <div className="play-game full-screen">
            <iframe src={loginUrl} />
          </div>}
      <ErrorDialog />
    </div>
  )
}

export default withStyles(styles)(Login)
