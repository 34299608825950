import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button
} from '@material-ui/core'
// import { useTranslation } from 'react-i18next'
import styles from './style'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import copy from 'copy-to-clipboard'
import numeral from 'numeral'

function home({ account, password, balance, deeplink, route, classes, history, html5 }) {
  // console.log(account, html5, route, deeplink)
  // const { t } = useTranslation()
  const [openGame, setOpenGame] = React.useState(0)
  const copy2Clipboard = (text) => {
    copy(text)
    // alert('Has been copied: \n' + text)
  }
  React.useEffect(() => {
    setTimeout(() => {
      if(deeplink !== '') {
        const e = document.getElementById('deeplink')

        if(e && openGame === 0) {
          console.log(e)
          e.click()
          setOpenGame(1)
        }
      }
    }, 2000)
  }, [])
  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
        Account: {account}
        <FileCopyOutlined className={classes.filecoppy}
                          onClick={() => copy2Clipboard(account)}
        />
      </Typography>
      <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
          Password: {password}
        <FileCopyOutlined className={classes.filecoppy} onClick={() => copy2Clipboard(password)}
        />
      </Typography>

      <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
        Balance: {numeral(balance).format('0,0.00')}

      </Typography>
      {!html5 && deeplink
        ? <a className='link-btn-default' id="deeplink" href={deeplink}>Play now</a>
        : (!html5 && !deeplink ? <Button onClick={() => history.push(route)} fullWidth variant="contained" color="primary" className={classes.submit}>
          <strong>Play now</strong>
        </Button> : <div>
          <a style={{margin: 3}} className='link-btn-default' href={route}>Play HTML5</a>
          <a style={{margin: 3}} className='link-btn-default' id="deeplink" href={deeplink}>Play now</a>
        </div>)
      }
    </React.Fragment>
  )
}
export default withStyles(styles)(home)
