import React from 'react'

const StyledButton = ({ children, onClick, background, width, height, margin, ...rest }) => {
    const buttonStyle = {
      backgroundColor: background,
      width: width,
      height: height,
      margin: margin
    }

  return (
    <button className='styled-button-all'
    onClick={onClick}
    {...rest}
      style={buttonStyle}
    >
      {children}
    </button>
  )
}

export default StyledButton
