/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'
import store from 'store'
import queryString from 'query-string'

const { useDialog, useLoading, Header } = component

function Live22({ history }) {
  const { location: { search } } = history
  const { GameType } = queryString.parse(search)
  const [Dialog, showDialog] = useDialog({
    title: 'Asia Gaming',
    type: 'error'
  })
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const [loading, withLoading] = useLoading()
  const login = async () => {
    try {
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 21)) {
        const { gameUrl } = await withLoading(() =>
            http.get({ path: `games/21/login`, params: { ProviderId: 'AG', GameType: GameType ||'SL' } }))
        return updateLoginUrl(gameUrl)
      }
      return showDialog('Asia Gaming is comming')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    login()
  }, [])
  return (
    <div className="col8">
      {/* <Header history={history} title='Live22' /> */}
      {loading || !loginUrl ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : <div className="play-game full-screen">
          <iframe src={loginUrl} />
        </div>}
      <Dialog />
    </div>
  )
}

export default Live22