/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import http from 'service/http'
import { PropagateLoader } from 'react-spinners'
import component from 'component'
import store from 'store'
import queryString from 'query-string'

const { useDialog, useLoading, Header } = component

function Live22({ history }) {
  const [Dialog, showDialog] = useDialog({
    title: 'Rich88',
    type: 'error'
  })
  const [loginUrl, updateLoginUrl] = React.useState(null)
  const [loading, withLoading] = useLoading()
  const { location: { search } } = history
  const { GameType } = queryString.parse(search)
  const login = async () => {
    try {
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 31)) {
        let Platform = 'D'
        if( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        ){
          Platform = 'M'
        }
        const { gameLink } = await withLoading(() => http.get({ path: `games/31/login`, params: { Platform, GameType } }))
        return updateLoginUrl(gameLink)
      }
      return showDialog('Rich88 is comming')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    login()
  }, [])
  return (
    <div className="col8">
      {/* <Header history={history} title='Live22' /> */}
      {loading || !loginUrl ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : <div className="play-game full-screen">
          <iframe src={loginUrl} />
        </div>}
      <Dialog />
    </div>
  )
}

export default Live22