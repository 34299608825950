import React, { useEffect } from 'react';
import store from 'store'
import { Form } from 'antd';

const FormComponent = ({ handlerSubmit,children, ...rest }) => {
  const [form] = Form.useForm();
  const { user_id: userId, bankAccountNumber, bankName, bankAccount } = store.get('user') || { user_id: undefined }
  useEffect(() => {
    form.setFieldsValue({
     bankName: bankName,
     bankAccount: bankAccount,
     bankAccountNumber: bankAccountNumber
    })
 }, [form])
  return (
    <Form form={form} layout="vertical" className="form-deposit" onFinish={handlerSubmit}>
      {children}
    </Form>
  )
};

export default FormComponent;
