/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import store from 'store'
import http from 'service/http'
import numeral from 'numeral'
import config from 'config'
import WithdrawNew from 'feature/WithdrawNew'
import './style.css'
import CompCommon from 'feature/CompCommon'
import NewDeposit from 'feature/NewDeposit'
import NewHistoriesMb from 'feature/NewHistoriesMb'
import useLoading from 'feature/loading/hook'
import useDialog from 'feature/dialog/hook'
import Modal from 'antd/lib/modal/Modal'
import { Button, Form } from 'antd'
import InputField from '../../component/textInput/InputField'
import { showNotification } from '../../utils/function'
import { ETypeNotification } from '../../constants/gameData'
import Howto from './components/HowtoRegister'
import Colletion from './components/Collection'
import AppDownload from './components/AppDowload'
import Head from '../../component/layout/Head'
import Testimonials from '../../component/layout/Testimonials'
import CASINO from '../LiveCasinoComp/constant'
import SLOST from '../slotGameComp/constant'
import SPORTS from '../SportsComp/constant'
import GAMELOVE from './apiGameLove'

const dataTitle = {
  title1: 'Popular Game We Love',
  title2: 'Best live Casino Games',
  title3: 'Best Slot Games',
  title4: 'Best Sportsbook'
}
const dataMore = {
  more1: '/lottery',
  more2: '/casino',
  more3: '/slot',
  more4: '/sports'
}
const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: '',
    1: ''
  }

  return proxy[id]
}
const gameInit = {
  hot: false,
  casino: false,
  lottery: false,
  slot: false,
  sport: false,
}
function home({ history, classes }) {
  const { t } = useTranslation()
  const {
    location: { search }
  } = history
  const [jackpot, setJackpot] = React.useState(0)

  const {
    user_id: userId,
    user_account_no,
    bankName,
    bankCode,
    bankAccount,
    bankAccountNumber,
  } = store.get('user') || { user_id: undefined }
  const user = store.get('user')
  window.username = user_account_no
  const [balance, setBalance] = React.useState(0)
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Your Account'
  })
  const [loading, withLoading] = useLoading(true)
  const [isShowDeposit, setShowDeposit] = useState(false)
  const [games, setShowGames] = useState({...gameInit, hot: true})
  const [isShowWithdraw, setShowWithdraw] = useState(false)
  const [isShowHistory, setShowHistory] = useState(false)
  const [histories, setHistory] = useState([])
  const [users, updateUser] = useState({})
  const [gameBalances, updateGameBalances] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [banks, setBanks] = useState([])
  const [bankInfo, setBankInfo] = useState({
    bankName,
    bankCode,
    bankAccount,
    bankAccountNumber,
  })
  const imageBanner = './images/home/bannerhome.png'
  React.useEffect(() => {
    // fetchAccount()
    const loadgame = localStorage.loadgame
    if (!loadgame || loadgame == 1) {
      localStorage.loadgame = 0
    }
    (function() {
      var widget_id = 'WokNIyleR2'
      var d = document
      var w = window

      function l() {
        var s = document.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = '//code.jivosite.com/script/widget/' + widget_id
        var ss = document.getElementsByTagName('script')[0]
        ss.parentNode.insertBefore(s, ss)
      }

      if (d.readyState == 'complete') {
        l()
      } else {
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()
    withLoading(() => http.get({ path: 'banks-support' }).then(setBanks))

    if (userId) {
      http.post({ path: `users/${userId}/sync-game` })
      withLoading(() => http.get({ path: 'me' }).then(rs => {
        if(!rs.bankCode || rs.bankCode === '') setIsModalVisible(true)
      }))
    }
    const userBalance = store.get('userBalance')
    setBalance(userBalance)
    setTimeout(() => {
      const userBalance = store.get('userBalance')
      setBalance(userBalance)
    }, 5000)
    setTimeout(() => {
      const userBalance = store.get('userBalance')
      setBalance(userBalance)
    }, 10000)
  }, [])
  const fetchAccount = () => {
    const { user_id: userId } = store.get('user')
    setTimeout(async () => {
      const [userResp] = await withLoading(() =>
        Promise.all([http.get({ path: 'me' })])
      )
      // if(userResp.bankCode === '') setIsModalVisible(true)
      store.set('user', userResp)
      updateUser(userResp)
      // console.log(config.gamesAvailable)
      updateGameBalances(
        userResp.balanceGame
          .filter(x => config.gamesAvailable.includes(x.balance.game_code))
          .map(t => {
            return t.balance
          })
      )
    }, 2000)
  }
  const onPullOut = async () => {
    const { user_id: userId } = store.get('user')
    const tasks = []
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== 'asiagaming') {
        const providerCode = game_code === 'ag_galaxy' ? '?ProviderId=AG' : ''
        tasks.push(
          http.post({
            path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
            payload: {
              amount: balance
            }
          })
        )
      }
    })
    await withLoading(() => Promise.all(tasks))
    showDialogWithMessage('Pull out done!')
    fetchAccount()
  }
  const onRedeemPoint = async () => {
    await withLoading(() => http.put({ path: `users/redeem/point`}))
    showDialogWithMessage('Redeem success!')
    fetchAccount()
  }
  const onUpdateShow = (value, type) => {
    if (type === 'deposit') {
      setShowDeposit(true)
    }
    if (type === 'withdraw') {
      setShowWithdraw(true)
    }
    if (type === 'history') {
      setShowHistory(true)
    }
  }
  const getListHistory = value => {
    setHistory(value)
  }
  const onSubmit = async payload => {
    try {
      const { user_id: userId } = store.get('user')
      const submited = {
        bankName: payload.bankName,
        bankAccount: payload.bankAccount,
        bankAccountNumber: payload.bankAccountNumber,
        bankCode: bankInfo.bankCode
      }
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: submited })
      )

      showNotification(ETypeNotification.SUCCESS,'Update Your Bank Detail Success')
      window.location.reload()

    } catch (error) {
      showNotification(ETypeNotification.ERROR,error.message)
    }
  }
  const [form] = Form.useForm();
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: '#fff'
        }}
      >
        <Head image={imageBanner} />
        <div className="main">
          <Howto />
          <Colletion
            data={GAMELOVE}
            dataTitle={dataTitle.title1}
            dataMore={dataMore.more1}
            history={history}
          />
          <Colletion
            data={CASINO}
            dataTitle={dataTitle.title2}
            dataMore={dataMore.more2}
            history={history}
          />
          <Colletion
            data={SLOST}
            dataTitle={dataTitle.title3}
            dataMore={dataMore.more3}
            history={history}
          />
          <Colletion
            data={SPORTS}
            dataTitle={dataTitle.title4}
            dataMore={dataMore.more4}
            history={history}
          />
          <AppDownload />
          <Testimonials />
          <div className="" style={{ margin: '0 auto' }} id="home">
            <Modal
              footer={null}
              title={t('BANK_INFO')}
              visible={isModalVisible}
            >
              <Form
                form={form}
                layout="vertical"
                className="form-deposit"
                onFinish={values => onSubmit({ ...values })}
              >
                <Form.Item
                  name="bankName"
                  label="Bank Name"
                  style={{ marginBottom: '10px' }}
                >
                  <select
                    style={{ background: '#fff' }}
                    type="text"
                    className="login-input mt-3"
                    placeholder={t('BANK_NAME')}
                    value={bankInfo.bankCode}
                    onChange={event => {
                      setBankInfo({
                        ...bankInfo,
                        bankCode: event.target.value,
                        bankName: banks.find(x => x.value == event.target.value)
                          .title
                      })
                    }}
                  >
                    <option value="">{t('SELECT_BANK')}</option>
                    {banks.map(b => {
                      return <option value={b.value}>{b.title}</option>
                    })}
                  </select>
                </Form.Item>
                <Form.Item name="bankAccount" label="Bank Account">
                  <InputField
                    type="text"
                    size="default"
                    placeholder="Bank Account.."
                  />
                </Form.Item>
                <Form.Item name="bankAccountNumber" label="Account No">
                  <InputField
                    type="number"
                    min={0}
                    size="default"
                    placeholder="Account No.."
                  />
                </Form.Item>
                <div className="text-center formGroup_btn">
                  <Button type="primary" loading={loading} htmlType="submit">
                    {t('SUBMIT_LABEL')}
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
      <CompCommon
        title={'Deposit'}
        className="custom-mobile-deposit"
        show={isShowDeposit}
        close={() => {
          setShowDeposit(!isShowDeposit)
        }}
      >
        <NewDeposit />
      </CompCommon>
      <CompCommon
        title={'Withdraw'}
        show={isShowWithdraw}
        close={() => {
          setShowWithdraw(!isShowWithdraw)
        }}
      >
        <WithdrawNew />
      </CompCommon>
      <CompCommon
        title={'History'}
        show={isShowHistory}
        icon={'images/icon/Untitled-2.png'}
        close={() => {
          setShowHistory(!isShowHistory)
        }}
        getListHistory={getListHistory}
      >
        <NewHistoriesMb histories={histories} />
      </CompCommon>
    </>
  )
}

export default home
