/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import http from 'service/http'
// import socket from 'service/socket'
import numeral from 'numeral'
import moment from 'moment'
import store from 'store'
import config from 'config'
import { Button, Form, Spin } from 'antd';
import Bottom from 'component/bottom'
import Header from 'component/header'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import formCreateInputs from 'service/form/create'
import validate from 'service/form/validation'

import useDialog from '../dialog/hook'
import useLoading from '../loading/hook'
import CompCommon from "feature/CompCommon"
// import NewDeposit from "feature/NewDeposit"
import WithdrawNew from "feature/WithdrawNew"
import NewHistoriesMb from "feature/NewHistoriesMb"
import './style.css'
import Modal from 'antd/lib/modal/Modal'
import InputField from '../../component/textInput/InputField'
import { showNotification } from '../../utils/function'
import { ETypeNotification } from '../../constants/gameData'
import { Spinner } from 'react-bootstrap'
import banks from '../../constants/banks'

// socket.on('login', (data) => {
//   localStorage.clear();
//   alert('Your account just login from other place !')
//   window.location = 'login'
// })

function NewBankDetails({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [banks, setBanks] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = store.get('user')
  const { user_id: userId, bankAccountNumber, bankName, bankAccount, bankCode } = store.get('user') || { user_id: undefined }
  const [bankInfo, setBankInfo] = useState({
    bankName,
    bankCode,
    bankAccount,
    bankAccountNumber,
  })
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchAccount = () => {
    setTimeout( async () => {
      const [userResp, banksSupport] = await withLoading(() => Promise.all([
        http.get({ path: 'me' }),
        http.get({ path: 'banks-support' }),
      ]))
      store.set('user', userResp)
      setBanks(banks)
    }, 2000);
   
  }
  const onSubmit = async payload => {
    try {
      const { user_id: userId } = store.get('user')
      const submited = {
        bankName: payload.bankName,
        bankAccount: payload.bankAccount,
        bankAccountNumber: payload.bankAccountNumber,
        bankCode: bankInfo.bankCode
      }
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: submited })
      )
     
      showNotification(ETypeNotification.SUCCESS,'Update Your Bank Detail Success')
      window.location.reload()
      
    } catch (error) {
      showNotification(ETypeNotification.ERROR,error.message)
    }
  }
  useEffect(() => {
     form.setFieldsValue({
      bankName: bankName,
      bankAccount: bankAccount,
      bankAccountNumber: bankAccountNumber,
       bankCode
     })
  }, [form])
  useEffect(() => {
    withLoading(() => http.get({ path: 'banks-support' }).then(setBanks))
  }, [])

  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    onSubmit({...values})
    setIsModalVisible(false);
    fetchAccount();
  }

  return (
    <div className="content-detail-banking" style={{marginTop: 60}}>
      <div className="label-bank-detail" onClick={showModal}>
        Update Bank Account
      </div>
      {user && user.user_id ? <div className="value-detail-banking">
       {loading && <Spin tip="Loading..." />} 
        <div className="row-content-bank">
          <span className="title">Bank Name</span>
          <span className="value">{bankName}</span>
        </div>
        <div className="row-content-bank">
          <span className="title">Account Name</span>
          <span className="value">{bankAccount}</span>
        </div>
        <div className="row-content-bank">
          <span className="title">Account No</span>
          <span className="value">{bankAccountNumber}</span>
        </div>
      </div>
        :
        <button className="btn-login_mb" onClick={() => history.push("/login")}>
          <img src="images/icon/lock.png" alt="" /><span>LOGIN</span></button>}
      {isModalVisible && <Modal footer={null} title={t('BANK_INFO')} visible={isModalVisible}  onCancel={handleCancel}  >
        <Form form={form} layout="vertical" className="form-deposit" onFinish={handleSubmit}>
          <Form.Item 
            name="bankName" label="Bank Name" style={{ marginBottom: "10px" }}>
            <select
              type="text"
              className="login-input mt-3"
              placeholder={t('BANK_NAME')}
              value={bankInfo.bankCode}
              onChange={(event) => { setBankInfo({ ...bankInfo, bankCode: event.target.value, bankName: banks.find(x => x.value == event.target.value).title }) }}
            >
              {banks.map(b => {
                return <option value={b.value}>{b.title}</option>
              })}
            </select>
          </Form.Item>
          <Form.Item 
            name="bankAccount" label="Bank Account">
            <InputField type="text" size="default" placeholder="Bank Account.." />
          </Form.Item>
          <Form.Item 
            name="bankAccountNumber" label="Account No">
            <InputField type="text" size="default" placeholder="Account No.." />
          </Form.Item>
          <div className="text-center formGroup_btn">
            <Button type="primary" loading={loading} htmlType='submit'>{t('SUBMIT_LABEL')}</Button>
          </div>
        </Form>
      </Modal>}
    </div>
  );
}

export default NewBankDetails
