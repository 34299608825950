import React from "react"
import http from 'service/http'
import store from 'store'
import { PropagateLoader } from 'react-spinners'
import component from 'component'

const { useDialog, useLoading, Header } = component

function Allbet({ history }) {
  const [Dialog, showDialog] = useDialog({
    title: 'Allbet'
  })
  const [loading, withLoading] = useLoading()
  const login = async () => {
    try {
      const { user_id: userId } = store.get('user')
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 5)) {
        const { gameLoginUrl } = await withLoading(() => http.post({
          path: `users/${userId}/game/logout`, payload: {
            gameLoginId: '5'
          }
        }).then(() => http.get({ path: `games/5/login` })))
        window.location = gameLoginUrl
        return
      }
      showDialog('Allbet is Maintenance ')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    login()
  }, [])
  return (
    <div className="col8">
      {/* <Header history={history} title='Allbet'/> */}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }} >
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : null}
      <Dialog />
    </div>
  )
}

export default Allbet