/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import store from 'store'
import numeral from 'numeral'
import config from 'config'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import http from 'service/http'

import useLoading from '../loading/hook'

import gameData from '../../constants/gameData';

const LeftSideBar = ({ history }) => {
  const userInfo = store.get('user');

  const { t } = useTranslation()
  const [user, updateUser] = useState(userInfo || {})
  const [loading, withLoading] = useLoading(false)
  const { user_id: userId, user_money: money, is_welcome_bonus: bonus, username, user_account_no } = user || {}
  const [balance, setBalance] = React.useState({})
  const fetchAccount = async () => {
    try {
      const userBalanceTime = store.get('userBalanceTime')
      if(!userBalanceTime || Date.now() - userBalanceTime > 60000) {
        const [userResp] = await withLoading(() => Promise.all([
          http.get({ path: 'me' }),

        ]))

        store.set('user', userResp)
        updateUser(userResp)
        let bl = userResp.user_money;
        userResp.balanceGame.filter(x => x.game.game_code != 'asiagaming').map(r => {
          bl += Number(r.balance.balance)
          // console.log(r, bl)
        })
        setBalance(bl)
        store.set('userBalance', bl)
        store.set('userBalanceTime', Date.now())
      } else {
        const userResp = store.get('user')

        updateUser(userResp)
        setBalance(store.get('userBalance'))
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (userInfo) {
      fetchAccount()
    }
  }, [history.location.pathname])

  return (
    <div className="col2 show_pc">
      <div className="mainLeft ">
        {userId ? (
          <div className="profile_account mb_5">
            <h3 style={{ fontWeight: 'bold' }} onClick={() => history.push('/account')} className="rs c-pointer">{user_account_no.toLowerCase()}</h3>
            <div className="display_flex profile_account_info">
              {/* <div className="profile_account-img">
                <img src={avatar} className="img-fluid" alt="" />
              </div> */}
              <div className="profile_account-text">
                <p className="rs">- {t('YOUR_WALLET')}: {`${numeral(money).format('0,00')} ${config.currency || 'MYR'}`}</p>
                <p className="rs p_border">- {t('BALANCE')}: {`${numeral(balance).format('0,00')}`}</p>
                <p className="rs p_myaccount c-pointer" onClick={() => history.push('/account')}><span></span>{t('MY_ACCOUNT')}</p>
              </div>
            </div>
            <div className="text-center">
              <a
                onClick={() => {
                  history.push('/');
                  store.clearAll()
                  updateUser({})
                }}
                className="profile_signout c-pointer"
              >
                {t('LOGOUT')}
              </a>
            </div>
          </div>
        ) : (
            <>
              <a onClick={() => history.push('/register')} className="quick_account mb_5 c-pointer"><span className="icon_account"></span>{t('QUICK_REGISTER')}</a>
              <a onClick={() => history.push('/login')} className="login mb_5 c-pointer">{t('SIGN_IN')}</a>
            </>
          )}

        <div className="menu_sidebar">
          <div className="menu_sb1">
            <a onClick={() => history.push('/withdraw')} className="icon_top1 c-pointer">{t('WITHDRAW')}</a>
            <a onClick={() => history.push('/deposit')} className="icon_top2 c-pointer">{t('DEPOSIT')}</a>
            <a onClick={() => history.push('/transfer')} className="icon_top2 c-pointer">{t('TRANSFER')}</a>
            <a onClick={() => history.push('/comm')} className="icon_top2 c-pointer">{t('TRANSACTION_HISTORY')}</a>
            <a onClick={() => history.push('/reports')} className="icon_top3 c-pointer">{t('REPORTS')}</a>
          </div>
          <div className="menu_sb2">
            <span className="menu_sb2_title">{t('LIST')}</span>
            <a onClick={() => history.push('/home')} className="c-pointer"><span className="icon_menu1"></span>{t('HOME')}</a>
            <a href="" className=""><span className="icon_menu2"></span>{t('LATEST_OFFERS')}</a>
            <span className="menu_sb2_title">{t('GAME')}</span>
            {gameData.bodyContents
              .filter(({ ACL }) => config.market.includes(ACL))
              .map(({ chils, title, route, icon }) =>
                <a key={title}
                  // onClick={() => history.push(route)}
                  onClick={() => window.location.href = route}
                  className="c-pointer"
                >
                  <span className={icon} />
                  {t(title)}
                </a>
              )}
            <span className="menu_sb2_title">{t('OTHER')}</span>
            <a onClick={() => history.push('/download')} className=""><span className="icon_menu8"></span>{t('DOWNLOAD')}</a>
            <a href="" className=""><span className="icon_menu9"></span>{t('AFFILIATE_AGENT')}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(LeftSideBar);
